import { DependencyList, useEffect } from 'react';

type KeyCallback = ({
  key,
  shift,
  ctrl,
  alt,
}: {
  key: string;
  shift: boolean;
  ctrl: boolean;
  alt: boolean;
}) => void;

export const useKeyPress = (callback: KeyCallback, deps?: DependencyList) => {
  useEffect(() => {
    if (!window || !window.document) return;

    const onKeyPress = (
      event: KeyboardEvent,
      preventDefault: boolean = false,
    ) => {
      if (preventDefault) {
        event.preventDefault();
      }
      callback({
        key: event.key,
        shift: event.shiftKey,
        ctrl: event.ctrlKey || event.metaKey,
        alt: event.altKey,
      });
    };

    window.document.addEventListener('keydown', onKeyPress);
    return () => window.document.removeEventListener('keydown', onKeyPress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...(deps ?? [])]);
};
