import { HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { NewProjectButton } from '$/components/core/Projects/components/NewProjectButton';
import { TooltipHeader } from '$/pages/DashboardPages/components/TooltipHeader';

export const WelcomeHeader = () => {
  const { t } = useTranslation();

  return (
    <HStack justify='space-between'>
      <TooltipHeader header={t('dashboard.welcome.yourSpaceText')} info='' />
      <NewProjectButton />
    </HStack>
  );
};
