import { useQuery } from '@tanstack/react-query';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { NewProjectCard } from '$/components/core/Projects/components/Cards/NewProjectCard';
import { ProjectCard } from '$/components/core/Projects/components/Cards/ProjectCard';
import { UpgradeAccountCard } from '$/components/core/Projects/components/Cards/UpgradeAccountCard';
import { LoadingProjectCard } from '$/components/core/Projects/components/LoadingProjectCard';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { projectsQuery } from '$/services/usecases/projects/queries';
import { repeat } from '$/utils/arrayUtils';

export const ProjectRowItems = () => {
  const { hasPermission, canCreateMoreProjects, getProjectCountLimit } =
    useAuthorization();
  const itemLimit = useMaterialGridMaxItems();

  const { data, isLoading } = useQuery(projectsQuery);

  if (isLoading || !data) {
    return repeat(itemLimit).map((index) => <LoadingProjectCard key={index} />);
  }

  const projects = data.projects
    .slice(0, getProjectCountLimit())
    .map((project) => (
      <ProjectCard key={project.id} project={project} isLoaded={true} />
    ));

  if (hasPermission('General_Project_Page')) {
    if (!canCreateMoreProjects()) {
      return (
        <>
          {projects.slice(0, Math.min(3, itemLimit))}
          <UpgradeAccountCard />
        </>
      );
    }
    if (data.projectCount < 4) {
      return (
        <>
          {projects}
          <NewProjectCard />
        </>
      );
    }
    if (data.projectCount >= itemLimit) {
      return projects.slice(0, itemLimit);
    }
  }

  return (
    <>
      {projects}
      {canCreateMoreProjects() ? <NewProjectCard /> : <UpgradeAccountCard />}
    </>
  );
};
