import { Box, Heading, VStack } from '@chakra-ui/react';
import { FC } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { MaterialListItem } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItem';
import { MaterialListItemSkeleton } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItemSkeleton';
import { Material } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  title: string;
  favorites: FavoriteItem[];
  isLoading: boolean;
  activeMaterialId?: string;
  onClickItem: (material: Material) => void;
  additionalHideCondition?: boolean;
}

export const FavoriteFolderSection: FC<Props> = ({
  favorites,
  title,
  isLoading,
  onClickItem,
  activeMaterialId,
  additionalHideCondition = false,
}) => {
  if ((!isLoading && favorites.length === 0) || additionalHideCondition)
    return null;

  return (
    <VStack alignItems='flex-start' w='full' mb='6' px='5'>
      <Heading as='h3' my='3' fontSize='sm'>
        {title}
      </Heading>
      <Show
        when={!isLoading}
        fallback={() =>
          repeat(4).map((_, index) => (
            <MaterialListItemSkeleton key={index} id={index} />
          ))
        }
      >
        {favorites.map((favorite, index) => (
          <Box
            key={favorite.material.id}
            as='button'
            onClick={() => onClickItem(favorite.material)}
          >
            <MaterialListItem
              id={index}
              material={favorite.material}
              showAsActive={activeMaterialId === favorite.material.id}
            />
          </Box>
        ))}
      </Show>
    </VStack>
  );
};
