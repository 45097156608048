import {
  Box,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Button,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import { ForgotPasswordForm } from '$/components/core/Authentication/components/ForgotPasswordForm';
import { LoginForm } from '$/components/core/Authentication/components/LoginForm';
import { RegisterForm } from '$/components/core/Authentication/components/RegisterForm';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { trackContentImpression, trackContentInteraction } from '$/logger';
import { mapAuthModalTypeToPiwikContentPiece } from '$/utils/piwikUtils';

export const AuthenticationModal = () => {
  const { t } = useTranslation();

  const isAuthenticationModalOpen = useAuthenticationStore.useIsModalOpen();
  const onCloseAuthenticationModal = useAuthenticationStore.useOnModalClose();
  const authenticationModalType = useAuthenticationStore.useModalType();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();

  useEffect(() => {
    if (!isAuthenticationModalOpen) {
      return;
    }

    const contentPiece = mapAuthModalTypeToPiwikContentPiece(
      authenticationModalType,
    );

    if (!contentPiece) {
      return;
    }

    trackContentImpression('CloudAccount', contentPiece, 'Open');
  }, [isAuthenticationModalOpen, authenticationModalType]);

  const handleCloseAuthenticationModal = () => {
    const contentPiece = mapAuthModalTypeToPiwikContentPiece(
      authenticationModalType,
    );

    if (!contentPiece) {
      return;
    }

    trackContentInteraction(
      'ClickButton',
      'CloudAccount',
      contentPiece,
      'Close',
    );

    onCloseAuthenticationModal();
  };

  const getModalInformation = (): {
    header?: string | JSX.Element;
    content: JSX.Element;
  } => {
    switch (authenticationModalType) {
      case 'login':
        return {
          header: t('dashboard.header.signIn'),
          content: <LoginForm />,
        };
      case 'register':
        return {
          header: t('dashboard.header.createAccount'),
          content: <RegisterForm />,
        };
      case 'passwordForgotten':
        return {
          header: (
            <HStack>
              <TrackedButton
                h='14px'
                px='0'
                onClick={() => setAuthenticationModalType('login')}
                variant='text'
                contentName='CloudAccount'
                contentPiece='ForgetPassword'
                contentTarget='Back'
              >
                <Icon icon='chevron_down' transform='rotate(90deg)' />
              </TrackedButton>

              <Box as='span'>
                {t('dashboard.authentication.resetPassword.action')}
              </Box>
            </HStack>
          ),
          content: <ForgotPasswordForm />,
        };
      case 'registerSuccess':
        return {
          content: (
            <VStack gap='8'>
              <Heading>
                {t('dashboard.authentication.createAccountSuccessfulTitle')}
              </Heading>
              <Icon boxSize='86px' icon='leaderboard' />
              <Text fontSize='sm'>
                {t(
                  'dashboard.authentication.createAccountSuccessfulDescription',
                )}
              </Text>
              <Button onClick={onCloseAuthenticationModal}>
                {t('general.backHomeShort')}
              </Button>
            </VStack>
          ),
        };
    }
  };

  const modalInfo = getModalInformation();

  return (
    <Modal
      isCentered
      isOpen={isAuthenticationModalOpen}
      onClose={handleCloseAuthenticationModal}
    >
      <ModalOverlay />
      <ModalContent maxW='500px' bg='background'>
        {modalInfo.header && (
          <>
            <ModalHeader px='10' pt='10' fontSize='2xl'>
              {modalInfo.header}
            </ModalHeader>
            <ModalCloseButton top='0' right='0' mt='8' mr='10' />
          </>
        )}

        <ModalBody p='10'>{modalInfo.content}</ModalBody>
      </ModalContent>
    </Modal>
  );
};
