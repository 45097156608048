import { useParams } from '@tanstack/react-router';

import { useKeyPress } from '$/hooks/useKeyPress';
import { ActionBarSection } from '$/pages/EditorPage/components/ActionBarSection';
import { AttributesSection } from '$/pages/EditorPage/components/AttributesSection';
import { ComponentSection } from '$/pages/EditorPage/components/ComponentSection';
import { ModuleSection } from '$/pages/EditorPage/components/ModuleSection';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { editorActions } from '$/pages/EditorPage/stores/editorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const EditorPage = () => {
  const { id }: { id: string | undefined } = useParams({ strict: false });

  const activeLayer = useEditorStore.useActiveLayerIndex();
  const activeComponent = useEditorStore.useActiveComponentIndex();
  const actions = useEditorActions();

  useKeyPress(({ ctrl, key }) => {
    if (ctrl && key === 'z') {
      actions.selectAction(ModuleSendSelectAction.Undo);
    }

    if (ctrl && key === 'y') {
      actions.selectAction(ModuleSendSelectAction.Redo);
    }

    if (key === 'Delete') {
      if (activeComponent == null) return;
      if (activeLayer == null) {
        actions.deleteComponent(activeComponent);
      } else {
        actions.deleteObject(activeComponent, activeLayer);
      }
    }
  });

  return (
    <>
      {id && <ModuleSection />}
      <ComponentSection projectName='Projektname/Raum' />
      <ActionBarSection groups={editorActions} />
      <AttributesSection />
    </>
  );
};
