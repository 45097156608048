import { literal, number, object, string } from 'zod';

export const userRoleDefinition = {
  free: {
    translation: 'roles.free' as const,
    mockProjects: 0,
    projectLimit: 0,
  },
  community: {
    translation: 'roles.community' as const,
    mockProjects: 3,
    projectLimit: 3,
  },
  standard: {
    translation: 'roles.standard' as const,
    mockProjects: 6,
    projectLimit: 8,
  },
  pro: {
    translation: 'roles.pro' as const,
    mockProjects: 8,
    projectLimit: 1000,
  },
};

export type UserRole = keyof typeof userRoleDefinition;

export type User = {
  role: UserRole;
  authExpirationDate: Date | null;
  data: {
    mode: string;
    email: string;
    name: string;
  } | null;
};

export const UserJwtSchema = object({
  iss: literal('https://de.spectrumexpress.de'),
  aud: literal('https://de.spectrumexpress.de'),
  iat: number(),
  nbf: number(),
  exp: number(),
  data: object({
    mode: string(),
    user: string(),
  }),
}).transform<User>((payload) => ({
  role: 'community' as UserRole,
  authExpirationDate: new Date(payload.exp * 1000),
  data: {
    mode: payload.data.mode,
    name: payload.data.user.split('@')[0] ?? 'Rick',
    email: payload.data.user,
  },
}));
