import { Link } from '@tanstack/react-router';
import { FC } from 'react';

import {
  CardContent,
  CardContentGridProps,
} from '$/components/common/Card/CardContent';
import { endpointFactory } from '$/services/endpoints';

interface Props extends CardContentGridProps {
  id: string;
  label: string;
}

export const InspirationCard: FC<Props> = ({ label, id, ...props }) => {
  return (
    <Link to='/inspirations/$id' params={{ id }}>
      <CardContent
        label={label}
        image={endpointFactory.sceneImageUrl({ id })}
        bg='transparent'
        {...props}
      />
    </Link>
  );
};
