import { useTranslation } from 'react-i18next';

import { TooltipHeader } from '$/pages/DashboardPages/components/TooltipHeader';

export const InspirationHeader = () => {
  const { t } = useTranslation();

  return (
    <TooltipHeader
      header={t('dashboard.inspiration.header')}
      info={t('dashboard.inspiration.description')}
    />
  );
};
