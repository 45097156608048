import {
  IconButton,
  Menu,
  MenuItem,
  Flex,
  Text,
  DrawerHeader,
  DrawerBody,
  Box,
  HStack,
} from '@chakra-ui/react';
import { t } from 'i18next';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  selectedSubFilter: string;
  closeFilterDrawer: () => void;
  setSubFilter: (filterSearchName: string | null) => void;
}

export const MobileSubFilterPage: FC<Props> = ({
  selectedSubFilter,
  closeFilterDrawer,
  setSubFilter,
}) => {
  const possibleFilter = useCollectionStore.useFilter();
  const filterGroups = useCollectionStore.useFilterGroups();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const resetFilterGroup = useCollectionStore.useResetFilterGroup();
  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeFilterDrawer(),
  });

  const filter = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ].find((x) => x.searchName === selectedSubFilter);

  if (!filter) {
    return undefined;
  }

  const filterGroup = filterGroups.find((x) => x.type === selectedSubFilter);

  const isFilterSelected = (intName: string) =>
    filterGroup?.type === selectedSubFilter && filterGroup?.option === intName;

  const isNoFilterSelected = () => !filterGroup;

  return (
    <Box>
      <DrawerHeader {...swipeEvents}>
        <HStack gap='0'>
          <IconButton
            pr='2.5'
            aria-label=''
            icon={
              <Icon
                transform={'rotate(180deg)'}
                icon='arrow_right'
                width='13px'
              />
            }
            onClick={() => {
              if (selectedSubFilter === 'collection') {
                closeFilterDrawer();
              }
              setSubFilter(null);
            }}
            variant='text'
          />
          <Text fontSize='md'>{filter?.displayName}</Text>
        </HStack>
      </DrawerHeader>

      <DrawerBody>
        <Menu>
          <MenuItem
            px='3'
            py='4'
            fontWeight='bold'
            bg={isNoFilterSelected() ? 'secondaryBackground' : 'background'}
            borderRadius='4px'
            onClick={async () => {
              await resetFilterGroup(
                filter?.searchName ? [filter.searchName] : [],
              );
              closeFilterDrawer();
            }}
          >
            <Flex align='center' justify='space-between' w='full'>
              <Text fontSize='sm'>{t('dashboard.collection.show')}</Text>
              <Text color='lighterText' fontSize='xs'>
                {filter?.items.reduce((acc, item) => acc + item.amount, 0)}
              </Text>
            </Flex>
          </MenuItem>
          {filter?.items.map((item) => {
            return (
              <MenuItem
                key={item.intName}
                px='3'
                py='4'
                fontWeight='bold'
                bg={
                  isFilterSelected(item.intName)
                    ? 'secondaryBackground'
                    : 'background'
                }
                borderRadius='4px'
                onClick={async () => {
                  await activateFilterGroup(
                    {
                      displayName: item.displayName,
                      type: item.searchName,
                      option: item.intName,
                      payload: item.payload,
                    },
                    false,
                  );
                  closeFilterDrawer();
                }}
              >
                <Flex align='center' justify='space-between' w='full'>
                  <Text fontSize='sm'>{item.displayName}</Text>
                  <Text color='lighterText' fontSize='xs'>
                    {item.amount}
                  </Text>
                </Flex>
              </MenuItem>
            );
          })}
        </Menu>
      </DrawerBody>
    </Box>
  );
};
