import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { AvailableIcons } from '$/components/common/Icon';
import { MobileFilterMulti } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilterMulti';
import { MobileFilterSingle } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilterSingle';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props extends BoxProps {
  filterSearchName: string;
  onSingleFilterSelected: (filterSearchName: string) => void;
  setIsLoading?: (isLoading: boolean) => void;
  placeholder?: string;
  filterIcon?: AvailableIcons;
}

export const MobileFilter: FC<Props> = ({
  filterSearchName,
  onSingleFilterSelected,
  setIsLoading,
  placeholder,
  filterIcon,
  ...boxProps
}) => {
  const possibleFilter = useCollectionStore.useFilter();
  const filterGroups = useCollectionStore.useFilterGroups();

  const filter = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ].find((x) => x.searchName === filterSearchName);

  if (!filter) {
    return undefined;
  }

  const filterGroup = filterGroups.find((x) => x.type === filterSearchName);

  return (
    <Box {...boxProps}>
      <Show when={filter.selectionFilter === 'single'}>
        <MobileFilterSingle
          filterSearchName={filter.searchName}
          filterGroup={filterGroup}
          onSingleFilterSelected={onSingleFilterSelected}
          placeholder={placeholder}
          filterIcon={filterIcon}
        />
      </Show>
      <Show when={filter.selectionFilter === 'multi'}>
        <MobileFilterMulti
          filterSearchName={filter.searchName}
          setIsLoading={setIsLoading}
        />
      </Show>
    </Box>
  );
};
