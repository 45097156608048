import { FC } from 'react';

import { ChangePasswordForm } from '$/pages/Profile/pages/ChangePasswordModal/components/ChangePasswordForm';
import { ChangePasswordHeader } from '$/pages/Profile/pages/ChangePasswordModal/components/ChangePasswordHeader';

interface Props {
  onSuccess: () => void;
  onReturn: () => void;
}

export const ChangePasswordModal: FC<Props> = ({ onSuccess, onReturn }) => {
  return (
    <>
      <ChangePasswordHeader onReturn={onReturn} />
      <ChangePasswordForm onSuccess={onSuccess} />
    </>
  );
};
