import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { MaterialType } from '$/services/mapper/uses';

export type FavoriteModalMode =
  | 'deleteFavorite'
  | 'renameFolder'
  | 'deleteFolder'
  | 'createFolder';

type FavoriteModalProps = {
  isOpen: boolean;
  modalMode: FavoriteModalMode;
  itemIdentifier: string;
  itemName: string;
  itemType: MaterialType | undefined;
  onClose: () => void;
  openFavoriteModal: (
    modalMode: FavoriteModalMode,
    itemIdentifier: string,
    itemName: string,
    itemType?: MaterialType,
  ) => void;
};

const store = create<FavoriteModalProps>()((set) => ({
  isOpen: false,
  modalMode: 'deleteFavorite',
  itemIdentifier: '',
  itemName: '',
  itemType: undefined,
  onClose: () => {
    set({ isOpen: false });
  },
  openFavoriteModal: (mode, itemIdentifier, itemName, itemType) =>
    set({ modalMode: mode, isOpen: true, itemIdentifier, itemType, itemName }),
  saveAction: () => {},
}));

export const useFavoriteModal = createSelectorHooks(store);
