import { Accordion, AccordionItem, Stack } from '@chakra-ui/react';
import { FC, useState } from 'react';

import { AccordionContent } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter/components/AccordionContent';
import { AccordionHeader } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter/components/AccordionHeader';
import { ActiveFilterText } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter/components/ActiveFilterText';
import { FilterActions } from '$/components/core/Collection/CollectionFilter/components/FilterActions';
import { FilterGroup, FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  onMenuClose: VoidFunction;
  additionalFilter: FilterGroupOption[];
}

export const AdditionalFilter: FC<Props> = ({
  onMenuClose,
  additionalFilter,
}) => {
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();
  const resetFilterGroup = useCollectionStore.useResetFilterGroup();
  const getActiveInCategory = useCollectionStore.useGetActiveInCategory();
  const isAnyCategoryActive = useCollectionStore.useIsAnyCategoryActive();

  const [activeChanges, setActiveChanges] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [loadingItem, setLoadingItem] = useState('');

  const filterTypes = additionalFilter.map((filter) => filter.searchName);

  const handleCheckboxToggle = async (group: FilterGroup) => {
    setActiveChanges(true);
    setLoadingItem(group.option);
    await activateFilterGroup(group, true).finally(() => setLoadingItem(''));
  };

  const handleFilterReset = async () => {
    setIsActionLoading(true);
    await resetFilterGroup(filterTypes).finally(() =>
      setIsActionLoading(false),
    );
    onMenuClose();
  };

  const handleFilterSubmit = () => {
    onMenuClose();
  };

  return (
    <Stack pb='5'>
      <Accordion minW='23rem' allowToggle>
        {additionalFilter.map((filter) => {
          const activeFilters = getActiveInCategory(filter.searchName);

          return (
            <AccordionItem key={filter.searchName}>
              {({ isExpanded }) => (
                <>
                  <AccordionHeader
                    isExpanded={isExpanded}
                    label={filter.displayName}
                  />
                  <AccordionContent
                    filter={filter}
                    loadingItem={loadingItem}
                    onToggle={handleCheckboxToggle}
                  />
                  <ActiveFilterText
                    isExpanded={isExpanded}
                    activeFilter={activeFilters}
                  />
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>

      <FilterActions
        anyFilterActive={isAnyCategoryActive(filterTypes)}
        activeChanges={activeChanges}
        isLoading={isActionLoading}
        onSubmit={handleFilterSubmit}
        onReset={handleFilterReset}
        px='4'
        py='5'
      />
    </Stack>
  );
};
