import { Stack, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';

export const NoResultCard = () => {
  const { t } = useTranslation();
  const setQuery = useMaterialSearchStore.useSetQuery();

  return (
    <Stack
      justifyContent='center'
      p='4'
      border='solid 1px'
      borderColor='border'
      borderRadius='7px'
    >
      <Text fontSize='sm' textAlign='center'>
        {t('inspirationEditor.search.noResults')}
      </Text>
      <Button fontSize='sm' onClick={() => setQuery('')} variant='ghost'>
        {t('inspirationEditor.search.reset')}
      </Button>
    </Stack>
  );
};
