import { Grid } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { useIsMobile } from '$/hooks/useIsMobile';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';

interface Props {
  children: ReactNode;
}

export const DashboardSectionRow: FC<Props> = ({ children }) => {
  const isMobile = useIsMobile();

  return (
    <Grid
      justifyContent='flex-start'
      flexWrap='wrap'
      gap={{ base: '3', md: '6' }}
      templateColumns={materialTemplateColumn()}
      w={isMobile ? 'full' : 'calc(100% - 15px)'}
    >
      {children}
    </Grid>
  );
};
