import { Stack } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrightnessFilterMenuItem } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessFilterMenuItem';
import { FilterActions } from '$/components/core/Collection/CollectionFilter/components/FilterActions';
import { TooltipLabel } from '$/pages/DashboardPages/components/TooltipLabel';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  onMenuClose: () => void;
}

export const BrightnessFilter: FC<Props> = ({ onMenuClose }) => {
  const { t } = useTranslation();

  const setBrightnessFilter = useCollectionStore.useSetBrightnessFilter();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();

  const [activeChanges, setActiveChanges] = useState(false);
  const [brightness, setBrightness] = useState<number[]>(() => [
    brightnessFilter.from,
    brightnessFilter.to,
  ]);

  useEffect(() => {
    const sync = () => {
      const brightnessFilter = useCollectionStore.getState().brightnessFilter;
      setBrightness([brightnessFilter.from, brightnessFilter.to]);
    };

    return useCollectionStore.subscribe((s) => s.brightnessFilter, sync);
  }, []);

  const handleChange = (value: number[]) => {
    setActiveChanges(true);
    setBrightness(value);
  };

  const handleReset = () => {
    setBrightness([0, 100]);
    setActiveChanges(true);
    setBrightnessFilter({ from: 0, to: 100 });
    onMenuClose();
  };

  const handleSubmit = () => {
    setActiveChanges(false);
    setBrightnessFilter({ from: brightness[0], to: brightness[1] });
    onMenuClose();
  };

  return (
    <>
      <BrightnessFilterMenuItem value={brightness} setValue={handleChange} />
      <Stack gap='4' p='3'>
        <TooltipLabel
          label={t('dashboard.collection.whatIsBrightness')}
          info={t('dashboard.collection.brightnessExplanation')}
        />
        <FilterActions
          anyFilterActive={brightness[0] !== 0 || brightness[1] !== 100}
          activeChanges={activeChanges}
          onSubmit={handleSubmit}
          onReset={handleReset}
        />
      </Stack>
    </>
  );
};
