import { Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  label: string;
}

export const NavbarLabel: FC<Props> = ({ label }) => {
  return (
    <Text px='4' pb='2' color='lighterText' fontSize='xs' fontWeight='bold'>
      {label}
    </Text>
  );
};
