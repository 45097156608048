import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  MenuItem,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { StyledLink } from '$/components/core/StyledLink';
import { useToast } from '$/hooks/useToast';
import {
  UserRole,
  userRoleDefinition,
} from '$/services/usecases/authentication/mapper/jwt';

interface Props {
  height?: string;
  showHomeLink?: boolean;
}

export const BrandMenu: FC<Props> = ({ height, showHomeLink = true }) => {
  const { t } = useTranslation();

  const showToast = useToast();

  const { role, data } = useAuthenticationStore.useUser();
  const setMockedUserRole = useAuthenticationStore.useSetMockedUserRole();

  const onChangeUserRole = (value: string | string[]) => {
    const newRole = value as UserRole;
    setMockedUserRole(newRole);
    // Didn't use translations here because this is just a dummy toast that won't be in the final product
    showToast(
      newRole === 'free' ? 'User Role Downgraded!' : 'User Role Changed!',
      newRole === 'free' ? 'error' : 'success',
      `User has now the role ${newRole}`,
    );
  };

  const canUseRole = (role: UserRole) => {
    if (data == null) {
      return role === 'free';
    }
    return role !== 'free';
  };

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <Flex align='center' gap='3' h='full'>
            <Link to='/'>
              <Icon icon='caparol' width='28px' height='26px' />
            </Link>
            <MenuButton
              as={GhostButton}
              h={height}
              color='header.text'
              rightIcon={
                <Icon
                  transition='transform 0.3s ease'
                  transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
                  icon='chevron_down'
                  w='12px'
                />
              }
            >
              {t('brand.spectrum')}
            </MenuButton>
          </Flex>

          {isOpen && (
            <MenuList fontSize='sm' borderColor='border'>
              <Show when={showHomeLink}>
                <MenuItem>
                  <StyledLink link={{ to: '/' }} w='full'>
                    {t('dashboard.header.toStart')}
                  </StyledLink>
                </MenuItem>
                <MenuDivider />
              </Show>

              <MenuItem>{t('dashboard.header.languages')}</MenuItem>
              <MenuItem>{t('dashboard.header.shortcuts')}</MenuItem>
              <MenuItem>
                <StyledLink w='full' link={{ to: '/imprint' }}>
                  {t('dashboard.header.contact')}
                </StyledLink>
              </MenuItem>
              <MenuItem>{t('dashboard.header.termsOfCondition')}</MenuItem>
              <MenuDivider />
              <MenuItem justifyContent='space-between'>
                <Box as='span'>{t('dashboard.header.theme')}</Box>
                <Box h='32px' p='0'>
                  <ThemeSwitcher />
                </Box>
              </MenuItem>
              <MenuDivider />
              <MenuOptionGroup
                ml='3'
                onChange={onChangeUserRole}
                title='User Role'
                type='radio'
                value={role}
              >
                {Object.entries(userRoleDefinition).map(([key, role]) => (
                  <MenuItemOption
                    key={key}
                    isDisabled={!canUseRole(key as UserRole)}
                    value={key}
                  >
                    {t(role.translation)}
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
              {import.meta.env.VITE_SENTRY_ENV !== 'production' && (
                <>
                  <MenuDivider />
                  <MenuOptionGroup ml='3' title='Dokumentation' type='radio'>
                    <MenuItem>
                      <Link to='/docs/api'>API Dokumentation</Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to='/docs/technical'>Technische Dokumentation</Link>
                    </MenuItem>
                  </MenuOptionGroup>
                </>
              )}
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};
