import { VStack, HStack, Text, Spacer, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { BrushShapeOptionButton } from '$/pages/EditorPage/components/AttributesSection/components/BrushShapeOptionButton';
import { SliderInput } from '$/pages/EditorPage/components/AttributesSection/components/BrushSizeInput';
import { CurrentColorSection } from '$/pages/EditorPage/components/AttributesSection/components/CurrentColorSection';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import {
  Tip,
  TipSection,
} from '$/pages/EditorPage/components/AttributesSection/components/TipSection';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';
import { ModuleSendBrushSizeAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

const maxValue = 100;
const minValue = 1;

export const BrushSidebar = () => {
  const { t } = useTranslation();
  const brushSize = useEditorStore.useBrushSize();
  const { setBrushSize } = useEditorActions();
  const [isVisible, setIsVisible] = useState(false);

  const tips: Tip[] = [
    {
      tipHeader: t('editor.tips.brushUseCase.title'),
      tipContent: t('editor.tips.brushUseCase.content'),
    },
    {
      tipHeader: t('editor.tips.drawStraightLine.title'),
      tipContent: t('editor.tips.drawStraightLine.content'),
    },
  ];

  return (
    <VStack w='full' fontSize='sm'>
      <TitleSection title={t('editor.brush')} />
      <SidebarDivider />
      <CurrentColorSection />
      <VisualizationSection />
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <HStack>
          <Icon icon='brush' boxSize='4' />
          <Text fontWeight='bold'>{t('editor.brushSize')}</Text>
        </HStack>
        <SliderInput
          initialValue={brushSize}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(val) =>
            setBrushSize(ModuleSendBrushSizeAction.BrushSize, val)
          }
        />
        <HStack w='full' pt='4'>
          <Text>{t('editor.brushShape')}</Text>
          <Spacer />
          <BrushShapeOptionButton type='round' />
          <BrushShapeOptionButton type='square' />
        </HStack>
      </VStack>
      <SidebarDivider />
      <Box mx='4' mt='4'>
        <TipSection tips={tips} />
        <VisibilityToggle currentState={isVisible} onChange={setIsVisible} />
      </Box>
    </VStack>
  );
};
