import {
  AbsoluteCenter,
  Box,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, MouseEvent } from 'react';

import { DeleteButton } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/DeleteButton';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { endpointFactory } from '$/services/endpoints';

interface ImageURLProps {
  variantButtonIndex: number;
}

export const VariantCard: FC<ImageURLProps> = ({ variantButtonIndex }) => {
  const activeSceneVariantIndex =
    useInspirationEditorStore.useActiveSceneVariantIndex();
  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();
  const setActiveSceneVariantIndex =
    useInspirationEditorStore.useSetActiveSceneVariantIndex();
  const isActive = activeSceneVariantIndex === variantButtonIndex;
  const { isOpen, onToggle, onClose } = useDisclosure();
  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const handleRightClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    onToggle();
  };

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Box
          pos='relative'
          h='inherit'
          border='2px solid'
          borderColor={isActive ? 'editor.objectText' : 'transparent'}
          borderRadius='4px'
        >
          <AbsoluteCenter userSelect='none' pointerEvents='none'>
            <Text color='reverseText' fontSize='xl' fontWeight='bold'>
              {sceneVariantLabelOrder[variantButtonIndex]}
            </Text>
          </AbsoluteCenter>
          <Image
            h='full'
            border='2px solid'
            borderColor='editor.borderVariants'
            borderRadius='4px'
            cursor='pointer'
            alt={scene?.id}
            onClick={() => setActiveSceneVariantIndex(variantButtonIndex)}
            onContextMenu={handleRightClick}
            src={endpointFactory.sceneImageUrl({ id: scene?.id ?? '0' })}
          />
        </Box>
      </PopoverTrigger>
      {sceneLayersVariants.length > 1 && (
        <PopoverContent w='fit-content'>
          <PopoverArrow />
          <DeleteButton buttonIndex={variantButtonIndex} onClose={onClose} />
        </PopoverContent>
      )}
    </Popover>
  );
};
