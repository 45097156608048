import { VStack } from '@chakra-ui/react';

import { Show } from '$/components/common/Flow/Show';
import { AttributeSidebar } from '$/components/core/Editor/AttributeSidebar';
import { ColorSelectionSidebar } from '$/pages/InspirationEditorPage/components/AttributeSection/components/ColorSelectionSidebar';
import { SceneDetails } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const InspirationAttributesSection = () => {
  const activeId = useInspirationEditorStore.useActiveComponentId();

  return (
    <AttributeSidebar overflowY='auto' pb='0'>
      <VStack w='full'>
        <Show when={activeId != null} fallback={() => <SceneDetails />}>
          <ColorSelectionSidebar />
        </Show>
      </VStack>
    </AttributeSidebar>
  );
};
