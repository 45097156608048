import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { FC } from 'react';

import { trackContentInteraction } from '$/logger';
import { PiwikContentName, PiwikContentPiece } from '$/utils/piwikUtils';

export interface TrackedButtonProps extends CheckboxProps {
  contentName?: PiwikContentName;
  contentPiece?: PiwikContentPiece;
}

export const TrackedCheckbox: FC<TrackedButtonProps> = ({
  contentName,
  contentPiece,
  onChange,
  ...props
}) => {
  return (
    <Checkbox
      onChange={(e) => {
        if (contentName && contentPiece) {
          trackContentInteraction(
            'ClickButton',
            contentName,
            contentPiece,
            e.target.checked ? 'Enable' : 'Disable',
          );
        }

        onChange && onChange(e);
      }}
      {...props}
    ></Checkbox>
  );
};
