import { createRoute } from '@tanstack/react-router';

import { BaseDashboardLayout } from '$/components/layouts/BaseDashboardLayout/BaseDashboardLayout';
import { RootRoute } from '$/routes/RootRoute';

export const BaseRouteLayout = createRoute({
  getParentRoute: () => RootRoute,
  component: BaseDashboardLayout,
  id: 'dashboardLayout',
});
