import { Box, Button, Text, VStack } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { registerUser } from '$/services/usecases/authentication';
import {
  containsLowercase,
  containsNumber,
  containsSpaces,
  containsSpecialChar,
  containsUppercase,
  emailFormatRegex,
} from '$/utils/regexUtils';

export type RegisterData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatedPassword: string;
};

const RegisterInput = (props: HookFormInputProps<RegisterData>) => (
  <HookFormInput<RegisterData> {...props} />
);

export const RegisterForm = () => {
  const { t, i18n } = useTranslation();
  const setModalType = useAuthenticationStore.useSetModalType();
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const formMethods = useForm<RegisterData>({ mode: 'onTouched' });
  const [error, setError] = useState<string | null>(null);

  const onRegister = async (data: RegisterData) => {
    const { error, isSuccessful } = await registerUser(data, i18n.language);

    if (isSuccessful) {
      setModalType('registerSuccess');
    } else {
      const code = error?.code ?? -1;
      setError(t(`dashboard.authentication.errorMessages.${code}`));
    }
  };

  const isPasswordValid = (password: string) =>
    password.length >= 10 &&
    containsUppercase(password) &&
    containsLowercase(password) &&
    containsNumber(password) &&
    containsSpecialChar(password) &&
    !containsSpaces(password);

  return (
    <FormProvider {...formMethods}>
      <VStack as='form' gap='8' onSubmit={formMethods.handleSubmit(onRegister)}>
        <RegisterInput
          accessor='firstName'
          label={t('dashboard.authentication.firstNameLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.firstNameLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
          }}
        />
        <RegisterInput
          accessor='lastName'
          label={t('dashboard.authentication.lastNameLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.lastNameLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
          }}
        />
        <RegisterInput
          type='email'
          accessor='email'
          label={t('dashboard.authentication.emailLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.emailLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            pattern: {
              value: emailFormatRegex,
              message: t('dashboard.authentication.errorMessages.wrongFormat'),
            },
          }}
          description={t('dashboard.authentication.errorMessages.emailRules')}
        />
        <RegisterInput
          type={showPassword ? 'text' : 'password'}
          accessor='password'
          label={t('dashboard.authentication.insertPasswordLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.passwordLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            validate: {
              passwordValid: (v) => {
                if (
                  formMethods.formState.touchedFields.repeatedPassword &&
                  formMethods.getValues('repeatedPassword') != v
                ) {
                  void formMethods.trigger('repeatedPassword');
                }

                return (
                  isPasswordValid(v) ||
                  t(
                    'dashboard.authentication.errorMessages.passwordRulesMismatch',
                  )
                );
              },
            },
          }}
          inputRightElement={
            <Button
              onClick={() => setShowPassword((state) => !state)}
              variant='text'
            >
              <Icon icon={showPassword ? 'visibility_on' : 'visibility_off'} />
            </Button>
          }
          description={t(
            'dashboard.authentication.errorMessages.passwordRules',
          )}
        />
        <RegisterInput
          type={showRepeatedPassword ? 'text' : 'password'}
          accessor='repeatedPassword'
          label={t('dashboard.authentication.repeatPasswordLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.passwordLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            validate: {
              samePassword: (_, values) =>
                values.repeatedPassword === values.password ||
                t('dashboard.authentication.errorMessages.passwordMismatch'),
            },
          }}
          inputRightElement={
            <Button
              onClick={() => setShowRepeatedPassword((state) => !state)}
              variant='text'
            >
              <Icon
                icon={showRepeatedPassword ? 'visibility_on' : 'visibility_off'}
              />
            </Button>
          }
        />
        {error && (
          <Text color='danger.1000' fontWeight='bold'>
            {error}
          </Text>
        )}
        <TrackedButton
          isDisabled={!formMethods.formState.isValid}
          type='submit'
          contentName='CloudAccount'
          contentPiece='Registration'
          contentTarget='NewAccount'
        >
          {t('dashboard.authentication.createAccountButton')}
        </TrackedButton>
        <Text fontSize='sm'>
          <Trans
            i18nKey='dashboard.authentication.createAccountLabel'
            components={{
              TermsLink: (
                <Box
                  as='span'
                  color='primaryButton.background'
                  textDecoration='underline'
                >
                  <Link to='https://www.youtube.com/watch?v=dQw4w9WgXcQ' />
                </Box>
              ),
              PrivacyLink: (
                <Box
                  as='span'
                  color='primaryButton.background'
                  textDecoration='underline'
                >
                  <Link to='https://www.youtube.com/watch?v=dQw4w9WgXcQ' />
                </Box>
              ),
            }}
          />
        </Text>
      </VStack>
    </FormProvider>
  );
};
