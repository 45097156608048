import { createRoute } from '@tanstack/react-router';

import { LoadEditorPage } from '$/pages/EditorPage/pages/LoadEditorPage';
import { EditorRouteLayout } from '$/routes/Layouts/EditorLayout';

export const NewProjectEditorRoute = createRoute({
  getParentRoute: () => EditorRouteLayout,
  path: '/editor',
  component: LoadEditorPage,
});
