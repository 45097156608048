import { Box, Fade } from '@chakra-ui/react';
import { FC } from 'react';
import { KeepScale } from 'react-zoom-pan-pinch';

import { useIdleUserHook } from '$/hooks/useIdleUserHook';
import { ImageMarkerButton } from '$/pages/InspirationEditorPage/components/ImageSection/components/ImageMarkerButton';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  isImageLoading: boolean;
}

export const ImageMarkers: FC<Props> = ({ isImageLoading }) => {
  const layerMarkers = useInspirationEditorStore.useLayerMarkers();
  const activeVariantIndex =
    useInspirationEditorStore.useActiveSceneVariantIndex();

  const isUserActive = useIdleUserHook();

  if (isImageLoading) return null;

  return (
    <Fade
      transition={{ enter: { duration: 0.2 }, exit: { duration: 0.5 } }}
      in={!isUserActive}
      unmountOnExit
    >
      {layerMarkers.map((marker) => (
        <Box
          key={`${marker.id}-${activeVariantIndex}`}
          as={KeepScale}
          pos='absolute'
          top={`calc(${marker.position.y}% - 16px)`}
          left={`calc(${marker.position.x}% - 16px)`}
        >
          <ImageMarkerButton markerId={marker.id} />
        </Box>
      ))}
    </Fade>
  );
};
