import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/fonts/Gotham-Bold.otf') format('opentype');
      }

      @font-face {
        font-family: 'Gotham';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/fonts/Gotham-Book.otf') format('opentype');
      }
    `}
  />
);
