import { Divider, Spacer } from '@chakra-ui/react';

import { LayerSidebar } from '$/components/core/Editor/LayerSidebar';
import { Layers } from '$/pages/InspirationEditorPage/components/LayerSection/components/Layers';
import { PerspectiveSection } from '$/pages/InspirationEditorPage/components/LayerSection/components/PerspectiveSection';
import { VariantSection } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const LayerSection = () => {
  const scene = useInspirationEditorStore.useScene();

  return (
    <LayerSidebar projectName={scene?.name} pb='4'>
      <Layers />
      <Spacer
        onClick={() =>
          useInspirationEditorStore.setState({
            activeComponentId: null,
            hoveredComponentId: null,
          })
        }
      />
      <Divider />
      <PerspectiveSection scene={scene} />
      <Divider />
      <VariantSection />
    </LayerSidebar>
  );
};
