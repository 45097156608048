import { createRoute } from '@tanstack/react-router';

import { NotAllowedPage } from '$/pages/Authentication/pages/NotAllowedPage';
import { RootRoute } from '$/routes/RootRoute';

export const NotAllowedRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/not-allowed',
  component: NotAllowedPage,
});
