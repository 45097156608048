import { ActionBarSection } from '$/pages/InspirationEditorPage/components/ActionBarSection';
import { InspirationAttributesSection } from '$/pages/InspirationEditorPage/components/AttributeSection';
import { ImageSection } from '$/pages/InspirationEditorPage/components/ImageSection';
import { LayerSection } from '$/pages/InspirationEditorPage/components/LayerSection';
import { InspirationsEditorRoute } from '$/routes/Inspirations/InspirationsEditorRoute';

export const InspirationEditorPage = () => {
  const { id } = InspirationsEditorRoute.useParams();

  return (
    <>
      <ImageSection id={id} />
      <LayerSection />
      <InspirationAttributesSection />
      <ActionBarSection groups={[]} />
    </>
  );
};
