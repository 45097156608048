export const reorderArray = <T>(
  items: T[],
  startIndex: number,
  endIndex: number,
) => {
  const result = [...items];
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const pickRandomElement = <T>(arr: T[]): T => {
  return arr[Math.floor(Math.random() * arr.length)];
};

export const shuffle = <T>(arr: T[]): T[] => {
  const result = [...arr];
  for (let i = result.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [result[i], result[j]] = [result[j], result[i]];
  }
  return result;
};

export const repeat = (n: number) => {
  return [...Array<number>(n).keys()];
};

// This is actually typed, because it just extends any, to then infer
// it with the real type. Disabling this should bo ok here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
  arr.reduce(
    (groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<K, T[]>,
  );

export const range = (from: number, to: number): number[] => {
  const result: number[] = [];

  const start = Math.min(from, to);
  const end = Math.max(from, to);

  for (let i = start; i <= end; i++) {
    result.push(i);
  }

  return result;
};
