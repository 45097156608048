import { VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { useToast } from '$/hooks/useToast';
import {
  ProfileFields,
  ProfileInputField,
} from '$/pages/Profile/pages/ProfileModal/components/ProfileInputField';
import { ProfileRoute } from '$/routes/Account/ProfileRoute';
import { ProfileData, updateUserData } from '$/services/usecases/profile';
import { emailFormatRegex } from '$/utils/regexUtils';

export const ProfileFormSection = () => {
  const { t } = useTranslation();

  const formMethods = useForm<ProfileData>({ mode: 'onChange' });
  const toast = useToast();

  const user = ProfileRoute.useLoaderData();
  const setUser = useAuthenticationStore.useSetUser();

  const [loading, setLoading] = useState(false);
  const [inEditMode, setInEditMode] = useState<ProfileFields | null>(null);

  const onChange = async (data: ProfileData) => {
    try {
      setLoading(true);
      const newUser = await updateUserData(data);
      if (!newUser) return;
      setUser({
        role: user.role,
        authExpirationDate: user.authExpirationDate,
        data: { ...user.data, ...newUser.data },
      });
      toast(
        t('dashboard.authentication.profile.changeData.successTitle'),
        'success',
        t('dashboard.authentication.profile.changeData.successDescription'),
      );
    } catch (_) {
      toast(
        t('dashboard.authentication.profile.changeData.errorTitle'),
        'error',
        t('dashboard.authentication.profile.changeData.errorDescription'),
      );
      formMethods.reset();
    } finally {
      setLoading(false);
      setInEditMode(null);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <VStack as='form' w='full' onSubmit={formMethods.handleSubmit(onChange)}>
        <ProfileInputField
          accessor='name'
          label={t('dashboard.authentication.profile.nameLabel')}
          inEditMode={inEditMode === 'name'}
          value={user.data.name}
          setActive={setInEditMode}
          locked={inEditMode !== null}
          isLoading={inEditMode === 'name' && loading}
          trackedProfileInputField='EditName'
        />
        <ProfileInputField
          accessor='email'
          label={t('dashboard.authentication.profile.emailLabel')}
          inEditMode={inEditMode === 'email'}
          value={user.data.email}
          setActive={setInEditMode}
          locked={inEditMode !== null}
          isLoading={inEditMode === 'email' && loading}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            pattern: {
              value: emailFormatRegex,
              message: t('dashboard.authentication.errorMessages.wrongFormat'),
            },
          }}
          trackedProfileInputField='EditMail'
        />
      </VStack>
    </FormProvider>
  );
};
