import { Divider, Stack, StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/ShareSection';
import { SceneDetailSection } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/SceneDetailSection';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const SceneDetails: FC<StackProps> = (props) => {
  const { t } = useTranslation();

  const layers = useInspirationEditorStore((state) =>
    state.getActiveSceneVariant(),
  ).filter(
    (layer, pos, self) =>
      self.findIndex((l) => l.material?.id === layer.material?.id) === pos,
  );

  const isLoading = useInspirationEditorStore.useIsLoading();

  return (
    <Stack w='full' borderColor='border' {...props}>
      <ShareSection />
      <Divider />
      <SceneDetailSection
        icon='inspiration_marker'
        label={t('inspirationEditor.attributes.usedColors')}
        description='?'
        isLoading={isLoading}
        items={layers.filter(
          (layer) =>
            layer.material?.type.includes('uni') && !layer.material?.url(),
        )}
      />

      <SceneDetailSection
        icon='wall_material'
        label={t('inspirationEditor.attributes.usedWalls')}
        description='?'
        isLoading={isLoading}
        items={layers.filter(
          (layer) =>
            layer.material?.type.includes('wall') &&
            layer.category !== 'floor' &&
            !!layer.material?.url(),
        )}
      />

      <SceneDetailSection
        icon='floor_material'
        label={t('inspirationEditor.attributes.usedFloors')}
        description='?'
        isLoading={isLoading}
        items={layers.filter(
          (layer) =>
            layer.material?.type.includes('floor') &&
            layer.category === 'floor' &&
            !!layer.material?.url(),
        )}
      />
      <ExportSection />
      <Divider />
    </Stack>
  );
};
