// IMPORTANT: THIS WHOLE FILE IS COPIED FROM REACT-BEAUTIFUL-DND AND MODIFIED TO OUR NEEDS

import { FluidDragActions, PreDragActions, SensorAPI } from '@hello-pangea/dnd';
import { useCallback, useLayoutEffect, useMemo, useRef } from 'react';

const primaryButton: number = 0;
const sloppyClickThreshold: number = 5;

const supportedPageVisibilityEventName: string = ((): string => {
  const base: string = 'visibilitychange';

  // See https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
  const candidates: string[] = [
    base,
    `ms${base}`,
    `webkit${base}`,
    `moz${base}`,
    `o${base}`,
  ];

  const supported = candidates.find(
    (eventName: string): boolean => `on${eventName}` in document,
  );

  return supported || base;
})();

type Position = { x: number; y: number };

type EventOptions = {
  passive?: boolean;
  capture?: boolean;
  once?: boolean;
};

type EventBinding = {
  eventName: string;
  fn: (event: never) => void;
  options?: EventOptions;
};

type Idle = {
  type: 'IDLE';
};

type Pending = {
  type: 'PENDING';
  point: Position;
  actions: PreDragActions;
};

type Dragging = {
  type: 'DRAGGING';
  actions: FluidDragActions;
};

type Phase = Idle | Pending | Dragging;

const idle: Idle = { type: 'IDLE' };

type UnbindFn = () => void;

function isSloppyClickThresholdExceeded(
  original: Position,
  current: Position,
): boolean {
  return (
    Math.abs(current.x - original.x) >= sloppyClickThreshold ||
    Math.abs(current.y - original.y) >= sloppyClickThreshold
  );
}

function getOptions(
  shared?: EventOptions,
  fromBinding?: EventOptions,
): EventOptions {
  return {
    ...shared,
    ...fromBinding,
  };
}

const invariant = (condition: unknown, message?: string) => {
  if (condition) {
    return;
  }

  throw Error(message);
};

const bindEvents = (
  el: HTMLElement | Window,
  bindings: EventBinding[],
  sharedOptions?: EventOptions,
) => {
  const unbindings: UnbindFn[] = bindings.map(
    (binding: EventBinding): UnbindFn => {
      const options = getOptions(sharedOptions, binding.options);

      el.addEventListener(
        binding.eventName,
        binding.fn as EventListenerOrEventListenerObject,
        options,
      );

      return function unbind() {
        el.removeEventListener(
          binding.eventName,
          binding.fn as EventListenerOrEventListenerObject,
          options,
        );
      };
    },
  );

  // Return a function to unbind events
  return function unbindAll() {
    unbindings.forEach((unbind: UnbindFn) => {
      unbind();
    });
  };
};

type GetCaptureArgs = {
  cancel: () => void;
  completed: () => void;
  getPhase: () => Phase;
  setPhase: (phase: Phase) => void;
};

function getCaptureBindings({
  cancel,
  completed,
  getPhase,
  setPhase,
}: GetCaptureArgs): EventBinding[] {
  return [
    {
      eventName: 'mousemove',
      fn: (event: MouseEvent) => {
        const { button, clientX, clientY } = event;
        if (button !== primaryButton) {
          return;
        }

        const point: Position = {
          x: clientX,
          y: clientY,
        };

        const phase: Phase = getPhase();

        // Already dragging
        if (phase.type === 'DRAGGING') {
          // preventing default as we are using this event
          event.preventDefault();
          phase.actions.move(point);
          return;
        }

        // There should be a pending drag at this point
        invariant(phase.type === 'PENDING', 'Cannot be IDLE');
        const pendingPhase = phase as Pending;
        const pending: Position = pendingPhase.point;

        // threshold not yet exceeded
        if (!isSloppyClickThresholdExceeded(pending, point)) {
          return;
        }

        // preventing default as we are using this event
        event.preventDefault();

        // Lifting at the current point to prevent the draggable item from
        // jumping by the sloppyClickThreshold
        const actions: FluidDragActions = pendingPhase.actions.fluidLift(point);

        setPhase({
          type: 'DRAGGING',
          actions,
        });
      },
    },
    {
      eventName: 'mouseup',
      fn: (event: MouseEvent) => {
        const phase: Phase = getPhase();

        if (phase.type !== 'DRAGGING') {
          cancel();
          return;
        }

        // preventing default as we are using this event
        event.preventDefault();
        phase.actions.drop({ shouldBlockNextClick: true });
        completed();
      },
    },
    {
      eventName: 'mousedown',
      fn: (event: MouseEvent) => {
        // this can happen during a drag when the user clicks a button
        // other than the primary mouse button
        if (getPhase().type === 'DRAGGING') {
          event.preventDefault();
        }

        cancel();
      },
    },
    {
      eventName: 'resize',
      fn: cancel,
    },
    {
      eventName: 'scroll',
      // kill a pending drag if there is a window scroll
      options: { passive: true, capture: false },
      fn: () => {
        if (getPhase().type === 'PENDING') {
          cancel();
        }
      },
    },
    {
      eventName: supportedPageVisibilityEventName,
      fn: cancel,
    },
  ];
}

export const useCustomMouseSensor = (api: SensorAPI) => {
  const phaseRef = useRef<Phase>(idle);
  const unbindEventsRef = useRef<() => void>(() => {});

  const startCaptureBinding: EventBinding = useMemo(
    () => ({
      eventName: 'mousedown',
      fn: function onMouseDown(event: MouseEvent) {
        if (event.defaultPrevented) {
          return;
        }
        if (event.button !== primaryButton) {
          return;
        }

        const draggableId = api.findClosestDraggableId(event);

        if (!draggableId) {
          return;
        }

        const actions = api.tryGetLock(draggableId, stop, {
          sourceEvent: event,
        });

        if (!actions) {
          return;
        }

        event.preventDefault();

        const point: Position = {
          x: event.clientX,
          y: event.clientY,
        };

        unbindEventsRef.current();
        startPendingDrag(actions, point);
      },
    }),
    // not including startPendingDrag as it is not defined initially
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api],
  );

  const listenForCapture = useCallback(
    function listenForCapture() {
      const options: EventOptions = {
        passive: false,
        capture: true,
      };

      unbindEventsRef.current = bindEvents(
        window,
        [startCaptureBinding],
        options,
      );
    },
    [startCaptureBinding],
  );

  const stop = useCallback(() => {
    const current: Phase = phaseRef.current;
    if (current.type === 'IDLE') {
      return;
    }

    phaseRef.current = idle;
    unbindEventsRef.current();

    listenForCapture();
  }, [listenForCapture]);

  const cancel = useCallback(() => {
    const phase: Phase = phaseRef.current;
    stop();
    if (phase.type === 'DRAGGING') {
      phase.actions.cancel({ shouldBlockNextClick: true });
    }
    if (phase.type === 'PENDING') {
      phase.actions.abort();
    }
  }, [stop]);

  const bindCapturingEvents = useCallback(
    function bindCapturingEvents() {
      const options = { capture: true, passive: false };
      const bindings: EventBinding[] = getCaptureBindings({
        cancel,
        completed: stop,
        getPhase: () => phaseRef.current,
        setPhase: (phase: Phase) => {
          phaseRef.current = phase;
        },
      });

      unbindEventsRef.current = bindEvents(window, bindings, options);
    },
    [cancel, stop],
  );

  const startPendingDrag = useCallback(
    function startPendingDrag(actions: PreDragActions, point: Position) {
      invariant(
        phaseRef.current.type === 'IDLE',
        'Expected to move from IDLE to PENDING drag',
      );
      phaseRef.current = {
        type: 'PENDING',
        point,
        actions,
      };
      bindCapturingEvents();
    },
    [bindCapturingEvents],
  );

  useLayoutEffect(
    function mount() {
      listenForCapture();

      // kill any pending window events when unmounting
      return function unmount() {
        unbindEventsRef.current();
      };
    },
    [listenForCapture],
  );
};
