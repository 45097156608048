import { StackProps } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

import { DesktopSearchDropdown } from '$/components/core/Collection/MaterialSearch/components/DesktopSearchDropdown';
import { MobileSearchDrawer } from '$/components/core/Collection/MaterialSearch/components/MobileSearchDrawer';
import { useIsMobile } from '$/hooks/useIsMobile';
import { Material } from '$/services/usecases/materials';

interface Props extends StackProps {
  searchLabel: string;
  onResultClick?: (material: Material) => void;
  idFilter?: string[];
}

export const SearchDropdown: FC<Props> = ({
  searchLabel,
  onResultClick,
  idFilter,
  ...props
}) => {
  const isMobile = useIsMobile();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openSearchDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeSearchDrawer = () => {
    setIsDrawerOpen(false);
  };

  useEffect(() => {
    if (!isMobile && isDrawerOpen) {
      closeSearchDrawer();
    }
  }, [isMobile, isDrawerOpen]);

  if (isMobile) {
    return (
      <MobileSearchDrawer
        searchLabel={searchLabel}
        isDrawerOpen={isDrawerOpen}
        openSearchDrawer={openSearchDrawer}
        closeSearchDrawer={closeSearchDrawer}
        onResultClick={(material: Material) => {
          closeSearchDrawer();
          onResultClick && onResultClick(material);
        }}
      />
    );
  }

  return (
    <DesktopSearchDropdown
      searchLabel={searchLabel}
      onResultClick={onResultClick}
      idFilter={idFilter}
      {...props}
    />
  );
};
