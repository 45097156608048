import {
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { FC, useEffect } from 'react';

import { Icon } from '$/components/common/Icon';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { useDebouncedState } from '$/hooks/useDebouncedState';
import { useIsMobile } from '$/hooks/useIsMobile';

interface Props extends InputGroupProps {
  searchLabel: string;
}

const INPUT_ID = 'search-input';

export const SearchInput: FC<Props> = ({ searchLabel, ...props }) => {
  const query = useMaterialSearchStore.useQuery();
  const setQuery = useMaterialSearchStore.useSetQuery();
  const toggleDropdown = useMaterialSearchStore.useToggleDropdown();
  const isMobile = useIsMobile();

  const [localQuery, setLocalQuery] = useDebouncedState<string>(
    query,
    (val) => setQuery(val),
    500,
  );

  useEffect(() => {
    setLocalQuery(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    toggleDropdown(
      localQuery.length >= 3 && document.activeElement?.id === INPUT_ID,
    );
  }, [localQuery, toggleDropdown]);

  return (
    <InputGroup
      alignItems='center'
      justifyContent='center'
      display='flex'
      w='full'
      h='full'
      data-group
      {...props}
    >
      <InputLeftElement h='full' pointerEvents='none' data-group>
        <Icon
          icon='search'
          w='14px'
          _groupFocusWithin={{ fill: 'text' }}
          fill='neutralText'
        />
      </InputLeftElement>
      <Input
        h={isMobile ? '40px' : 'full'}
        fontSize='sm'
        borderRadius={isMobile ? '8px' : '4px'}
        focusBorderColor='primaryBlue.700'
        id={INPUT_ID}
        onBlur={() => toggleDropdown(false)}
        onChange={(e) => setLocalQuery(e.currentTarget.value)}
        onFocus={() => localQuery.length >= 3 && toggleDropdown(true)}
        placeholder={searchLabel}
        value={localQuery}
      />
      {localQuery.length > 0 && (
        <InputRightElement h='full' pr='2'>
          <IconButton
            color='text'
            aria-label='cancel'
            icon={<Icon icon='cancel' w='15px' />}
            onClick={() => setLocalQuery('')}
            variant='text'
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};
