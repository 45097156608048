import { Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

import { FilterButton } from '$/pages/DashboardPages/pages/Inspiration/components/FilterButton';
import { randomPlaceholder } from '$/utils/stringUtils';

interface Props {
  id: number;
}

export const LoadingFilterCard: FC<Props> = ({ id }) => {
  return (
    <FilterButton minW='15rem' ml='-4px' isDisabled>
      <Skeleton layerStyle='FilterImage' w='16' borderRadius='6px' />
      <Skeleton>{randomPlaceholder(id, 5, 10)}</Skeleton>
    </FilterButton>
  );
};
