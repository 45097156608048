import { Box, Divider, HStack, IconButton, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { LayerSidebar } from '$/components/core/Editor/LayerSidebar';
import { ComponentList } from '$/pages/EditorPage/components/ComponentSection/ComponentList';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import {
  getLetterFromIndex,
  useEditorStore,
} from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  projectName: string;
}

export const ComponentSection: FC<Props> = ({ projectName }) => {
  const { t } = useTranslation();

  const { createComponent } = useEditorActions();

  const components = useEditorStore.useComponents();
  const shiftAccordionItems = useEditorStore.useShiftAccordionItems();

  const handleAddElement = () => {
    const componentName = t('editor.component_name', {
      letter: getLetterFromIndex(components.length + 1),
    });
    createComponent(componentName);
    shiftAccordionItems();
  };

  return (
    <LayerSidebar projectName={projectName}>
      <HStack gap='3' px='4'>
        <Text fontWeight='bold'>{t('editor.componentListHeader')}</Text>
        <IconButton
          minW='0'
          h='auto'
          minH='0'
          p='5px'
          aria-label={t('editor.addComponentIconAlt')}
          icon={<Icon icon='add_layer' fill='editor.icon' />}
          onClick={handleAddElement}
          variant='text'
        />
      </HStack>

      <Divider />

      <Box overflowY='auto' w='full' h='full'>
        <ComponentList />
      </Box>
    </LayerSidebar>
  );
};
