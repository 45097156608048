import {
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import {
  MAXZOOMLEVEL,
  MINZOOMLEVEL,
  useEditorStore,
} from '$/pages/EditorPage/stores/useEditorStore';

export const EditorZoomDropdown = () => {
  const { t } = useTranslation();

  const zoomLevel = useEditorStore.useZoomPercent();

  const { zoomPercentage } = useEditorActions();

  const handleZoomIn = () => {
    const newZoomLevel = zoomLevel + 25;
    const zoomLevelInBounds = Math.min(MAXZOOMLEVEL, newZoomLevel);
    zoomPercentage(zoomLevelInBounds);
  };

  const handleZoomOut = () => {
    const newZoomLevel = zoomLevel - 25;
    const zoomLevelInBounds = Math.max(MINZOOMLEVEL, newZoomLevel);
    zoomPercentage(zoomLevelInBounds);
  };

  const handleResetZoom = () => {
    zoomPercentage(100);
  };

  return (
    <Menu closeOnSelect={false} placement='bottom-end'>
      <MenuButton
        as={GhostButton}
        w='85px'
        h='30px'
        px='10px'
        fontSize='sm'
        borderRadius='2px'
        rightIcon={<Icon icon='chevron_down' width='12px' />}
      >
        {zoomLevel} %
      </MenuButton>

      <MenuList minW='0'>
        <MenuItem as='span' onClick={handleResetZoom}>
          <GhostButton w='full' fontSize='sm'>
            100 %
          </GhostButton>
        </MenuItem>

        <MenuItem as='span' justifyContent='center'>
          <HStack>
            <IconButton
              aria-label={t('editor.zoom_out')}
              icon={<Icon icon='zoom_out' />}
              onClick={handleZoomOut}
              variant='text'
            />
            <IconButton
              aria-label={t('editor.zoom_in')}
              icon={<Icon icon='zoom_in' />}
              onClick={handleZoomIn}
              variant='text'
            />
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
