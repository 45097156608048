import { object, string, number, array, z } from 'zod';

import { categoryNames } from '$/services/usecases/scenes/mapper/allCategories';
import { unsecureSeededRandom } from '$/utils/numberUtils';

// TODO: Remove if backend provides this data
const flatCategories = (category: string) => {
  if (category === 'sleeping') return 'bedroom';
  return category;
};

export const AllSceneSchema = object({
  scenes: array(
    object({
      id: string(),
      name: string(),
      trans_name: string(),
      category: string(),
      category_master: string(),
      view_cnt: number(),
      views: array(string()),
    }),
  ),
}).transform((data) =>
  data.scenes.map((scene) => ({
    name: scene.trans_name === '' ? scene.name : scene.trans_name,
    id: scene.id,
    category: categoryNames[flatCategories(scene.category)],
    categoryId: flatCategories(scene.category),
    categoryMaster: scene.category_master,
    viewCount: scene.view_cnt,
    views: scene.views,
    clicks: Math.floor(unsecureSeededRandom(parseInt(scene.id)) * 100000),
  })),
);

export type Scene = z.infer<typeof AllSceneSchema>[number];
