import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ThemeSwitcher } from '$/components/common/ThemeSwitcher';
import { ShareModal } from '$/components/core/Editor/AttributeSidebar/components/ShareModal';

export const ShareSection = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <HStack
        justifyContent='space-between'
        w='full'
        h='32px'
        minH='32px'
        mt='1'
        mb='1'
        px='5'
      >
        <Button
          h='8'
          fontSize='sm'
          fontWeight='normal'
          borderRadius='base'
          bgColor='editor.btnShareBg'
          onClick={onOpen}
          variant='primary'
        >
          {t('editor.shareButton')}
        </Button>

        <ThemeSwitcher />
      </HStack>
      <ShareModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};
