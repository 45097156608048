import { Box, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialDetails } from '$/pages/InspirationEditorPage/components/AttributeSection/components/AppliedMaterial/components/MaterialDetails';
import { Material } from '$/services/usecases/materials';

interface Props {
  material: Material;
}

export const AppliedColor: FC<Props> = ({ material }) => {
  return (
    <Stack w='full'>
      <Box pos='relative' mb='3'>
        <MaterialCard material={material} editMode='none' />
      </Box>

      <MaterialDetails material={material} />
    </Stack>
  );
};
