import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { FavoriteButton } from '$/components/core/Collection/FavoriteButton';
import { LoginForFavoriteToastContent } from '$/components/core/Collection/MaterialCard/LoginForFavoriteToastContent';
import { MoveFavoriteMenu } from '$/components/core/Collection/MaterialCard/MoveFavoriteMenu';
import { SavedFavoriteToastContent } from '$/components/core/Collection/MaterialCard/SavedFavoriteToastContent';
import { useToast } from '$/hooks/useToast';
import { useFavoriteModal } from '$/pages/DashboardPages/pages/Favorite/hooks/useFavoriteModal';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';
import { useMaterialContextMenuStore } from '$/stores/useMaterialContextMenuStore';

export type MaterialCardEditMode = 'none' | 'favorite' | 'favoriteExtended';

interface Props {
  editMode: MaterialCardEditMode;
  material: Material;
  materialType?: MaterialType;
}

const getIcon = (
  editMode: MaterialCardEditMode,
  isFavorite: boolean = false,
) => {
  if (editMode === 'favorite') {
    return (
      <Icon
        icon={isFavorite ? 'check_icon' : 'add_big'}
        boxSize={isFavorite ? '15px' : '20px'}
        color='text'
      />
    );
  }

  if (editMode === 'favoriteExtended') {
    return <Icon icon='three_dots' color='text' boxSize='15px' />;
  }
};

export const MaterialCardContextMenu: FC<Props> = ({
  editMode,
  material,
  materialType,
}) => {
  const { t } = useTranslation();
  const activeMaterialContextMenuId =
    useMaterialContextMenuStore.useActiveMaterialId();
  const favorites = useFavoriteStore.useFavorites();
  const contextMaterialType = useMaterialContextMenuStore.useMaterialType();
  const setActiveMaterialContextMenuId =
    useMaterialContextMenuStore.useSetActiveMaterial();
  const addFavorite = useFavoriteStore.useAddFavorite();
  const openFavoriteModal = useFavoriteModal.useOpenFavoriteModal();
  const { hasPermission } = useAuthorization();
  const toast = useToast();
  const isFavorite = useFavoriteStore((state) =>
    state.isFavorite(material.uniqueKey),
  );
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const removeFavoriteFromFolder =
    useFavoriteStore.useRemoveFavoriteFromFolder();

  const onToggleFavorite = () => {
    if (!hasPermission('Favorites_Select')) {
      toast(
        t('error.accountNecessary'),
        'error',
        t('favorites.loginForFavorites'),
        {
          customContent: <LoginForFavoriteToastContent />,
        },
      );
      return;
    }
    if (isFavorite) {
      openFavoriteModal(
        'deleteFavorite',
        material.uniqueKey,
        material.info,
        materialType,
      );
    } else {
      addFavorite(material.uniqueKey, materialType, material.info);
      toast(t('favorites.favoriteSavedToastHeader'), 'success', '', {
        customContent: (
          <SavedFavoriteToastContent
            materialLabel={material.info}
            materialId={material.uniqueKey}
            materialType={materialType}
          />
        ),
      });
    }
  };

  if (editMode === 'none') return null;

  if (
    material.uniqueKey === activeMaterialContextMenuId &&
    materialType === contextMaterialType
  ) {
    return (
      <MoveFavoriteMenu
        favoriteId={material.uniqueKey}
        icon={getIcon(editMode, isFavorite)}
        materialType={materialType}
      />
    );
  }

  if (editMode === 'favorite') {
    return (
      <FavoriteButton
        aria-label={t('favorites.addFavorit')}
        icon={getIcon(editMode, isFavorite)}
        toggleFavorite={onToggleFavorite}
      />
    );
  }

  if (editMode === 'favoriteExtended') {
    const favoriteItem = favorites.find(
      (favorite) => favorite.materialId === material.uniqueKey,
    );

    return (
      <Menu placement='bottom-end'>
        <MenuButton
          as={IconButton}
          layerStyle='floatCardButton'
          icon={getIcon(editMode, isFavorite)}
          onClick={(event) => {
            event.stopPropagation();
          }}
          variant='text'
        />
        <MenuList fontSize='sm'>
          <MenuItem
            icon={<Icon icon='trash_can' />}
            onClick={(event) => {
              event.stopPropagation();
              if (favoriteItem?.parentFolderIds.length === 1) {
                openFavoriteModal(
                  'deleteFavorite',
                  material.uniqueKey,
                  material.info,
                  materialType,
                );
              } else {
                removeFavoriteFromFolder(
                  material.uniqueKey,
                  activeFolderId ?? DEFAULTFOLDERNAME,
                );
              }
            }}
          >
            {t('favorites.removeFromFolder')}
          </MenuItem>
          <MenuItem
            icon={<Icon icon='folder' />}
            onClick={(event) => {
              setActiveMaterialContextMenuId(material.uniqueKey, materialType);
              event.stopPropagation();
            }}
          >
            {t('favorites.changeFolder')}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }
};
