import { Box } from '@chakra-ui/react';
import { FC } from 'react';

import {
  CardContent,
  CardContentGridProps,
} from '$/components/common/Card/CardContent';
import {
  MaterialCardContextMenu,
  MaterialCardEditMode,
} from '$/components/core/Collection/MaterialCard/MaterialCardContextMenu';
import { MaterialType } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';

interface Props extends CardContentGridProps {
  material: Material;
  editMode?: MaterialCardEditMode;
  materialType?: MaterialType;
  labelOverwrite?: string;
}

export const MaterialCard: FC<Props> = ({
  material,
  labelOverwrite,
  editMode = 'favorite',
  materialType,
  ...props
}) => {
  const cursor = props.onClick != null ? 'pointer' : 'default';

  const bg = !material.url() ? material.color ?? 'transparent' : 'transparent';
  const label = labelOverwrite ?? material.info;

  return (
    <Box pos='relative' cursor={cursor}>
      <MaterialCardContextMenu
        editMode={editMode}
        material={material}
        materialType={materialType}
      />
      <CardContent
        bg={bg}
        label={label}
        image={material.url()}
        brightness={material.brightness}
        {...props}
      />
    </Box>
  );
};
