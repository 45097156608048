import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { t } from 'i18next';
import { FC, useEffect, useState } from 'react';

import { MobileDrawer } from '$/components/common/Drawer/MobileDrawer';
import { Icon } from '$/components/common/Icon';
import { MobileFilter } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilter';
import { MobileFilterDrawerContent } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilterDrawerContent';
import { useIsMobile } from '$/hooks/useIsMobile';
import { FilterType } from '$/services/mapper/uses';

interface Props {
  type: FilterType;
  amount: number | null;
}

export const MobileFilterDrawer: FC<Props> = ({ type, amount }) => {
  const isMobile = useIsMobile();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [subFilter, setSubFilter] = useState<string | null>(null);

  useEffect(() => {
    if (!isOpen && subFilter) {
      setSubFilter(null);
    }
  }, [isOpen, subFilter]);

  useEffect(() => {
    if (!isMobile && isOpen) {
      onClose();
    }
  }, [isMobile, isOpen, onClose]);

  return (
    <>
      <HStack>
        <MobileFilter
          width='auto'
          flexGrow='5'
          filterSearchName='collection'
          filterIcon='chevron_down'
          onSingleFilterSelected={(subFilter) => {
            setSubFilter(subFilter);
            onOpen();
          }}
          placeholder={t('inspirationEditor.filter.collectionDefault')}
        />
        <Button
          px='4'
          py='2'
          color='textButton.color'
          fontSize='sm'
          bg='background'
          borderWidth='1px'
          borderColor='border'
          borderRadius='8px'
          _hover={{ bg: 'background' }}
          _active={{ bg: 'background' }}
          onClick={onOpen}
          rightIcon={<Icon icon='filter_alt' boxSize='14px' />}
        >
          {t('dashboard.collection.filter.mobile.filterButton')}
        </Button>
      </HStack>

      <MobileDrawer isOpen={isOpen} onClose={onClose}>
        <MobileFilterDrawerContent
          type={type}
          onClose={onClose}
          amount={amount}
          setSubFilter={setSubFilter}
          subFilter={subFilter}
        />
      </MobileDrawer>
    </>
  );
};
