import { Box, Button, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useToast } from '$/hooks/useToast';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { EditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

const rasteringToast = (
  toolName: string,
  selectAction: EditorActions['selectAction'],
  toast: ReturnType<typeof useToast>,
  t: ReturnType<typeof useTranslation>['t'],
) => {
  toast(t('editor.warning'), 'warning', '', {
    customContent: (
      <Box>
        <Text fontSize='xs' lineHeight='1.2'>
          {t('editor.vectorWarning', { tool: toolName })}
        </Text>
        <Button
          mt='2'
          px='0'
          fontSize='sm'
          onClick={() => selectAction(ModuleSendSelectAction.SelectPoly)}
          variant='text'
        >
          {t('editor.cancel')}
        </Button>
      </Box>
    ),
  });
};

// const newElementToast = (
//   toast: ReturnType<typeof useToast>,
//   t: ReturnType<typeof useTranslation>['t'],
// ) => {
//   toast(
//     t('editor.newElementCreatedToastTitle'),
//     'info',
//     t('editor.newElementCreatedToastContent'),
//   );
// };

export const editorActions: EditorActionGroup[] = [
  {
    id: 'group1',
    groupable: true,
    needComponent: false,
    actions: [
      {
        key: 'select',
        mode: 'select',
        icon: <Icon icon='select' w='18px' h='18px' />,
        select: true,
        cursor: 'crosshair',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.SelectPoly);
        },
      },
    ],
  },
  {
    id: 'group2',
    groupable: true,
    needComponent: false,
    actions: [
      {
        key: 'createeditpoly',
        mode: 'createeditpoly',
        icon: <Icon icon='polyline' w='18px' h='18px' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.CreateEditPoly);
        },
      },
    ],
  },
  {
    id: 'group3',
    groupable: true,
    needComponent: true,
    actions: [
      {
        key: 'shape_circle',
        mode: 'addEllipse',
        icon: <Icon icon='shape_circle' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.AddEllipse);
        },
      },
      {
        key: 'shape_rectangle',
        mode: 'addRect',
        icon: <Icon icon='shape_rectangle' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.AddRect);
        },
      },
      {
        key: 'shape_grid',
        mode: 'grid',
        icon: <Icon icon='shape_grid' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.EditGrid);
        },
      },
      {
        key: 'add_photo',
        icon: <Icon icon='add_photo' />,
        select: true,
        cursor: 'default',
      },
    ],
  },
  {
    id: 'group4',
    groupable: true,
    needComponent: true,
    actions: [
      {
        key: 'brush',
        mode: 'brush',
        icon: <Icon icon='brush' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }, { toast, t }) => {
          selectAction(ModuleSendSelectAction.UseBrush);
          rasteringToast(t('editor.brushTool'), selectAction, toast, t);
        },
      },
      {
        key: 'eraser',
        mode: 'rubber',
        icon: <Icon icon='eraser' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }, { toast, t }) => {
          selectAction(ModuleSendSelectAction.UseRubber);
          rasteringToast(t('editor.rubber.tool'), selectAction, toast, t);
        },
      },
      {
        key: 'stencil',
        icon: <Icon icon='stencil' />,
        select: true,
        cursor: 'default',
      },
      {
        key: 'edit',
        mode: 'magicWand',
        icon: <Icon icon='magic_wand' />,
        select: true,
        cursor: 'default',
        onClick: ({ selectAction }, { toast, t }) => {
          selectAction(ModuleSendSelectAction.MagicWand);
          rasteringToast(t('editor.magicWand.tool'), selectAction, toast, t);
        },
      },
    ],
  },
  {
    id: 'group5',
    groupable: false,
    needComponent: false,
    actions: [
      {
        key: 'undo',
        icon: <Icon icon='undo' />,
        select: false,
        cursor: 'default',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.Undo);
        },
      },
      {
        key: 'redo',
        icon: <Icon icon='redo' />,
        select: false,
        cursor: 'default',
        onClick: ({ selectAction }) => {
          selectAction(ModuleSendSelectAction.Redo);
        },
      },
    ],
  },
];
