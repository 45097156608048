import {
  Box,
  ButtonProps,
  CheckboxGroup,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';
import { MoveFavoriteCheckbox } from '$/components/core/Collection/MaterialCard/MoveFavoriteCheckbox';
import { useFavoriteModal } from '$/pages/DashboardPages/pages/Favorite/hooks/useFavoriteModal';
import { MaterialType } from '$/services/mapper/uses';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';
import { useMaterialContextMenuStore } from '$/stores/useMaterialContextMenuStore';

interface Props extends ButtonProps {
  favoriteId: string;
  icon?: ReactElement;
  materialType?: MaterialType;
}

export const MoveFavoriteMenu: FC<Props> = ({
  favoriteId,
  icon,
  materialType,
}) => {
  const { t } = useTranslation();
  const setActiveMaterialContextMenuId =
    useMaterialContextMenuStore.useSetActiveMaterial();
  const favorites = useFavoriteStore.useFavorites();
  const removeFavorite = useFavoriteStore.useRemoveFavorite();
  const folders = useFavoriteStore.useFavoriteFolders();
  const changeFolderOfFavorite = useFavoriteStore.useChangeFoldersOfFavorite();
  const selectedFavorite = favorites.find(
    (favorite) => favorite.materialId === favoriteId,
  );
  const openFavoriteModal = useFavoriteModal.useOpenFavoriteModal();
  const [checkedItems, setCheckedItems] = useState(
    () => selectedFavorite?.parentFolderIds ?? [],
  );

  if (selectedFavorite == null) return null;

  const onFinishSelection = () => {
    if (checkedItems.length === 0) {
      removeFavorite(favoriteId);
    } else {
      changeFolderOfFavorite(favoriteId, checkedItems);
    }

    setActiveMaterialContextMenuId(null);
  };

  return (
    <Menu
      closeOnSelect={false}
      isOpen={true}
      onClose={() => {
        onFinishSelection();
      }}
      placement='bottom-end'
    >
      <MenuButton
        as={IconButton}
        layerStyle='floatCardButton'
        icon={icon}
        variant='text'
      />
      <MenuList w='20rem'>
        <HStack gap='2' mx='4' my='2' fontSize='sm' fontWeight='bold'>
          <Icon icon='folder' />
          <Text>{t('favorites.changeFolder')}</Text>
        </HStack>
        <Box overflowY='auto' maxH='20vh'>
          <CheckboxGroup
            onChange={(newValues) => setCheckedItems(newValues as string[])}
            value={checkedItems}
          >
            <MoveFavoriteCheckbox
              label={t('favorites.generalStorage')}
              value={DEFAULTFOLDERNAME}
            />
            {folders.map((folder) => (
              <MoveFavoriteCheckbox
                label={folder.name}
                value={folder.id}
                key={folder.id}
              />
            ))}
          </CheckboxGroup>
        </Box>
        <MenuItem as='div' mt='2' px='4' py='0'>
          <TrackedButton
            p='0'
            fontSize='sm'
            leftIcon={<Icon icon='add_big' />}
            onClick={() => {
              openFavoriteModal('createFolder', favoriteId, '', materialType);
              setActiveMaterialContextMenuId(null);
            }}
            variant='text'
            contentName='Favorite'
            contentPiece='EditFolder'
            contentTarget='NewFolder'
          >
            {t('favorites.newFolder')}
          </TrackedButton>
        </MenuItem>
        <HStack justifyContent='space-between' mx='4' my='3'>
          <TrackedButton
            fontSize='sm'
            onClick={() => setActiveMaterialContextMenuId(null)}
            size='sm'
            variant='text'
            contentName='Favorite'
            contentPiece='EditFolder'
            contentTarget='Cancel'
          >
            {t('favorites.cancelButton')}
          </TrackedButton>
          <TrackedButton
            fontSize='sm'
            onClick={onFinishSelection}
            size='sm'
            variant='primary'
            contentName='Favorite'
            contentPiece='EditFolder'
            contentTarget='Submit'
          >
            {t('favorites.finish')}
          </TrackedButton>
        </HStack>
      </MenuList>
    </Menu>
  );
};
