import { Flex, MenuItem, MenuItemProps, Spinner, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends MenuItemProps {
  isActive: boolean;
  name: string;
  amount?: number;
  isLoading?: boolean;
}

export const SingleMenuItem: FC<Props> = ({
  isActive,
  name,
  amount,
  isLoading = false,
  ...props
}) => {
  const getBackground = () => {
    if (isActive) return 'secondaryBackground';
    return isLoading ? 'bodyBackground' : 'transparent';
  };

  return (
    <MenuItem justifyContent='space-between' bg={getBackground()} {...props}>
      <Flex align='center' justify='space-between' w='full'>
        <Text fontSize='sm'>{name}</Text>
        {!isLoading && amount && (
          <Text color='lighterText' fontSize='sm'>
            {amount}
          </Text>
        )}
        {isLoading && <Spinner emptyColor='neutral.1000/30' size='sm' />}
      </Flex>
    </MenuItem>
  );
};
