import { object, string } from 'zod';

import {
  GeneralAuthResponse,
  parseErrorResult,
} from '$/services/mapper/authentication';

export const UpdateDataSchema = object({
  ...GeneralAuthResponse,
  mode: string().optional(),
  user: string().optional(),
}).transform((data) => {
  return parseErrorResult(data, () => ({
    data: {
      mode: data.mode!,
      email: data.user!,
      name: data.user!.split('@')[0] ?? 'Rick',
    },
  }));
});
