import { HStack, Heading } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { NewProjectButton } from '$/components/core/Projects/components/NewProjectButton';
import { projectsQuery } from '$/services/usecases/projects/queries';

export const ProjectHeader = () => {
  const { t } = useTranslation();

  const { getProjectCountLimit } = useAuthorization();

  const projectCount = useQuery(projectsQuery).data?.projectCount ?? 0;

  return (
    <HStack justify='space-between'>
      <Heading as='h1' fontSize='3xl'>
        {t('dashboard.projects.header')} ({projectCount}/
        {getProjectCountLimit()})
      </Heading>
      <NewProjectButton />
    </HStack>
  );
};
