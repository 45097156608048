import { Button } from '@chakra-ui/react';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';

interface Props<T extends FieldValues> extends HookFormInputProps<T> {}

export const HookFormPasswordInput = <T extends FieldValues>({
  accessor,
  label,
  registerOptions,
  description,
  ...rest
}: Props<T>) => {
  const [showPassword, setShowPassword] = useState(false);

  const { t } = useTranslation();

  return (
    <HookFormInput<T>
      type={showPassword ? 'text' : 'password'}
      accessor={accessor}
      label={label}
      placeholder={t('dashboard.authentication.changePassword.inputPassword')}
      registerOptions={registerOptions}
      description={description}
      inputRightElement={
        <Button
          onClick={() => setShowPassword((state) => !state)}
          variant='text'
        >
          <Icon icon={showPassword ? 'visibility_on' : 'visibility_off'} />
        </Button>
      }
      {...rest}
    />
  );
};
