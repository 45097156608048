import { LoadModal } from '$/pages/EditorPage/components/LoadModal/LoadModal';
import { EditorPage } from '$/pages/EditorPage/pages/EditorPage';

export const LoadEditorPage = () => {
  return (
    <>
      <LoadModal />
      <EditorPage />
    </>
  );
};
