import { Box, Grid, Stack, StackProps } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FC, ForwardedRef } from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { MaterialDetailsModal } from '$/components/core/Collection/MaterialDetailsModal';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { useIntersectionObserver } from '$/hooks/useIntersectionObserver';
import { DataSortingRow } from '$/pages/DashboardPages/pages/Collection/components/CollectionGrid/DataSortingRow';
import { MaterialType } from '$/services/mapper/uses';
import { infiniteMaterialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';
import { repeat } from '$/utils/arrayUtils';

interface Props extends StackProps {
  type: MaterialType;
  scrollRefs?: ForwardedRef<HTMLDivElement>[];
}

export const CollectionGrid: FC<Props> = ({ type, scrollRefs, ...props }) => {
  const setActiveMaterial = useMaterialDetailStore.useSetActive();
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      infiniteMaterialsQuery({
        limit: 50,
        filter,
        brightnessFilter,
        type: [type],
      }),
    );

  const materials = data?.pages.flatMap((page) => page.materials);
  const materialCount = data?.pages[0].materialCount;

  const observer = useIntersectionObserver(
    (intersecting) => intersecting && fetchNextPage(),
    { disabled: !data || isFetchingNextPage || materials?.length === 0 },
  );

  return (
    <>
      <DataSortingRow
        amount={materialCount ?? 0}
        isLoading={isLoading}
        scrollRefs={scrollRefs}
      />

      <Stack gap='3' {...props}>
        <Grid
          gap={{ base: '3', md: '6' }}
          templateColumns={materialTemplateColumn()}
        >
          <For each={materials}>
            {(material, i) => (
              <Box key={material.id}>
                <MaterialCard
                  material={material}
                  onClick={() => setActiveMaterial(material)}
                  materialType={type}
                />
                {i === materials!.length - 8 && <Box ref={observer} />}
              </Box>
            )}
          </For>
          <Show when={isLoading || isFetchingNextPage}>
            {repeat(50).map((i) => (
              <LoadingMaterialCard key={i} />
            ))}
          </Show>
        </Grid>
      </Stack>

      <MaterialDetailsModal />
    </>
  );
};
