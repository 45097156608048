import { ListItem, Text, UnorderedList, VStack } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  description: string;
  errors: string[];
}

export const HookFormErrorList: FC<Props> = ({ errors, description }) => {
  return (
    <VStack align='flex-start'>
      <Text color='danger.1000' fontSize='sm'>
        {description}
      </Text>
      <UnorderedList pl='3'>
        {errors.map((error, index) => (
          <ListItem key={index} color='danger.1000'>
            <Text fontSize='sm'>{error}</Text>
          </ListItem>
        ))}
      </UnorderedList>
    </VStack>
  );
};
