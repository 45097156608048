import { HStack, Skeleton, StackProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { randomPlaceholder } from '$/utils/stringUtils';

interface Props extends StackProps {
  layerId: number;
}

export const LoadingColorLayer: FC<Props> = ({ layerId, ...props }) => {
  return (
    <HStack cursor='pointer' {...props}>
      <Icon icon='other_objects' />
      <Skeleton layerStyle='ColorImage' borderRadius='4px' />
      <Skeleton fontSize='12px' lineHeight='24px'>
        {randomPlaceholder(layerId, 3, 10)}
      </Skeleton>
    </HStack>
  );
};
