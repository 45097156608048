import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type State = {
  activeMaterialId: string | null;
};

type Actions = {
  setActiveMaterial: (id: string | null) => void;
};

export const initial: State = {
  activeMaterialId: null,
};

export const store = create<State & Actions>()(
  persist(
    (set) => ({
      ...initial,
      setActiveMaterial: (id) => set({ activeMaterialId: id }),
    }),
    {
      name: 'material-store',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export const useMaterialStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
