import { Stack, StackProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const AttributeSidebar: FC<StackProps & Props> = ({
  children,
  ...rest
}) => {
  return (
    <Stack
      layerStyle='EditorFloatingSidebar'
      pos='fixed'
      right='0'
      bottom='0'
      gap='4'
      overflow='hidden'
      w='16rem'
      m='4'
      {...rest}
    >
      {children}
    </Stack>
  );
};
