import { Tooltip, HStack, Text, Flex, StackProps } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';

interface Props extends StackProps {
  info: string;
  label: string;
}

export const TooltipLabel: FC<Props> = ({ info, label, ...props }) => {
  return (
    <HStack gap='3' {...props}>
      <Tooltip
        p='3'
        color='text'
        bg='background'
        border='1px'
        borderColor='neutral.50'
        shadow='base'
        label={info}
      >
        <Flex align='center' gap='2' cursor='help'>
          <Icon icon='info' color='neutralText' width='14px' />
          <Text color='neutralText' fontSize='sm'>
            {label}
          </Text>
        </Flex>
      </Tooltip>
    </HStack>
  );
};
