import {
  NullableUrlSearchParams,
  buildUrl,
  cloudEndpoints,
  cnf,
} from '$/services/endpoints';
import { ProfileData } from '$/services/usecases/profile';

export const cloudEndpointFactory = {
  registerUser: (username: string, password: string, language: string) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      user: btoa(username),
      pw: btoa(password),
      lang: language,
    };
    return buildUrl(cloudEndpoints.REGISTER_URL, params);
  },
  signInUser: ({
    email,
    password,
    language,
  }: {
    email: string;
    password: string;
    language: string;
  }) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      user: btoa(email),
      pw: btoa(password),
      lang: language,
    };
    return buildUrl(cloudEndpoints.SIGN_IN_URL, params);
  },
  allProjects: () => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
    };
    return buildUrl(cloudEndpoints.ALL_PROJECTS_URL, params);
  },
  forgotPassword: (email: string, language: string) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      user: btoa(email),
      lang: language,
    };
    return buildUrl(cloudEndpoints.FORGOT_PASSWORD_URL, params);
  },
  changePassword: (oldPassword: string, newPassword: string) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      password: btoa(oldPassword),
      newPassword: btoa(newPassword),
    };
    return buildUrl(cloudEndpoints.CHANGE_PASSWORD_URL, params);
  },
  updateUserData: (data: ProfileData) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      user: btoa(data.email),
      name: btoa(data.name),
    };
    return buildUrl(cloudEndpoints.UPDATE_USER_DATA, params);
  },
  uploadProfilePicture: () => {
    const params: NullableUrlSearchParams = { cnf: cnf() };
    return buildUrl(cloudEndpoints.UPLOAD_PROFILE_PICTURE, params);
  },
  deleteAccount: () => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
    };
    return buildUrl(cloudEndpoints.DELETE_ACCOUNT, params);
  },
};
