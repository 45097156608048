import { HStack } from '@chakra-ui/react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { RespondingIconButton } from '$/components/core/Editor/ActionBar/components/RespondingIconButton';
import { HookFormInput } from '$/components/core/Form/HookFormInput';
import { emailFormatRegex } from '$/utils/regexUtils';
interface IFormInput {
  mailAddress: string;
}
export const SendPerMailSection = () => {
  const { t } = useTranslation();
  const formMethods = useForm<IFormInput>({ mode: 'all' });
  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    console.log(data);
    await new Promise((resolve) => setTimeout(resolve, 2000));
  };

  return (
    <HStack as='form' alignItems='flex-end' w='full' m='0' fontSize='sm'>
      <FormProvider {...formMethods}>
        <HookFormInput<IFormInput>
          type='email'
          accessor='mailAddress'
          label={t('editor.invite_per_mail')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.emailLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            pattern: {
              value: emailFormatRegex,
              message: t('dashboard.authentication.errorMessages.wrongFormat'),
            },
          }}
        />
      </FormProvider>
      <RespondingIconButton
        onClick={formMethods.handleSubmit(onSubmit)}
        aria-label='send'
        icon='project_send'
        iconColor='reverseText'
        isDisabled={formMethods.formState.errors.mailAddress?.message != null}
      />
    </HStack>
  );
};
