import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { getUserData } from '$/services/usecases/authentication';
import { User, UserRole } from '$/services/usecases/authentication/mapper/jwt';

export type AuthModalType =
  | 'login'
  | 'register'
  | 'passwordForgotten'
  | 'registerSuccess';

type State = {
  user: User;
  modalType: AuthModalType;
  isModalOpen: boolean;
};

type Action = {
  authenticate: (token: string) => void;
  setMockedUserRole: (userRole: UserRole) => void;
  setUser: (user: User) => void;
  setModalType: (type: AuthModalType) => void;
  onModalOpen: (type?: AuthModalType) => void;
  onModalClose: () => void;
};

const initial: State = {
  user: { role: 'free', authExpirationDate: null, data: null },
  modalType: 'login',
  isModalOpen: false,
};

export const store = create<State & Action>()(
  persist(
    (set, get) => ({
      ...initial,
      authenticate: (token) => {
        set({ user: getUserData(token) });
      },
      setUser: (user) => set({ user }),
      setMockedUserRole: (userRole) => {
        set({ user: { ...get().user, role: userRole } });
      },
      setModalType: (modalType) => set({ modalType }),
      onModalClose: () => set({ isModalOpen: false }),
      onModalOpen: (modalType) => {
        set({ isModalOpen: true });
        if (modalType != null) set({ modalType });
      },
    }),
    {
      name: 'user-store',
      partialize: (state) => ({ user: state.user }),
    },
  ),
);

export const useAuthenticationStore = createSelectorHooks(store);
