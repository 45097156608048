import { Button, useBreakpointValue } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

export const BackButton = () => {
  const { t } = useTranslation();

  const backText = useBreakpointValue(
    {
      base: t('general.back'),
      md: t('general.backToOverview'),
    },
    { ssr: false },
  );

  return (
    <Link to='/collections'>
      <Button
        w='fit-content'
        ml='-15px'
        leftIcon={
          <Icon icon='arrow_right' w='15px' transform='rotate(180deg)' />
        }
        variant='text'
      >
        {backText ?? t('general.backToOverview')}
      </Button>
    </Link>
  );
};
