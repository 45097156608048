import { Text, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { MaterialType } from '$/services/mapper/uses';
import { useMaterialContextMenuStore } from '$/stores/useMaterialContextMenuStore';

interface Props {
  materialLabel: string;
  materialId: string;
  materialType?: MaterialType;
}

export const SavedFavoriteToastContent: FC<Props> = ({
  materialLabel,
  materialId,
  materialType,
}) => {
  const { t } = useTranslation();
  const setActiveMaterialContextMenuId =
    useMaterialContextMenuStore.useSetActiveMaterial();

  return (
    <>
      <Text fontSize='sm' wordBreak='break-word'>
        <Trans
          i18nKey='favorites.favoriteSavedToastDescription'
          values={{ materialName: materialLabel }}
        />
      </Text>
      <Button
        p='0'
        color='primaryButton.background'
        fontSize='sm'
        fontWeight='bold'
        onClick={() => setActiveMaterialContextMenuId(materialId, materialType)}
        variant='text'
      >
        {t('favorites.changeFolder')}
      </Button>
    </>
  );
};
