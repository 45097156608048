import {
  HStack,
  IconProps,
  ResponsiveValue,
  Text,
  VStack,
  useToast as useChakraToast,
} from '@chakra-ui/react';
import { Property } from 'csstype';
import { ReactElement, useCallback } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { AvailableIcons, Icon } from '$/components/common/Icon';
export type ToastOptions = 'success' | 'error' | 'warning' | 'info';
type iconCombination = {
  iconColor: ResponsiveValue<Property.Color>;
  iconType: AvailableIcons;
};
const toastOptions: Record<ToastOptions, iconCombination> = {
  error: { iconColor: 'danger.1000', iconType: 'info' },
  success: { iconColor: 'success.1000', iconType: 'leaderboard' },
  warning: { iconColor: 'warning.1000', iconType: 'info' },
  info: { iconColor: 'text', iconType: 'info' },
};
export const useToast = () => {
  const toast = useChakraToast();

  return useCallback(
    (
      headText: string,
      toastType: ToastOptions,
      text: string,
      options?: {
        customColor?: ResponsiveValue<Property.Color>;
        customIcon?: AvailableIcons;
        customIconProps?: IconProps;
        customContent?: ReactElement;
        customHeader?: ReactElement;
      },
    ) => {
      const { iconColor, iconType } = toastOptions[toastType];
      toast({
        position: 'bottom-left',
        duration: 5000,
        isClosable: true,
        render: ({ onClose }) => (
          <VStack
            alignItems='flex-start'
            w='21rem'
            px='4'
            py='3'
            color='text'
            bg='background'
            border='1px solid'
            borderColor='neutral.300'
            borderRadius='7px'
            onClick={onClose}
          >
            <HStack
              alignItems='center'
              align='left'
              gap='4px'
              w='full'
              fontSize='sm'
              fontStyle='bold'
            >
              <Icon
                {...options?.customIconProps}
                icon={options?.customIcon ?? iconType}
                color={options?.customColor ?? iconColor}
                mr='2'
              />
              <Show
                when={options?.customHeader != null}
                fallback={() => <Text fontWeight='bold'>{headText}</Text>}
              >
                {options?.customHeader}
              </Show>
            </HStack>
            <Show
              when={options?.customContent != null}
              fallback={() =>
                text.length > 0 && (
                  <Text
                    fontSize='xs'
                    lineHeight='standard'
                    wordBreak='break-all'
                  >
                    {text}
                  </Text>
                )
              }
            >
              {options?.customContent}
            </Show>
          </VStack>
        ),
      });
    },
    [toast],
  );
};
