import { jwtDecode } from 'jwt-decode';

import { RegisterData } from '$/components/core/Authentication/components/RegisterForm';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { cloudEndpointFactory } from '$/services/endpoints/cloudEndpoints';
import { fetcher, parser } from '$/services/fetcher';
import { GenericCloudResponseSchema } from '$/services/usecases/authentication/mapper/genericCloudResponse';
import { UserJwtSchema } from '$/services/usecases/authentication/mapper/jwt';
import { SignInSchema } from '$/services/usecases/authentication/mapper/signin';

export type LoginData = {
  email: string;
  password: string;
};

export const registerUser = async (data: RegisterData, language: string) => {
  return await fetcher(
    cloudEndpointFactory.registerUser(data.email, data.password, language),
    {
      msg: 'Registration failed',
      parser: (data) => GenericCloudResponseSchema.parse(data),
    },
  );
};

export const signInUser = async (
  { email, password }: LoginData,
  language: string,
) => {
  return await fetcher(
    cloudEndpointFactory.signInUser({ email, password, language }),
    {
      msg: 'Sign in failed',
      parser: (data) => SignInSchema.parse(data),
      includeCredentials: true,
    },
  );
};

export const getUserData = (token: string) => {
  return parser(jwtDecode(token), (payload) => UserJwtSchema.parse(payload));
};

export const forgotPassword = async (email: string, language: string) => {
  return await fetcher(cloudEndpointFactory.forgotPassword(email, language), {
    msg: 'Password Reset failed',
    parser: (data) => GenericCloudResponseSchema.parse(data),
  });
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  // TODO: CHECK IF CORRECT ENDPOINTS ARE AVAILABLE
  await new Promise((resolve) => setTimeout(resolve, 2000));
  return await fetcher(
    cloudEndpointFactory.changePassword(oldPassword, newPassword),
    {
      msg: 'Password Change failed',
      parser: (data) => GenericCloudResponseSchema.parse(data),
      includeCredentials: true,
    },
  );
};

export const resetPasswort = async (
  newPassword: string,
  oneTimeJwt: string,
) => {
  // TODO insert actual logic when endpoint exists
  await new Promise((resolve) => setTimeout(resolve, 2000));
  console.log(newPassword, oneTimeJwt);
};

// TODO: REPLACE WITH ACTUAL SIGN OUT ENDPOINT
export const signOut = () => {
  useAuthenticationStore.setState({
    user: { role: 'free', authExpirationDate: null, data: null },
  });
};
