import { createRoute, defer } from '@tanstack/react-router';

import { WelcomeDashboard } from '$/pages/DashboardPages/pages/Welcome';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { scenesQuery } from '$/services/usecases/scenes/queries';
import { pickRandomElement } from '$/utils/arrayUtils';

export const DashboardRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/',
  component: WelcomeDashboard,
  loader: ({ context: { client } }) => {
    const getMaterials = async () => {
      const { materials: colorMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 30, type: ['uni'] }),
      );
      const { materials: wallMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 10, type: ['wall'] }),
      );
      const { materials: floorMaterials } = await client.ensureQueryData(
        materialsQuery({ limit: 10, type: ['floor'] }),
      );

      return {
        colorMaterial: pickRandomElement(colorMaterials),
        wallMaterial: pickRandomElement(wallMaterials),
        floorMaterial: pickRandomElement(floorMaterials),
        structureMaterial: pickRandomElement(colorMaterials),
      };
    };

    return {
      scenes: defer(client.ensureQueryData(scenesQuery)),
      materials: defer(getMaterials()),
    };
  },
  staleTime: STANDARD_STALE_TIME,
});
