import { Box, Tooltip } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { Icon } from '$/components/common/Icon';

interface Props {
  info: string;
  icon?: ReactNode;
}

export const InfoTooltip: FC<Props> = ({ info, icon }) => {
  return (
    <Tooltip
      p='3'
      color='text'
      bg='background'
      border='1px'
      borderColor='neutral.50'
      shadow='base'
      label={info}
    >
      <Box>
        {icon ? icon : <Icon icon='info' color='border' cursor='help' />}
      </Box>
    </Tooltip>
  );
};
