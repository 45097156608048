import { Box } from '@chakra-ui/react';
import { FC } from 'react';

import { MobileMainFilterPage } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileMainFilterPage';
import { MobileSubFilterPage } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileSubFilterPage';
import { FilterType } from '$/services/mapper/uses';

interface Props {
  type: FilterType;
  onClose: () => void;
  amount: number | null;
  subFilter: string | null;
  setSubFilter: (subFiltername: string | null) => void;
}

export const MobileFilterDrawerContent: FC<Props> = ({
  type,
  onClose,
  amount,
  subFilter,
  setSubFilter,
}) => {
  return (
    <Box overflowY='auto' pb='8'>
      {!subFilter ? (
        <MobileMainFilterPage
          closeFilterDrawer={onClose}
          setSubFilter={setSubFilter}
          type={type}
          amount={amount}
        />
      ) : (
        <MobileSubFilterPage
          selectedSubFilter={subFilter}
          closeFilterDrawer={onClose}
          setSubFilter={setSubFilter}
        />
      )}
    </Box>
  );
};
