import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  icon: {
    color: 'lighterText',
    height: '55%',
    strokeLinecap: 'round',
  },
  control: {
    borderWidth: '1px',
    borderColor: 'lighterText',
    borderRadius: '2px',
    _checked: {
      bg: 'transparent',
      borderColor: 'text',
      _hover: {
        bg: 'transparent',
        borderColor: 'lighterText',
      },
    },
    _disabled: {
      bg: 'border',
      borderColor: 'border',
      _checked: {
        bg: 'border',
        borderColor: 'border',
        color: 'neutralText',
      },
    },
    _focus: {
      boxShadow: 'none',
    },
  },
});

export const Checkbox = defineMultiStyleConfig({ baseStyle });
