import { SiteSearch } from '@piwikpro/react-piwik-pro';
import { useEffect } from 'react';

import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { TypedToOptions } from '$/components/core/StyledLink';
import { getOriginCategory } from '$/stores/usePiwikStore';

interface SearchResult extends Record<string, unknown> {
  materialCount: number;
}

export const usePiwikSearchTracking = (
  searchResult: SearchResult | undefined,
) => {
  const query = useMaterialSearchStore.useQuery();

  useEffect(() => {
    if (searchResult != null && query.length > 3) {
      const category = getOriginCategory(location.pathname as TypedToOptions);
      if (category != null) {
        SiteSearch.trackSiteSearch(query, category, searchResult.materialCount);
      }
    }
    // disabled because we don't care if the query changes, as this happens before we get a result from the endpoint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResult]);
};
