import { Flex, Button, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends FlexProps {
  anyFilterActive: boolean;
  activeChanges: boolean;
  onReset: () => void;
  onSubmit: () => void;
  isLoading?: boolean;
}

export const FilterActions: FC<Props> = ({
  anyFilterActive,
  activeChanges,
  onReset,
  onSubmit,
  isLoading = false,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Flex justify='space-between' gap='4' {...props}>
      <Button
        h='32px'
        fontSize='sm'
        isDisabled={!activeChanges}
        isLoading={isLoading}
        onClick={onSubmit}
      >
        {t('dashboard.collection.show')}
      </Button>
      <Button
        h='32px'
        fontSize='sm'
        isDisabled={!anyFilterActive}
        isLoading={isLoading}
        onClick={onReset}
        variant='text'
      >
        {t('dashboard.collection.resetFilter')}
      </Button>
    </Flex>
  );
};
