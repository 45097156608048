import { createRoute } from '@tanstack/react-router';

import { CollectionCategoryPage } from '$/pages/DashboardPages/pages/Collection/pages/CollectionCategoryPage';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { FilterType } from '$/services/mapper/uses';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

const type: FilterType = 'floor';

export const FloorsCollectionRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/collections/floors',
  component: () => (
    <CollectionCategoryPage filterType={type} materialType={type} />
  ),
  loader: ({ context: { client } }) => {
    useCollectionStore.setState({ filter: undefined, activeType: type });

    const { filterGroups } = useCollectionStore.getState();
    void client
      .ensureQueryData(filterOptionsQuery({ type, filter: filterGroups }))
      .then((filter) => useCollectionStore.setState({ filter }));
  },
  staleTime: 0,
});
