import { ReactNode } from 'react';

interface Props<T> {
  children: ReactNode | ((value: T) => ReactNode);
  when: T | null | undefined;
  fallback?: () => ReactNode;
}

export function Show<T>({ when, fallback, children }: Props<T>) {
  if (when) return typeof children === 'function' ? children(when) : children;
  return fallback ? fallback() : null;
}
