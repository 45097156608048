import { Fragment, ReactNode } from 'react';

interface Props<T> {
  each?: T[] | null;
  children: (value: T, index: number, array: T[]) => ReactNode;
  fallback?: () => ReactNode;
  empty?: () => ReactNode;
  isLoading?: boolean;
}

export function For<T>({
  each,
  children,
  fallback,
  isLoading,
  empty,
}: Props<T>) {
  if (each && each.length > 0 && !isLoading) {
    return each
      ? each.map((val, index, all) => (
          <Fragment key={JSON.stringify(val)}>
            {children(val, index, all)}
          </Fragment>
        ))
      : null;
  }

  if (empty && !isLoading && each && each.length === 0) {
    return empty();
  }

  return fallback ? fallback() : null;
}
