import { Divider, SimpleGrid, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { ColorBox } from '$/pages/InspirationEditorPage/components/ColorBox';
import { Material } from '$/services/usecases/materials';
import { useFanMaterials } from '$/stores/useFanMaterials';

interface Props {
  material: Material | null | undefined;
  onColorChange: (material: Material) => void;
}

export const FanPageSelector: FC<Props> = ({ material, onColorChange }) => {
  const { t } = useTranslation();

  const activeMaterial = material;

  const fanMaterials = useFanMaterials(activeMaterial);

  const shownMaterials = fanMaterials ?? [];

  const fanLength = Math.max(6, shownMaterials.length);

  if (activeMaterial == null || fanMaterials.length === 0) return;

  return (
    <>
      <Stack w='full' px='5'>
        <FilterHeader
          header={t('inspirationEditor.filter.fanPage')}
          prefix={<Icon icon='inspiration_marker' w='14px' />}
          isActive={true}
        />
        <SimpleGrid w='full' columns={fanLength}>
          {shownMaterials?.map((material, index) => (
            <ColorBox
              key={material.id}
              onClick={() => onColorChange(material)}
              materialColor={material.color}
              image={material.url()}
              w='full'
              h='30px'
              aspectRatio='1/1'
              borderRightRadius={
                index % shownMaterials.length === shownMaterials.length - 1
                  ? '4px'
                  : '0'
              }
              borderLeftRadius={
                index % shownMaterials.length === 0 ? '4px' : '0'
              }
              border='1px solid'
              borderRight={
                index % shownMaterials.length === shownMaterials.length - 1 ||
                material.id === activeMaterial.id
                  ? 'auto'
                  : 'none'
              }
              borderColor={
                material.id === activeMaterial.id ? 'text' : 'border'
              }
            />
          ))}
        </SimpleGrid>
      </Stack>
      <Divider />
    </>
  );
};
