import { Box, Center } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { useControls, useTransformEffect } from 'react-zoom-pan-pinch';

import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { ImageMarkers } from '$/pages/InspirationEditorPage/components/ImageSection/components/ImageMarkers';
import { RenderedInspirationImage } from '$/pages/InspirationEditorPage/components/RenderedInspirationImage';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  id: string;
}

export const ZoomableImage: FC<Props> = ({ id }) => {
  const setZoomControls = useActionBarStore.useSetZoomControls();
  const syncLibraryState = useActionBarStore.useSyncLibraryState();

  const { zoomIn, zoomOut, resetTransform, setTransform } = useControls();
  const [isImageLoading, setIsImageLoading] = useState(true);
  const activeViewIndex = useInspirationEditorStore.useActiveViewIndex();

  const dragged = useRef(false);

  const activeSceneVariant = useInspirationEditorStore((state) =>
    state.getActiveSceneVariant(),
  );

  useTransformEffect(({ state, instance }) => {
    syncLibraryState(
      state.positionX,
      state.positionY,
      state.scale,
      instance.wrapperComponent,
    );
  });

  useEffect(() => {
    setZoomControls({
      zoomIn,
      zoomOut,
      resetZoom: resetTransform,
      setTransform,
    });
    // We don't want the functions to be a direct dependency here. If we would add all of these,
    // we would create an infinite update loop, which is not what we want here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center
      w='100vw'
      h='100vh'
      onClick={() => {
        !dragged.current &&
          useInspirationEditorStore.setState({
            activeComponentId: null,
            hoveredComponentId: null,
          });
      }}
      onMouseDown={() => (dragged.current = false)}
      onMouseMove={() => (dragged.current = true)}
    >
      <Box pos='relative'>
        <ImageMarkers isImageLoading={isImageLoading} />
        <RenderedInspirationImage
          sceneId={id}
          alt={id}
          loading='lazy'
          isImageLoading={isImageLoading}
          setIsImageLoading={setIsImageLoading}
          layers={activeSceneVariant}
          view={activeViewIndex}
        />
      </Box>
    </Center>
  );
};
