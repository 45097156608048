import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { MaterialType } from '$/services/mapper/uses';

export type State = {
  activeMaterialId: string | null;
  materialType: MaterialType | undefined;
};

type Actions = {
  setActiveMaterial: (id: string | null, materialType?: MaterialType) => void;
};

export const initial: State = {
  activeMaterialId: null,
  materialType: undefined,
};

export const store = create<State & Actions>()((set) => ({
  ...initial,
  setActiveMaterial: (id, type) =>
    set({ activeMaterialId: id, materialType: type }),
}));

export const useMaterialContextMenuStore = createSelectorHooks(
  store,
) as typeof store & ZustandHookSelectors<State & Actions>;
