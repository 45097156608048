import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface LastScene {
  id: string;
  date: Date;
}

type LastSeenStore = {
  lastScenes: LastScene[];
  addLastScene: (id: string) => void;
};

export const store = create<LastSeenStore>()(
  persist(
    (set, get) => ({
      lastScenes: [],
      addLastScene: (id) => {
        const updatedScenes = [...get().lastScenes];
        const existingSceneIndex = updatedScenes.findIndex(
          (scene) => scene.id === id,
        );

        if (existingSceneIndex !== -1) {
          updatedScenes.splice(existingSceneIndex, 1);
        } else if (updatedScenes.length >= 10) {
          updatedScenes.shift();
        }

        updatedScenes.push({ id, date: new Date() });
        set({ lastScenes: updatedScenes });
      },
    }),
    { name: 'last-viewed-scenes' },
  ),
);

export const useLastSeenStore = createSelectorHooks(store);
