import { Input, InputProps } from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

interface Props extends Omit<InputProps, 'onChange'> {
  value: number;
  onChange: (value: number) => void;
  validate: (value: number) => boolean;
  transformer: (value: number) => number;
}

export const BrightnessInputElement: FC<Props> = ({
  value,
  onChange,
  validate,
  transformer,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);
  const borderColor = !validate(localValue) ? 'danger.900' : 'border';

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Input
      w='100px'
      minW='0'
      px='2'
      textAlign='center'
      borderColor={borderColor}
      _hover={{}}
      _focusVisible={{}}
      outline='none'
      max={100}
      min={0}
      onBlur={(e) => onChange(transformer(parseInt(e.currentTarget.value)))}
      onChange={(e) => setLocalValue(parseInt(e.currentTarget.value))}
      onClick={(e) => e.stopPropagation()}
      type='number'
      value={localValue}
      {...props}
    />
  );
};
