import { createRoute, redirect } from '@tanstack/react-router';

import {
  Permission,
  hasPermissionFactory,
} from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { RootRoute } from '$/routes/RootRoute';

const hasPermission = (permission: Permission) =>
  hasPermissionFactory(useAuthenticationStore.getState().user.role)(permission);

export const EditorRouteLayout = createRoute({
  getParentRoute: () => RootRoute,
  beforeLoad: () => {
    if (!hasPermission('Project_Open')) {
      throw redirect({ to: '/not-allowed', replace: true });
    }
  },
  id: 'editorLayout',
});
