import {
  AbsoluteCenter,
  Box,
  Image,
  ImageProps,
  Skeleton,
  Spinner,
} from '@chakra-ui/react';
import { FC, useEffect, useState } from 'react';

import { Layer } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { buildRenderedInspirationImageUrl } from '$/services/endpoints';

interface Props extends Omit<ImageProps, 'src'> {
  sceneId: string;
  layers: Layer[];
  view?: number;
  resolution?: { width: number; height: number };
  setIsImageLoading?: (isLoading: boolean) => void;
  isImageLoading?: boolean;
  isAutoWidth?: boolean;
}

export const RenderedInspirationImage: FC<Props> = ({
  sceneId,
  layers,
  view,
  resolution,
  setIsImageLoading,
  isImageLoading,
  isAutoWidth = false,
  ...imageProps
}) => {
  const [hasLoadedInitialImage, setHasLoadedInitialImage] = useState(false);
  const [isImageLoadingLocal, setIsImageLoadingLocal] = useState(true);
  const [isVerticalImage, setIsVerticalImage] = useState(false);

  const imageUrl = buildRenderedInspirationImageUrl({
    sceneId,
    layers,
    view,
    resolution,
  });

  useEffect(() => {
    if (setIsImageLoading) setIsImageLoading(true);
    else setIsImageLoadingLocal(true);
  }, [imageUrl, setIsImageLoading]);

  const isLoading =
    isImageLoading === undefined ? isImageLoadingLocal : isImageLoading;

  return (
    <Box pos='relative' zIndex='-1'>
      {isLoading && (
        <AbsoluteCenter w='full'>
          {!hasLoadedInitialImage && (
            <Skeleton
              w={imageProps.w ?? imageProps.width ?? 'full'}
              opacity='1'
              borderRadius={imageProps.borderRadius}
              aspectRatio='303/215'
            />
          )}
          {hasLoadedInitialImage && (
            <AbsoluteCenter>
              <Spinner
                w='48px'
                h='48px'
                emptyColor='neutral.1000/30'
                thickness='4px'
              />
            </AbsoluteCenter>
          )}
        </AbsoluteCenter>
      )}
      <Image
        w={isAutoWidth || isVerticalImage ? 'auto' : '60vw'}
        h={isVerticalImage ? '90vh' : 'auto'}
        onLoad={(e) => {
          const isLoaded = () => {
            (setIsImageLoading ?? setIsImageLoadingLocal)(false);
            !hasLoadedInitialImage && setHasLoadedInitialImage(true);
          };

          setIsVerticalImage(e.currentTarget.height > e.currentTarget.width);

          if (!hasLoadedInitialImage) {
            setTimeout(isLoaded, 500);
          } else {
            isLoaded();
          }
        }}
        src={imageUrl}
        {...imageProps}
      />
    </Box>
  );
};
