import { HStack, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { VisualizationBox } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationBox';

export const VisualizationSection = () => {
  const { t } = useTranslation();
  return (
    <VStack align='flex-start' w='full' px='4'>
      <HStack>
        <Icon icon='color_palette' boxSize='4' />
        <Text fontWeight='bold'>{t('editor.visualization')}</Text>
      </HStack>
      <HStack>
        <VisualizationBox
          bg='visualization.transparentBlue'
          borderColor='visualization.blue'
        />
      </HStack>
      <HStack>
        <VisualizationBox bg='visualization.white' />
        <VisualizationBox bg='visualization.black' />
      </HStack>
      <HStack>
        <VisualizationBox bg='visualization.pink' />
        <VisualizationBox bg='visualization.blue' />
        <VisualizationBox bg='visualization.red' />
        <VisualizationBox bg='visualization.orange' />
        <VisualizationBox bg='visualization.green' />
      </HStack>
    </VStack>
  );
};
