import { Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

export const NotAllowedPage = () => {
  const { t } = useTranslation();

  return (
    <Center h='100vh'>
      <VStack
        layerStyle='DashboardSection'
        gap='2.5rem'
        maxW='31rem'
        p='2.5rem'
      >
        <Heading fontSize='3xl' textAlign='center'>
          {t('error.pageNotAllowedHeader')}
        </Heading>

        <Icon w='64px' h='64px' icon='not_allowed_page' />
        <Text fontSize='sm'>{t('error.pageNotAllowedText')}</Text>
        <Link to='/'>
          <Button variant='primary'>{t('error.pageNotAllowedButton')}</Button>
        </Link>
      </VStack>
    </Center>
  );
};
