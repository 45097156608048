import { Button } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
interface DeleteButtonProps {
  buttonIndex: number;
  onClose: () => void;
}

export const DeleteButton: FC<DeleteButtonProps> = ({
  buttonIndex,
  onClose,
}) => {
  const { t } = useTranslation();
  const deleteSceneVariant = useInspirationEditorStore.useDeleteSceneVariant();
  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();

  const handleImageDelete = (index: number) => {
    deleteSceneVariant(index);
    onClose();
  };
  return (
    <Button
      gap='2'
      py='3'
      fontWeight='medium'
      bg='editor.deletePopoverBg'
      isDisabled={sceneLayersVariants.length === 1}
      leftIcon={<Icon icon='trash_can' />}
      onClick={() => handleImageDelete(buttonIndex)}
      variant='secondary'
    >
      {t('editor.delete_variant')}
    </Button>
  );
};
