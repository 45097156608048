import { Divider, HStack, VStack, Text, Button } from '@chakra-ui/react';

import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

export const LayerSidebar = () => {
  const { selectBooleanAction } = useEditorActions();

  return (
    <VStack
      w='full'
      fontSize='sm'
      divider={<Divider pt='1' borderColor='border' />}
    >
      <HStack>
        <Text fontWeight='bold'>Boolean</Text>
      </HStack>
      <HStack>
        <Button onClick={() => selectBooleanAction('exclude')} size='xs'>
          Exclude
        </Button>
        <Button onClick={() => selectBooleanAction('substract')} size='xs'>
          Subtract
        </Button>
        <Button onClick={() => selectBooleanAction('union')} size='xs'>
          Union
        </Button>
      </HStack>
    </VStack>
  );
};
