import { createRoute } from '@tanstack/react-router';

import { HeaderDashboardLayout } from '$/components/layouts/BaseDashboardLayout/HeaderDashboardLayout';
import { RootRoute } from '$/routes/RootRoute';

export const ProfileRouteLayout = createRoute({
  getParentRoute: () => RootRoute,
  component: HeaderDashboardLayout,
  id: 'profileLayout',
});
