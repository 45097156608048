import { createRoute } from '@tanstack/react-router';

import { CookieDeclarationPage } from '$/pages/CookieDeclarationPage';
import { RootRoute } from '$/routes/RootRoute';

export const CookieDeclarationRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/cookie-declaration',
  component: CookieDeclarationPage,
});
