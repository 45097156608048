import { useQuery } from '@tanstack/react-query';
import { FC, useRef } from 'react';

import { CollectionFilter } from '$/components/core/Collection/CollectionFilter';
import { BaseCollectionLayout } from '$/pages/DashboardPages/pages/Collection/pages/layouts/BaseCollectionLayout';
import { FilterType, MaterialType } from '$/services/mapper/uses';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filterType: FilterType;
  materialType: MaterialType;
}

export const CollectionCategoryPage: FC<Props> = ({
  filterType,
  materialType,
}) => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();

  const { data } = useQuery(
    materialsQuery({
      limit: 10,
      filter,
      brightnessFilter,
      type: [materialType],
    }),
  );

  return (
    <BaseCollectionLayout
      filterType={filterType}
      materialType={materialType}
      ref={sectionRef}
    >
      <CollectionFilter
        sectionRef={sectionRef}
        type={filterType}
        amount={data?.materialCount ?? null}
      />
    </BaseCollectionLayout>
  );
};
