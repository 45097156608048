import { FilterGroup } from '$/services/usecases/filter';

export const filterGroupsToQuery = (
  filter: FilterGroup[] | undefined,
  filterWorkaround = false,
) => {
  // TODO: Remove mapping when Backend provides correct searchName
  const filterNameToSearchName = new Map([
    ['producer', 'producer'],
    ['collection', 'collection'],
    ['color', 'color'],
    ['SEARCH_CRITERIA_20', 'productGroup'],
    ['SEARCH_CRITERIA_39', 'colorIntense'],
    ['SEARCH_CRITERIA_36', 'design'],
    ['SEARCH_CRITERIA_37', 'material'],
    ['SEARCH_CRITERIA_38', 'rangeOfUse'],
  ]);

  const filterKeys = filter
    ?.map((filter) => {
      if (filter.type.includes('.')) return filter.type.split('.')[1];
      else return filter.type;
    })
    .map((filter) => {
      if (filterWorkaround) return filterNameToSearchName.get(filter);
      return filter;
    })
    .join(';');

  const filterValues = filter?.map((filter) => filter.option).join(';');

  return [filterKeys, filterValues] as const;
};
