import PiwikPro, {
  CustomEvent,
  PageViews,
  ContentTracking,
} from '@piwikpro/react-piwik-pro';
import * as Sentry from '@sentry/react';

import { TypedToOptions } from '$/components/core/StyledLink';
import {
  PiwikContentInteraction,
  PiwikContentName,
  PiwikContentPiece,
  PiwikContentTarget,
  PiwikEventAction,
  PiwikEventCategory,
  cleanUpCustomDimensionValues,
  mapPathnameToPage,
  parseContentPiece,
  parseContentTarget,
  setCustomDimensionValues,
} from '$/utils/piwikUtils';

export const trackPageView = (
  pathname: string,
  projectCount: number | null = null,
) => {
  const { dimensions, pageTitle } = mapPathnameToPage(
    pathname as TypedToOptions,
  );

  setCustomDimensionValues(dimensions, pathname, projectCount);

  PageViews.trackPageView(pageTitle);

  cleanUpCustomDimensionValues(dimensions);
};

export const trackEvent = (
  category: PiwikEventCategory,
  action: PiwikEventAction,
  name?: string,
  value?: number,
) => {
  CustomEvent.trackEvent(category, action, name, value);
};

export const trackContentImpression = (
  contentName: PiwikContentName,
  contentPiece: PiwikContentPiece,
  contentTarget: PiwikContentTarget,
) => {
  ContentTracking.trackContentImpression(
    contentName,
    parseContentPiece(contentPiece),
    parseContentTarget(contentTarget),
  );
};

export const trackContentInteraction = (
  contentInteraction: PiwikContentInteraction,
  contentName: PiwikContentName,
  contentPiece: PiwikContentPiece,
  contentTarget: PiwikContentTarget,
) => {
  ContentTracking.trackContentInteraction(
    contentInteraction,
    contentName,
    parseContentPiece(contentPiece),
    parseContentTarget(contentTarget),
  );
};

const isSentryEnabled = () => {
  return (
    import.meta.env.VITE_SENTRY_DNS != null &&
    import.meta.env.VITE_SENTRY_ENV != null
  );
};

export const logError = (error: unknown) => {
  if (isSentryEnabled()) {
    Sentry.captureException(error);
  }
  console.error(error);
};

export const enableSentry = () => {
  const dns = import.meta.env.VITE_SENTRY_DNS;
  const env = import.meta.env.VITE_SENTRY_ENV;

  if (dns == null || env == null) {
    console.warn('Logging not enabled');
    return;
  }

  Sentry.init({
    dsn: dns,
    integrations: [],
    environment: env,
  });
};

export const enablePiwik = () => {
  const id = import.meta.env.VITE_PIWIK_CONTAINER_ID;
  const url = import.meta.env.VITE_PIWIK_CONTAINER_URL;

  if (id == null || url == null) {
    console.warn('Visiting not recorded');
    return;
  }

  PiwikPro.initialize(id, url);
};
