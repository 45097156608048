import { Center } from '@chakra-ui/react';
import { MouseEvent } from 'react';

import { Icon } from '$/components/common/Icon';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const AddVariantButton = () => {
  const addSceneVariant = useInspirationEditorStore.useAddSceneVariant();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    addSceneVariant();
  };

  return (
    <Center
      layerStyle='SceneImage'
      display='flex'
      w='full'
      h='full'
      border='0.5px solid'
      borderRadius='4px'
      cursor='pointer'
      onClick={handleClick}
    >
      <Icon icon='add_big' />
    </Center>
  );
};
