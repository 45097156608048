import { SimpleGrid, Stack, StackProps } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { Icon } from '$/components/common/Icon';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { ColorBox } from '$/pages/InspirationEditorPage/components/ColorBox';
import { useCollectionStore } from '$/stores/useCollectionStore';

export const ColorFilter: FC<StackProps> = (props) => {
  const { t } = useTranslation();

  const filter = useCollectionStore.useFilter();
  const getActiveInCategory = useCollectionStore.useGetActiveInCategory();
  const isFilterActive = useCollectionStore.useIsFilterActive();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();

  const [loading, setLoading] = useState('');

  const headerText =
    getActiveInCategory('color').length == 0
      ? t('inspirationEditor.filter.colorGroups')
      : t('inspirationEditor.filter.colorGroups') +
        ` (${getActiveInCategory('color').length})`;

  const colorFilter = filter?.general.find(
    (item) => item.searchName === 'color',
  );

  if (colorFilter == null || colorFilter.items.length === 0) return;

  return (
    <Stack {...props}>
      <FilterHeader
        header={headerText}
        prefix={<Icon icon='color_palette' w='14px' />}
        isActive={true}
        isLoading={filter === undefined}
      />

      {colorFilter && (
        <SimpleGrid gap='2' columns={6}>
          <For each={colorFilter.items}>
            {(option) => (
              <ColorBox
                materialColor={option.payload}
                onClick={() => {
                  if (loading != '') return;

                  setLoading(option.intName);
                  void activateFilterGroup({
                    type: colorFilter.searchName,
                    option: option.intName,
                    displayName: option.displayName,
                    payload: option.payload,
                  }).finally(() => setLoading(''));
                }}
                isLoading={loading === option.intName}
                isActive={isFilterActive(
                  colorFilter.searchName,
                  option.intName,
                )}
                cursor='pointer'
                w='full'
                h={undefined}
                aspectRatio='1/1'
              />
            )}
          </For>
        </SimpleGrid>
      )}
    </Stack>
  );
};
