import { Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

import {
  getRandomPopularity,
  popularityToImageSize,
} from '$/pages/DashboardPages/pages/Inspiration/utils/popularityUtils';

interface Props {
  id: number;
}

export const LoadingSceneImage: FC<Props> = ({ id }) => {
  const imageSize = popularityToImageSize(
    getRandomPopularity(id),
    id.toString(),
  );

  return (
    <Skeleton
      layerStyle='SceneImage'
      w='100%'
      maxW='100%'
      h='100%'
      borderRadius='12px'
      _hover={{
        transform: 'scale(1.02)',
        transition: 'transform 300ms ease',
      }}
      {...imageSize}
    />
  );
};
