import { Box, BoxProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { trackContentInteraction } from '$/logger';
import {
  PiwikContentInteraction,
  PiwikContentName,
  PiwikContentPiece,
  PiwikContentTarget,
} from '$/utils/piwikUtils';

interface Props extends BoxProps {
  children: ReactNode;
  contentInteraction?: PiwikContentInteraction;
  contentName?: PiwikContentName;
  contentPiece?: PiwikContentPiece;
  contentTarget?: PiwikContentTarget;
}

export const TrackedSection: FC<Props> = ({
  children,
  contentInteraction,
  contentName,
  contentPiece,
  contentTarget,
}) => {
  return (
    <Box
      onClick={() => {
        if (
          contentInteraction &&
          contentName &&
          contentPiece &&
          contentTarget
        ) {
          trackContentInteraction(
            contentInteraction,
            contentName,
            contentPiece,
            contentTarget,
          );
        }
      }}
    >
      {children}
    </Box>
  );
};
