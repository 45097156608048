import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Link, useRouterState } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { trackContentImpression } from '$/logger';
import { signOut } from '$/services/usecases/authentication';

export const AccountButton = () => {
  const { t } = useTranslation();

  const { hasPermission } = useAuthorization();

  const { pathname } = useRouterState().location;

  const user = useAuthenticationStore.useUser();
  const onOpenAuthenticationModal = useAuthenticationStore.useOnModalOpen();

  const handleMenuOpen = () => {
    trackContentImpression('CloudAccount', 'Account', 'Open');
  };

  if (!hasPermission('General_User_Profile')) {
    return (
      <Button
        h='auto'
        px='10'
        borderRadius='0'
        onClick={() => onOpenAuthenticationModal('register')}
        rightIcon={<Icon icon='account' />}
        variant='black'
      >
        {t('dashboard.header.createAccount')}
      </Button>
    );
  }

  return (
    <Menu onOpen={handleMenuOpen}>
      <MenuButton
        as={GhostButton}
        h='auto'
        bg={pathname === '/profile' ? 'activeBackground' : 'transparent'}
        borderRadius='0'
        rightIcon={<Icon icon='account' />}
      >
        {user.data?.name}
      </MenuButton>
      <MenuList border='1px' borderColor='border'>
        <MenuItem>
          <TrackedSection
            contentInteraction='ClickButton'
            contentName='CloudAccount'
            contentPiece='Account'
            contentTarget='Manage'
          >
            <Link to='/profile'>{t('dashboard.header.manageAccount')}</Link>
          </TrackedSection>
        </MenuItem>
        <TrackedSection
          contentInteraction='ClickButton'
          contentName='CloudAccount'
          contentPiece='Account'
          contentTarget='Logout'
        >
          <MenuItem onClick={signOut}>{t('dashboard.header.signOut')}</MenuItem>
        </TrackedSection>
      </MenuList>
    </Menu>
  );
};
