import { Spinner, Tag, TagCloseButton, TagProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props extends TagProps {
  children: ReactNode;
  onDelete?: () => void;
  isLoading?: boolean;
}

export const FilterTag: FC<Props> = ({
  children,
  onDelete,
  isLoading = false,
  ...tagProps
}) => {
  return (
    <Tag layerStyle='CollectionTag' borderRadius='full' {...tagProps}>
      {children}
      {onDelete && !isLoading && (
        <TagCloseButton marginInlineStart='0' onClick={onDelete} />
      )}
      {onDelete && isLoading && (
        <Spinner w='12px' h='12px' mx='0.5' emptyColor='neutral.1000/30' />
      )}
    </Tag>
  );
};
