import { BoxProps, ButtonProps, ResponsiveValue } from '@chakra-ui/react';
import { Property } from 'csstype';
import { useState } from 'react';

import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const useHoverAndActiveState = (onClick?: VoidFunction) => {
  const [isHovering, setIsHovering] = useState(false);
  const activeId = useInspirationEditorStore.useActiveComponentId();
  const setActiveId = useInspirationEditorStore.useSetActiveComponentId();

  return (
    id: string,
    defaultBackgroundColor: ResponsiveValue<Property.Color> = 'transparent',
    isMarker: boolean = false,
  ) => {
    const getBackground = () => {
      if (isMarker) {
        if (activeId === id) return 'primaryButton.background';
        return isHovering ? 'bodyBackground' : defaultBackgroundColor;
      }

      if (activeId === id) return 'secondaryBackground';
      return isHovering ? 'bodyBackground' : defaultBackgroundColor;
    };

    const props: BoxProps & ButtonProps = {
      onMouseEnter: () => setIsHovering(true),
      onMouseLeave: () => setIsHovering(false),
      onClick: (e) => {
        e.stopPropagation();
        setActiveId(id);
        onClick && onClick();
      },
      bg: getBackground(),
      _hover: { _not: { _active: { bg: 'bodyBackground' } } },
      _active: { bg: getBackground() },
    };

    return props;
  };
};
