import { ImageProps } from '@chakra-ui/react';
import { useState } from 'react';

export const useBlurryImagePreloader = (
  blurryImageUrl: string,
  highQualityImageUrl: string,
): ImageProps => {
  const [src, setSrc] = useState(blurryImageUrl);
  const [isBlurred, setIsBlurred] = useState(true);

  return {
    src,
    onLoad: () => {
      const img = new Image();
      img.src = highQualityImageUrl;
      img.onload = () => {
        setSrc(highQualityImageUrl);
        setIsBlurred(false);
      };
    },
    filter: isBlurred ? 'blur(3px)' : 'none',
    transition: isBlurred ? 'none' : 'filter 0.3s ease-out',
  };
};
