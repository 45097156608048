import { Box, Stack, StackProps } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC } from 'react';

import { Dropdown } from '$/components/common/Dropdown';
import { SearchDropdownResults } from '$/components/core/Collection/MaterialSearch/components/SearchDropdownResults';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { usePiwikSearchTracking } from '$/hooks/usePiwikSearchTracking';
import { mapFilterTypeToMaterialTypes } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import {
  localFilteredMaterialsQuery,
  materialsQuery,
} from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props extends StackProps {
  searchLabel: string;
  onResultClick?: (material: Material) => void;
  idFilter?: string[];
}

export const DesktopSearchDropdown: FC<Props> = ({
  searchLabel,
  onResultClick,
  idFilter,
  ...props
}) => {
  const activeType = useCollectionStore.useActiveType();
  const dropdownVisible = useMaterialSearchStore.useDropdownVisible();
  const query = useMaterialSearchStore.useQuery();

  const activeMaterialTypes = activeType
    ? mapFilterTypeToMaterialTypes(activeType)
    : undefined;

  const usedQuery = idFilter
    ? localFilteredMaterialsQuery({
        query,
        limit: 10,
        ids: idFilter,
        type: activeMaterialTypes,
      })
    : materialsQuery({ query, limit: 10, type: activeMaterialTypes });

  const { data, isLoading } = useQuery(usedQuery);

  usePiwikSearchTracking(data);

  return (
    <Stack gap='0' {...props}>
      <Stack h='48px'>
        <SearchInput zIndex={20} searchLabel={searchLabel} />
      </Stack>
      <Dropdown isVisible={dropdownVisible} gap='-5px'>
        <Box pt='3'>
          <SearchDropdownResults
            materials={data?.materials}
            isLoading={isLoading}
            onResultClick={onResultClick}
          />
        </Box>
      </Dropdown>
    </Stack>
  );
};
