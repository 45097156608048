import { object, string, array, z } from 'zod';

export const categoryNames: Record<string, string> = {
  hall: 'Flur',
  living: 'Wohnzimmer',
  child: 'Kinderzimmer',
  bedroom: 'Schlafzimmer',
  kitchen_dining: 'Küche',
  sleeping: 'Schlafzimmer',
  one_family_house: 'Einfamilienhaus',
  fassade: 'Fassade',
};

// TODO: Remove if backend provides this data
const blacklistedCategories = ['sleeping'];

export const AllCategoriesSchema = object({
  category: array(string()),
}).transform((data) =>
  data.category
    .filter((category) => !blacklistedCategories.includes(category))
    .map((category) => ({
      id: category,
      name: categoryNames[category],
    })),
);

export type Category = z.infer<typeof AllCategoriesSchema>[number];
