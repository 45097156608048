import { Box } from '@chakra-ui/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { ReactNode, FC } from 'react';

import { DropdownContent } from '$/components/common/Dropdown/DropdownContent';

interface Props {
  children: ReactNode;
  isVisible: boolean;
  gap?: string;
}

export const Dropdown: FC<Props> = ({ children, isVisible, gap }) => {
  return (
    <MotionConfig reducedMotion='user'>
      <Box pos='relative'>
        <AnimatePresence>
          {isVisible && (
            <DropdownContent isOpen={isVisible} gap={gap}>
              {children}
            </DropdownContent>
          )}
        </AnimatePresence>
      </Box>
    </MotionConfig>
  );
};
