import { createRoute } from '@tanstack/react-router';

import { ProjectPage } from '$/pages/DashboardPages/pages/Projects';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';

export const ProjectsRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/projects',
  component: ProjectPage,
});
