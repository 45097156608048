import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { FC } from 'react';

import { ActionGroup } from '$/components/core/Editor/ActionBar/components/ActionGroup';
import { EditorZoomDropdown } from '$/components/core/Editor/ActionBar/components/EditorZoomDropdown';
import { HorizontalGroup } from '$/components/core/Editor/ActionBar/components/HorizontalGroup';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useKeyPress } from '$/hooks/useKeyPress';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  groups: EditorActionGroup[];
}

export const ActionBarSection: FC<Props> = ({ groups }) => {
  const activeComponentIndex = useEditorStore.useActiveComponentIndex();
  const { selectAction } = useEditorActions();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const rawStatus = useEditorStore.useRawStatus();

  useKeyPress(
    ({ key }) =>
      key === 'Escape' && selectAction(ModuleSendSelectAction.SelectPoly),
  );

  return (
    <HStack as='section' layerStyle='FloatingActionBar'>
      <HStack gap='0' h='full'>
        {groups.map(
          (group) =>
            !(activeComponentIndex === null && group.needComponent) && (
              <HStack key={group.id} h='100%'>
                <Box display={{ base: 'none', xl: 'flex' }}>
                  <HorizontalGroup group={group} />
                </Box>

                <Box display={{ base: 'flex', xl: 'none' }}>
                  <ActionGroup group={group} />
                </Box>

                <Box w='2px' h='40%' bg='header.divider' borderRadius='2px' />
              </HStack>
            ),
        )}

        <EditorZoomDropdown />
        <Button fontSize='sm' onClick={onOpen} size='sm'>
          Debugger
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size='2xl'>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Last Received Status</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text fontSize='sm' whiteSpace='pre'>
                {JSON.stringify(rawStatus, null, 4)}
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
      </HStack>
    </HStack>
  );
};
