import { IconButton } from '@chakra-ui/react';
import { TFunction } from 'i18next';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AvailableIcons, Icon } from '$/components/common/Icon';

type ModeOptions = 'new' | 'add' | 'subtract';

const getProperties = (
  mode: ModeOptions,
  t: TFunction<'translation', undefined>,
): { label: string; icon: AvailableIcons } => {
  switch (mode) {
    case 'new':
      return {
        icon: 'magic_wand',
        label: t('editor.addComponentIconAlt'),
      };
    case 'add':
      return {
        icon: 'magic_wand_add',
        label: t('editor.addComponentIconAlt'),
      };
    case 'subtract':
      return {
        icon: 'magic_wand_subtract',
        label: t('editor.addComponentIconAlt'),
      };
  }
};

interface Props {
  mode: ModeOptions;
}

export const MagicWandModeOptionButton: FC<Props> = ({ mode }) => {
  const { t } = useTranslation();
  const isActive = mode === 'add';

  const { icon, label } = getProperties(mode, t);

  const onSelect = () => {
    // Implement when endpoints exist
    console.log('Selecting magic wand mode', mode);
  };

  return (
    <IconButton
      px='3'
      py='2'
      bg={isActive ? 'visualization.transparentBlue' : 'transparent'}
      border='1px solid'
      borderColor={isActive ? 'visualization.blue' : 'border'}
      aria-label={label}
      icon={<Icon icon={icon} h='16px' w='18px' />}
      onClick={onSelect}
      title={label}
      variant='text'
    />
  );
};
