export const dashboardLayerStyles = {
  WelcomeCard: {
    aspectRatio: '3/2',
    flex: '1',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: 'border',
    background: 'background',
    backgroundSize: 'cover',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 'sm',
    textAlign: 'center',
    lineHeight: 'standard',
  },
  DashboardSection: {
    bg: 'background',
    border: '1px solid',
    borderColor: 'border',
    borderRadius: '12px',
  },
  CollectionTag: {
    background: 'secondaryBackground',
    border: 'solid 1px',
    borderColor: 'lighterText',
    paddingY: '1.5',
    paddingX: '3.5',
    gap: '3',
    whiteSpace: 'nowrap',
    flexShrink: '0',
  },
  FilterTag: {
    background: 'background',
    border: 'solid 1px',
    borderColor: 'lighterText',
    paddingY: '1.5',
    paddingX: '3.5',
    gap: '3',
    whiteSpace: 'nowrap',
    flexShrink: '0',
  },
  FilterTagSelected: {
    background: 'secondaryBackground',
    border: 'solid 1px',
    borderColor: 'lighterText',
    paddingY: '1.5',
    paddingX: '3.5',
    gap: '3',
    whiteSpace: 'nowrap',
    flexShrink: '0',
  },
};
