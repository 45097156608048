import { useEffect, useState } from 'react';

import { useToast } from '$/hooks/useToast';
import { logError } from '$/logger';
import {
  ModuleSendAction,
  ModuleSendSelectAction,
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

type ModuleSendMessage = {
  action:
    | ModuleSendAction
    | ModuleSendSelectAction
    | ModuleSendBrushShapeAction
    | ModuleSendBrushSizeAction;
} & Record<string, unknown>;

type BrushShape = 'round' | 'square';

export type EditorActions = ReturnType<typeof useEditorActions>;

export const useEditorActions = () => {
  const editorModuleRef = useEditorStore.useEditorModuleRef();
  const [iframeWindow, setIframeWindow] = useState<Window | null>(null);
  const toast = useToast();

  useEffect(() => {
    setIframeWindow(editorModuleRef?.current?.contentWindow ?? null);
  }, [editorModuleRef]);

  const sendMessage = (message: ModuleSendMessage) => {
    if (iframeWindow == null) {
      logError('Editor Module not found');
      return;
    }
    // This log is here on purpose while developing, so we can see what exactly has been sent to the module
    console.log('SENT MESSAGE', message);
    iframeWindow.postMessage(
      JSON.stringify(message),
      'https://emodule.aocluster.com',
    );

    if (
      message.action !== ModuleSendAction.GetModuleState &&
      !message.action.includes('hover')
    ) {
      // Toast is only for testing purposes, remove this when we have more actual working comm
      toast(`Sent action to module`, 'success', JSON.stringify(message), {
        customIcon: 'arrow_right',
        customColor: 'lightblue',
      });
    }
  };

  const getModuleState = () => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.GetModuleState,
    };
    sendMessage(message);
  };

  /**
   * MESSAGES
   */

  const loadPicture = (token: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.LoadPicture,
      token,
    };
    sendMessage(message);
  };

  const selectAction = (action: ModuleSendSelectAction) => {
    const message: ModuleSendMessage = {
      action: action,
    };
    sendMessage(message);
  };

  const selectBooleanAction = (
    booleanMode: 'union' | 'substract' | 'intersect' | 'exclude',
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.DoBooleanOperation,
      booleanMode,
    };
    sendMessage(message);
  };

  const createComponent = (name: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.AddElement,
      name,
    };
    sendMessage(message);
  };

  const renameComponent = (componentIndex: number, newName: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.RenameElement,
      name: newName,
      idx: componentIndex,
    };
    sendMessage(message);
  };

  const selectComponent = (componentIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SelectElement,
      idx: componentIndex,
    };
    sendMessage(message);
  };

  const moveComponent = (oldIndex: number, newIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.MoveElement,
      data: {
        idxOld: oldIndex,
        idxNew: newIndex,
      },
    };
    sendMessage(message);
  };

  const deleteComponent = (componentIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.DeleteElement,
      idx: componentIndex,
    };
    sendMessage(message);
  };

  const renameObject = (
    componentIndex: number,
    objectIndex: number,
    newName: string,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.RenameLayer,
      data: {
        elementIdx: componentIndex,
        layerIdx: objectIndex,
        name: newName,
      },
    };
    sendMessage(message);
  };

  const selectObjects = (
    selectedObjects: { componentIndex: number; objectIndex: number }[],
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SelectLayer,
      data: selectedObjects.map((item) => ({
        elementIdx: item.componentIndex,
        layerIdx: item.objectIndex,
      })),
    };
    sendMessage(message);
  };

  const moveObject = (
    componentIndex: number,
    oldIndex: number,
    newIndex: number,
  ) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.MoveLayer,
      data: {
        elementIdx: componentIndex,
        layerIdxOld: oldIndex,
        layerIdxNew: newIndex,
      },
    };
    sendMessage(message);
  };

  const deleteObject = (componentIndex: number, objectIndex: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.DeleteLayer,
      data: {
        elementIdx: componentIndex,
        layerIdx: objectIndex,
      },
    };
    sendMessage(message);
  };

  const zoomPercentage = (percent: number) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ZoomPercentage,
      percent,
    };
    sendMessage(message);
  };

  const setBrushShape = (
    brushType: ModuleSendBrushShapeAction,
    brushShape: BrushShape,
  ) => {
    const message: ModuleSendMessage = {
      action: brushType,
      type: brushShape,
    };
    sendMessage(message);
  };

  const setBrushSize = (
    brushType: ModuleSendBrushSizeAction,
    brushSize: number,
  ) => {
    const message: ModuleSendMessage = {
      action: brushType,
      size: brushSize,
    };
    sendMessage(message);
  };

  const setLimitToMask = (limitToMask: boolean) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.LimitToMask,
      limit: limitToMask,
    };
    sendMessage(message);
  };

  const setMaterial = (materialId: string) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.SetMaterial,
      matId: materialId,
    };
    sendMessage(message);
  };

  const setHoveredElement = (elementIndex: number | null) => {
    let message: ModuleSendMessage;
    if (elementIndex === null) {
      message = {
        action: ModuleSendAction.HoverOffElement,
      };
    } else {
      message = {
        action: ModuleSendAction.HoverElement,
        data: {
          elementIdx: elementIndex,
        },
      };
    }
    sendMessage(message);
  };

  const setHoveredLayer = (
    object: { elementIndex: number; layerIndex: number } | null,
  ) => {
    let message: ModuleSendMessage;

    if (object == null) {
      message = {
        action: ModuleSendAction.HoverOffLayer,
      };
    } else {
      message = {
        action: ModuleSendAction.HoverLayer,
        data: {
          elementIdx: object.elementIndex,
          layerIdx: object.layerIndex,
        },
      };
    }

    sendMessage(message);
  };

  const setColorChecker = (colorChecker: boolean) => {
    const message: ModuleSendMessage = {
      action: ModuleSendAction.ColorChecker,
      status: colorChecker ? 1 : 0,
    };
    sendMessage(message);
  };

  return {
    loadPicture,
    selectAction,
    selectBooleanAction,
    createComponent,
    renameComponent,
    selectComponent,
    moveComponent,
    deleteComponent,
    renameObject,
    selectObjects,
    moveObject,
    deleteObject,
    zoomPercentage,
    setBrushShape,
    setBrushSize,
    setLimitToMask,
    getModuleState,
    setMaterial,
    setHoveredElement,
    setHoveredLayer,
    setColorChecker,
  };
};
