import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    borderWidth: '1px',
    borderColor: 'neutralText',
    _checked: {
      bg: 'background',
      color: 'text',
      borderColor: 'neutralText',
      _before: {
        height: '80%',
        width: '80%',
      },
      _hover: {
        bg: 'background',
        color: 'text',
      },
    },
  },
});

export const Radio = defineMultiStyleConfig({ baseStyle });
