export const dashboardColorTokens = {
  dashboard: {
    backgroundIconWelcome: {
      default: 'badgeLight.blue.background',
      _dark: 'badgeDark.blue.background',
    },
    backgroundIconInspirations: {
      default: 'badgeLight.red.background',
      _dark: 'badgeDark.red.background',
    },
    backgroundIconCollections: {
      default: 'badgeLight.green.background',
      _dark: 'badgeDark.green.background',
    },
    backgroundIconProjects: {
      default: 'badgeLight.brown.background',
      _dark: 'badgeDark.brown.background',
    },
    fillIconWelcome: {
      default: 'badgeLight.blue.text',
      _dark: 'badgeDark.blue.text',
    },
    fillIconInspirations: {
      default: 'badgeLight.red.text',
      _dark: 'badgeDark.red.text',
    },
    fillIconCollections: {
      default: 'badgeLight.green.text',
      _dark: 'badgeDark.green.text',
    },
    fillIconProjects: {
      default: 'badgeLight.brown.text',
      _dark: 'badgeLight.brown.text',
    },
    btnNewProjectBackground: {
      default: 'neutral.200',
      _dark: 'neutral.600',
    },
    createAccountCard: {
      background: {
        default: 'neutral.1000',
        _dark: 'neutral.50',
      },
      color: {
        default: 'neutral.50',
        _dark: 'neutral.1000',
      },
    },
  },
};
