import { Image, Skeleton } from '@chakra-ui/react';
import { FC, useState } from 'react';

import { endpointFactory } from '$/services/endpoints';

interface Props {
  id: string;
  view: string;
}

export const PerspectiveImage: FC<Props> = ({ id, view }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <Skeleton w='full' h='full' isLoaded={!isImageLoading}>
      <Image
        layerStyle='SceneImage'
        w='full'
        h='full'
        borderRadius='4px'
        alt={id}
        loading='lazy'
        onLoad={() => setIsImageLoading(false)}
        src={endpointFactory.sceneImageUrl({ id, view })}
      />
    </Skeleton>
  );
};
