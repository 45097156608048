import { HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { BrightnessInputElement } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessInputElement';
import { BrightnessSlider } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessSlider';

interface Props {
  value: number[];
  setValue: (value: number[]) => void;
}

export const BrightnessFilterMenuItem: FC<Props> = ({ value, setValue }) => {
  return (
    <HStack gap='4' p='3'>
      <BrightnessInputElement
        value={value[0]}
        validate={(curr) => curr >= 0 && curr <= 100 && curr < value[1]}
        transformer={(curr) => (curr <= 0 ? 0 : value[1] - 1)}
        onChange={(newValue) => setValue([newValue, value[1]])}
      />
      <BrightnessSlider value={value} setValue={setValue} />
      <BrightnessInputElement
        value={value[1]}
        validate={(curr) => curr >= 0 && curr <= 100 && curr > value[0]}
        transformer={(curr) => (curr >= 100 ? 100 : value[0] + 1)}
        onChange={(newValue) => setValue([value[0], newValue])}
      />
    </HStack>
  );
};
