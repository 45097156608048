import { FC, useMemo } from 'react';

import { For } from '$/components/common/Flow/For';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { createPopularityRank } from '$/pages/DashboardPages/pages/Inspiration/utils/popularityUtils';
import { InspirationCard } from '$/pages/DashboardPages/pages/Welcome/components/InspirationSection/components/InspirationCard';
import { Scene } from '$/services/usecases/scenes';
import { useLastSeenStore } from '$/stores/useLastSeenStore';

interface Props {
  scenes: Record<string, Scene[]>;
}

export const InspirationRow: FC<Props> = ({ scenes }) => {
  const lastScenes = useLastSeenStore.useLastScenes();
  const itemLimit = useMaterialGridMaxItems();

  const highlightedScenes = useMemo(() => {
    const allScenes = Object.entries(scenes).flatMap((item) => item[1]);
    const rankedScenes = allScenes.map((scene, __, self) =>
      createPopularityRank(self)(scene),
    );

    const shownScenes = [...lastScenes]
      .reverse()
      .slice(0, itemLimit)
      .reduce<Scene[]>((prev, current) => {
        const scene = allScenes.find((scene) => scene.id === current.id);
        if (scene != null) {
          prev.push(scene);
        }
        return prev;
      }, []);

    if (shownScenes.length < itemLimit) {
      const additionalScenes = rankedScenes
        .filter(
          (scene) => !lastScenes.some((lastScene) => lastScene.id === scene.id),
        )
        .slice(0, itemLimit - shownScenes.length);
      shownScenes.push(...additionalScenes);
    }
    return shownScenes;
  }, [scenes, lastScenes, itemLimit]);

  return (
    <For each={highlightedScenes}>
      {(scene) => (
        <InspirationCard key={scene.id} id={scene.id} label={scene.category} />
      )}
    </For>
  );
};
