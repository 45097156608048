import { useEffect, useState } from 'react';

export const useIdleUserHook = (idleTime: number = 5000) => {
  const [isUserIdle, setIsUserIdle] = useState(false);

  useEffect(() => {
    let timeoutId: number | null = null;

    const onUserInteraction = () => {
      setIsUserIdle(false);
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }

      timeoutId = window.setTimeout(() => {
        setIsUserIdle(true);
      }, idleTime);
    };

    window.addEventListener('mousemove', onUserInteraction);
    window.addEventListener('click', onUserInteraction);

    return () => {
      setIsUserIdle(false);
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
      window.removeEventListener('mousemove', onUserInteraction);
      window.removeEventListener('click', onUserInteraction);
    };
  }, [idleTime]);

  return isUserIdle;
};
