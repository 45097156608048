import {
  buildUrl,
  cnf,
  dataEndpoints,
  NullableUrlSearchParams,
} from '$/services/endpoints';
import {
  FilterType,
  mapTypeToUses,
  MaterialType,
} from '$/services/mapper/uses';

const addTypeToSearchParams = (
  keys: string | undefined,
  vals: string | undefined,
  type: MaterialType[] | undefined,
) => {
  if (!type || type.length === 0) return [keys, vals] as const;

  const usesVal = type.map(mapTypeToUses).join('|');
  if (!keys && !vals) return ['uses', usesVal] as const;

  return [[keys, 'uses'].join(';'), [vals, usesVal].join(';')] as const;
};

const materialCols =
  'SEARCH_CRITERIA_INT_7;SEARCH_CRITERIA_INT_8;SEARCH_CRITERIA_INT_10;SEARCH_CRITERIA_7;SEARCH_CRITERIA_8;SEARCH_CRITERIA_16;collection;SEARCH_CRITERIA_INT_6;info;producer;category;uses;mode;color;texture_filename;unique_key';

export const dataEndpointFactory = {
  materialSearchValues: ({
    searchKeys,
    searchValues,
    type,
  }: {
    searchKeys?: string;
    searchValues?: string;
    type?: FilterType;
  } = {}) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      lng: 'de',
      skeys: searchKeys,
      svals: searchValues,
      mode: type,
    };

    return buildUrl(dataEndpoints.MATERIAL_SEARCH_VALUES, params);
  },
  inspirationCategories: () => {
    const params: NullableUrlSearchParams = { cnf: cnf() };
    return buildUrl(dataEndpoints.SCENE_CATEGORIES, params);
  },
  inspirationScenes: ({
    sceneId,
    withComponents = false,
  }: {
    sceneId?: string;
    withComponents?: boolean;
  } = {}) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      skeys: sceneId ? 'id' : undefined,
      svals: sceneId,
      extended: withComponents ? '1' : '0',
      cols: materialCols,
      lng: 'de',
    };
    return buildUrl(dataEndpoints.SCENES, params);
  },
  inspirationMaterial: ({
    searchValues,
    searchKeys,
    sortOrder,
    limit,
    page,
    type,
    language,
  }: {
    searchValues?: string;
    searchKeys?: string;
    sortOrder?: string;
    limit?: number;
    page?: number;
    type?: MaterialType[];
    language: string;
  }) => {
    const [keysWithType, valsWithType] = addTypeToSearchParams(
      searchKeys,
      searchValues,
      type,
    );

    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      skeys: keysWithType,
      svals: valsWithType,
      cols: materialCols,
      limit: limit?.toString(),
      page: page?.toString(),
      sorder: sortOrder,
      lng: language,
    };

    return buildUrl(dataEndpoints.MATERIALS, params);
  },
  fandeckInfo: ({ collection, page }: { collection: string; page: number }) => {
    const params: NullableUrlSearchParams = {
      cnf: cnf(),
      collection,
      page: page.toString(),
    };
    return buildUrl(dataEndpoints.FANDECK_INFO, params);
  },
};
