import { z } from 'zod';

export const HoverStatusSchema = z
  .object({
    elementIdx: z.number().nullable().optional(),
    layerIdx: z.number().nullable().optional(),
  })
  .transform((data) => ({
    elementIndex: data.elementIdx,
    layerIndex: data.layerIdx,
  }));

export type HoverStatus = z.infer<typeof HoverStatusSchema>;
