import { object, string, z, array } from 'zod';

import {
  ForcedElement,
  GeneralAuthResponse,
  Successful,
  parseErrorResult,
} from '$/services/mapper/authentication';

export const AllProjectsSchema = object({
  ...GeneralAuthResponse,
  list: array(object({ unique_id: string(), name: string() })).optional(),
}).transform((data) => {
  return parseErrorResult(data, () => ({
    projects: data.list!.map((project) => ({
      id: project.unique_id,
      name: project.name,
    })),
  }));
});

export type Project = ForcedElement<
  Successful<z.infer<typeof AllProjectsSchema>>['projects']
>;
