import { queryOptions } from '@tanstack/react-query';

import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { FilterType } from '$/services/mapper/uses';
import { FilterGroup, getFilterOptions } from '$/services/usecases/filter';

export const filterOptionsQuery = ({
  type,
  filter,
}: {
  type?: FilterType;
  filter?: FilterGroup[];
} = {}) =>
  queryOptions({
    queryKey: ['filter_options', type, filter],
    queryFn: ({ signal }) => getFilterOptions({ signal, type, filter }),
    staleTime: STANDARD_STALE_TIME,
  });
