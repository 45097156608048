import { Link } from '@tanstack/react-router';
import { FC } from 'react';

import { CardContent } from '$/components/common/Card/CardContent';
import { endpointFactory } from '$/services/endpoints';
import { Project } from '$/services/usecases/projects/mapper/allProjects';

interface Props {
  project: Project;
  isLoaded: boolean;
}

export const ProjectCard: FC<Props> = ({ project }) => {
  return (
    <Link to='/editor/$id' params={{ id: project.id }}>
      <CardContent
        label={project.name}
        bg='transparent'
        image={endpointFactory.projectThumbnail({ id: project.id })}
      />
    </Link>
  );
};
