import {
  Menu,
  MenuButton,
  MenuItemProps,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';

interface Props extends MenuItemProps {
  title: string;
  children: ReactNode;
  closeOnSelect?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const GenericFilterSortDropdown: FC<Props> = ({
  title,
  children,
  closeOnSelect = false,
  isOpen,
  onClose,
  onOpen,
  ...props
}) => {
  const [isLargeDevice] = useMediaQuery('(min-height: 800px)');

  return (
    <Menu
      closeOnSelect={closeOnSelect}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
    >
      <MenuButton
        as={GhostButton}
        sx={{
          span: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
        }}
        h={{ base: '35px', md: 'auto' }}
        px={{ base: '4', md: '0' }}
        color='lighterText'
        fontSize='sm'
        border={{ base: '1px solid', md: 'none' }}
        borderColor={{ base: 'border', md: 'none' }}
        borderRadius={{ base: 'full', md: '0' }}
        isActive={isOpen}
        rightIcon={
          <Icon
            transition='transform 0.3s ease'
            transform={`rotate(${isOpen ? '180deg' : '0deg'})`}
            icon='chevron_down'
            w='12px'
          />
        }
        {...props}
      >
        {title}
      </MenuButton>
      <MenuList
        zIndex='dropdown'
        overflowY='auto'
        minW='0'
        maxH={isLargeDevice ? '50vh' : '25vh'}
        borderColor='border'
      >
        {children}
      </MenuList>
    </Menu>
  );
};
