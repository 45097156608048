import { Center, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { trackContentImpression } from '$/logger';
import { ChangePasswordModal } from '$/pages/Profile/pages/ChangePasswordModal';
import { ChangePasswordSuccessModal } from '$/pages/Profile/pages/ChangePasswordSuccessModal';
import { DeleteAccountModal } from '$/pages/Profile/pages/DeleteAccountModal';
import { ProfileModal } from '$/pages/Profile/pages/ProfileModal';
import { mapProfileModalToPiwikContentPiece } from '$/utils/piwikUtils';

export type ProfileModals =
  | 'profile'
  | 'change_password'
  | 'delete_account'
  | 'change_password_success';

export const ProfilePage = () => {
  const [activeModal, setActiveModal] = useState<ProfileModals>('profile');

  useEffect(() => {
    const contentPiece = mapProfileModalToPiwikContentPiece(activeModal);

    if (contentPiece) {
      trackContentImpression('CloudAccount', contentPiece, 'Open');
    }
  }, [activeModal]);

  return (
    <Center>
      <VStack
        layerStyle='DashboardSection'
        alignItems='flex-start'
        gap='2.5rem'
        overflowY='auto'
        w={{ base: '100%', sm: '32rem' }}
        maxH='100%'
        p='2.5rem'
      >
        {activeModal === 'profile' && (
          <ProfileModal onViewChange={setActiveModal} />
        )}
        {activeModal === 'delete_account' && (
          <DeleteAccountModal onReturn={() => setActiveModal('profile')} />
        )}
        {activeModal === 'change_password' && (
          <ChangePasswordModal
            onSuccess={() => setActiveModal('change_password_success')}
            onReturn={() => setActiveModal('profile')}
          />
        )}
        {activeModal === 'change_password_success' && (
          <ChangePasswordSuccessModal />
        )}
      </VStack>
    </Center>
  );
};
