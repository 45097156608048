import { HStack } from '@chakra-ui/react';
import { ReactNode, forwardRef } from 'react';

interface Props {
  children: ReactNode;
}

export const FilterWrapper = forwardRef<HTMLDivElement, Props>(
  function FilterWrapper({ children }, ref) {
    return (
      <HStack pb='4'>
        <HStack
          className='hidden-scrollbar'
          ref={ref}
          flexWrap={{ base: 'nowrap', md: 'wrap' }}
          gap={{ base: '3', md: '6' }}
          overflowX={{ base: 'auto', md: 'hidden' }}
          w='fit-content'
          py='2'
        >
          {children}
        </HStack>
      </HStack>
    );
  },
);
