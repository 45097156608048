import { Button, Grid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { Icon } from '$/components/common/Icon';
import { ActionCard } from '$/components/core/Projects/components/Cards/ActionCard';
import { DashboardSection } from '$/pages/DashboardPages/components/DashboardSection';
import { FavoriteFolderItem } from '$/pages/DashboardPages/pages/Favorite/components/FavoriteFolderItem';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';

export const FavoriteFolders = () => {
  const { t } = useTranslation();
  const folders = useFavoriteStore.useFavoriteFolders();
  const addFolder = useFavoriteStore.useAddFolder();
  return (
    <DashboardSection
      headline={t('favorites.yourFolders')}
      customActionElement={
        <Button px='0' onClick={() => addFolder()} variant='ghost'>
          {t('favorites.addFolder')}
        </Button>
      }
    >
      <Grid gap='6' templateColumns={materialTemplateColumn()}>
        <For each={folders}>
          {(folder) => <FavoriteFolderItem key={folder.id} folder={folder} />}
        </For>
        {folders.length === 0 && (
          <ActionCard
            icon={<Icon icon='add_sign' />}
            headerText={t('favorites.addFolder')}
            onClick={() => addFolder()}
            cursor='pointer'
          />
        )}
      </Grid>
    </DashboardSection>
  );
};
