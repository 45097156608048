import { Box, Grid, GridProps } from '@chakra-ui/react';
import { FC } from 'react';

import { CardTextArea } from '$/components/common/Card/CardTextArea';

export type CardContentGridProps = Omit<GridProps, 'bg' | 'brightness'>;

interface Props extends CardContentGridProps {
  image?: string;
  label: string;
  brightness?: number;
  bg: string;
}

export const CardContent: FC<Props> = ({
  bg,
  label,
  brightness,
  image,
  ...props
}) => {
  return (
    <Grid
      templateRows='1fr auto'
      templateColumns='minmax(0, 1fr)'
      overflow='hidden'
      border='1px solid'
      borderColor='border'
      borderRadius='12px'
      _hover={{
        borderColor: 'borderHover',
      }}
      _active={{
        borderColor: 'borderActive',
      }}
      aspectRatio='3/2'
      {...props}
    >
      <Box bg={bg} bgImage={image} bgSize='cover' />
      <CardTextArea label={label} brightness={brightness} />
    </Grid>
  );
};
