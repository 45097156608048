import { CustomDimensions } from '@piwikpro/react-piwik-pro';

import {
  AuthModalType,
  useAuthenticationStore,
} from '$/components/core/Authentication/stores/useAuthenticationStore';
import { TypedToOptions } from '$/components/core/StyledLink';
import { ProfileModals } from '$/pages/Profile';
import { MaterialType } from '$/services/mapper/uses';
import { useFavoriteStore } from '$/stores/useFavoriteStore';
import { PiwikDesignOption, usePiwikStore } from '$/stores/usePiwikStore';

export type PiwikEventCategory =
  | 'FilterCollections'
  | 'FilterInspiration'
  | 'ColorTone'
  | 'WallCovering'
  | 'FloorCovering';

export type PiwikEventAction =
  | 'Producer'
  | 'Collection'
  | 'Colorgroup'
  | 'LightReferenceValueMin'
  | 'LightReferenceValueMax'
  | 'Productgroup'
  | 'Floor_Colorintensity'
  | 'Floor_Design'
  | 'Floor_Material'
  | 'Floor_Use'
  | 'Click'
  | 'AddFavorite'
  | 'Roomtype';

export type PiwikContentInteraction =
  | 'ClickImage'
  | 'ClickPreview'
  | 'ClickButton';

export type PiwikContentName =
  | 'CollectionOverlay'
  | 'CloudAccount'
  | 'Favorite';

export type RoomSource = { roomSourceIndex: number };

export type UriTarget = { uri: string };

export type TrackedProfileInputField = 'EditName' | 'EditMail';

export type PiwikContentPiece =
  | 'Preview'
  | 'ImageRow'
  | 'Buttons'
  | 'Registration'
  | 'Login'
  | 'ForgetPassword'
  | 'Account'
  | 'ManageAccount'
  | 'EditPassword'
  | 'DeleteAccount'
  | 'ManageFavorite'
  | 'EditFolder'
  | 'NewFolder'
  | TrackedProfileInputField
  | RoomSource;

export type PiwikContentTarget =
  | 'DesignMaterial'
  | 'Inspiration'
  | 'Close'
  | 'Open'
  | 'NewAccount'
  | 'Login'
  | 'ForgetPassword'
  | 'Reset'
  | 'Back'
  | 'Manage'
  | 'Logout'
  | 'Picture'
  | 'Cancel'
  | 'Submit'
  | 'EditPassword'
  | 'DeleteAccount'
  | 'Edit'
  | 'Delete'
  | 'EditFolder'
  | 'NewFolder'
  | 'Enable'
  | 'Disable'
  | 'InsertName'
  | TrackedProfileInputField
  | RoomSource
  | UriTarget;

export const isRoomSource = (
  input: PiwikContentPiece | PiwikContentTarget,
): input is RoomSource => {
  return (input as RoomSource).roomSourceIndex !== undefined;
};

export const isUriTarget = (
  input: PiwikContentPiece | PiwikContentTarget,
): input is UriTarget => {
  return (input as UriTarget).uri !== undefined;
};

export enum CustomDimensionsOptions {
  Version = 1,
  Projects = 2,
  Favorites = 3,
  DesignOrigin = 4,
  Design = 5,
  DesignName = 6,
  InspirationImage = 7,
}

export const mapMaterialTypeToCategory = (
  type: MaterialType,
): PiwikDesignOption | PiwikEventCategory => {
  switch (type) {
    case 'uni':
      return 'ColorTone';
    case 'floor':
      return 'FloorCovering';
    case 'wall':
      return 'WallCovering';
    case 'facade':
      return 'WallCovering';
  }
};

export const mapFilterGroupTypeToAction = (
  type: string,
): PiwikEventAction | null => {
  switch (type) {
    case 'producer':
      return 'Producer';
    case 'collection':
      return 'Collection';
    case 'color':
      return 'Colorgroup';
    case 'SEARCH_CRITERIA_20':
      return 'Productgroup';
    case 'SEARCH_CRITERIA_39':
      return 'Floor_Colorintensity';
    case 'SEARCH_CRITERIA_36':
      return 'Floor_Design';
    case 'SEARCH_CRITERIA_37':
      return 'Floor_Material';
    case 'SEARCH_CRITERIA_38':
      return 'Floor_Use';
    default:
      return null;
  }
};

export const mapPathnameToPage = (
  pathname: TypedToOptions,
): { pageTitle: string; dimensions: CustomDimensionsOptions[] } => {
  switch (true) {
    case pathname === '/':
      return {
        pageTitle: 'Welcome Page',
        dimensions: [
          CustomDimensionsOptions.Version,
          CustomDimensionsOptions.Projects,
          CustomDimensionsOptions.Favorites,
        ],
      };
    case pathname === '/projects':
      return {
        pageTitle: 'Projects',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname === '/inspirations':
      return {
        pageTitle: 'Inspiration',
        dimensions: [
          CustomDimensionsOptions.Version,
          CustomDimensionsOptions.DesignOrigin,
          CustomDimensionsOptions.Design,
          CustomDimensionsOptions.DesignName,
        ],
      };
    case pathname === '/collections':
      return {
        pageTitle: 'Collections',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname === '/collections/colors':
      return {
        pageTitle: 'Collections-Colortone',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname === '/collections/walls':
      return {
        pageTitle: 'Collections-Wallcovering',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname === '/collections/floors':
      return {
        pageTitle: 'Collections-Floorcovering',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname === '/favorites':
      return {
        pageTitle: 'Favorites',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname?.includes('/inspirations/'):
      return {
        pageTitle: 'Inspiration-Editor',
        dimensions: [
          CustomDimensionsOptions.Version,
          CustomDimensionsOptions.DesignOrigin,
          CustomDimensionsOptions.Design,
          CustomDimensionsOptions.DesignName,
          CustomDimensionsOptions.InspirationImage,
        ],
      };
    case pathname?.includes('/editor'):
      return {
        pageTitle: 'Projects-Editor',
        dimensions: [CustomDimensionsOptions.Version],
      };
    case pathname === '/pricing':
      return {
        pageTitle: 'Subscription Page',
        dimensions: [CustomDimensionsOptions.Version],
      };
    default:
      return {
        dimensions: [],
        pageTitle: pathname?.replace('/', ' ') ?? '',
      };
  }
};

export const mapAuthModalTypeToPiwikContentPiece = (
  authModalType: AuthModalType,
): PiwikContentPiece | undefined => {
  switch (authModalType) {
    case 'login':
      return 'Login';
    case 'register':
      return 'Registration';
    case 'passwordForgotten':
      return 'ForgetPassword';
  }
};

export const mapProfileModalToPiwikContentPiece = (
  profileModal: ProfileModals,
): PiwikContentPiece | undefined => {
  switch (profileModal) {
    case 'profile':
      return 'ManageAccount';
    case 'change_password':
      return 'EditPassword';
    case 'delete_account':
      return 'DeleteAccount';
  }
};

export const setCustomDimensionValues = (
  dimensions: CustomDimensionsOptions[],
  pathname: string,
  projectCount: number | null = null,
) => {
  dimensions.forEach((dimension) => {
    switch (dimension) {
      case CustomDimensionsOptions.Version: {
        const { user } = useAuthenticationStore.getState();
        CustomDimensions.setCustomDimensionValue(dimension, user.role);
        break;
      }
      case CustomDimensionsOptions.Favorites: {
        const { user } = useAuthenticationStore.getState();

        if (user.role !== 'free') {
          const { favorites } = useFavoriteStore.getState();
          CustomDimensions.setCustomDimensionValue(
            dimension,
            favorites.length.toString(),
          );
        }
        break;
      }
      case CustomDimensionsOptions.InspirationImage: {
        const inspirationId = pathname.replace('/inspirations/', '');
        CustomDimensions.setCustomDimensionValue(dimension, inspirationId);
        break;
      }
      case CustomDimensionsOptions.DesignOrigin: {
        const { designOrigin } = usePiwikStore.getState();
        if (designOrigin != null) {
          CustomDimensions.setCustomDimensionValue(dimension, designOrigin);
        }
        break;
      }
      case CustomDimensionsOptions.Design: {
        const { design } = usePiwikStore.getState();
        if (design != null) {
          CustomDimensions.setCustomDimensionValue(dimension, design);
        }
        break;
      }
      case CustomDimensionsOptions.DesignName: {
        const { designName } = usePiwikStore.getState();
        if (designName != null) {
          CustomDimensions.setCustomDimensionValue(dimension, designName);
        }
        break;
      }
      case CustomDimensionsOptions.Projects:
        if (projectCount != null) {
          CustomDimensions.setCustomDimensionValue(
            dimension,
            projectCount.toString(),
          );
        }
        break;
    }
  });
};

export const cleanUpCustomDimensionValues = (
  dimensions: CustomDimensionsOptions[],
) => {
  dimensions.forEach((dimension) => {
    CustomDimensions.deleteCustomDimension(dimension.toString());
  });
};

export const parseContentPiece = (input: PiwikContentPiece) => {
  if (isRoomSource(input)) {
    return 'RoomSource' + input.roomSourceIndex.toString();
  } else {
    return input;
  }
};

export const parseContentTarget = (input: PiwikContentTarget) => {
  if (isUriTarget(input)) {
    return input.uri;
  } else if (isRoomSource(input)) {
    return 'RoomSource' + input.roomSourceIndex.toString();
  } else {
    return input;
  }
};
