import { Container } from '@chakra-ui/react';

import { HtmlContentPage } from '$/pages/HtmlContentPage';
import { CookieDeclaration } from '$/pages/PrivacyPage/components/CookieDeclaration';
import { legalEndpoints } from '$/services/endpoints';

export const PrivacyPage = () => {
  return (
    <>
      <HtmlContentPage contentEndpoint={legalEndpoints.PRIVACY_URL} />
      <Container
        layerStyle='DashboardSection'
        maxW='container.lg'
        my='10'
        p='8'
        fontSize='sm'
        id='cookie-declaration'
      >
        <CookieDeclaration />
      </Container>
    </>
  );
};
