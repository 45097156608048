import debounce from 'lodash/debounce';
import { DependencyList, useMemo, useState } from 'react';

export const useDebouncedState = <T>(
  defaultValue: T,
  callback: (val: T) => void,
  delay = 100,
  deps?: DependencyList,
) => {
  const [value, setValue] = useState<T>(defaultValue);

  const debouncedChangeHandler = useMemo(
    () => debounce((val: T) => callback(val), delay),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...(deps ?? []), delay],
  );

  const changeHandler = (val: T) => {
    setValue(val);
    debouncedChangeHandler(val);
  };

  return [value, changeHandler] as const;
};
