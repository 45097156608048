import { Input, FormLabel, Flex, Box } from '@chakra-ui/react';
import { Dispatch, SetStateAction, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  selectedFile: File | null;
  setSelectedFile: Dispatch<SetStateAction<File | null>>;
}

export const FileInput: FC<Props> = ({ selectedFile, setSelectedFile }) => {
  const { t } = useTranslation();

  const handleFileChange = (file: File | undefined) => {
    if (!file) return;

    const allowedExtensions = ['jpg', 'jpeg', 'png'];
    const extension = file.name.split('.').pop()?.toLowerCase();

    if (!extension || !allowedExtensions.includes(extension)) {
      // TODO: errorhandling
      return;
    }

    setSelectedFile(file);
  };

  return (
    <FormLabel w='full'>
      <Input
        display='none'
        w='full'
        p='0'
        accept='.jpg,.jpeg,.png'
        id='file_input'
        onChange={(e) => handleFileChange(e.target.files?.[0])}
        placeholder={t('editor.choose_picture')}
        type='file'
      />
      <Flex
        align='center'
        justify='space-between'
        w='full'
        p='2'
        bg='bodyBackground'
        border={selectedFile == null ? '1px solid' : '2px solid'}
        borderColor={selectedFile == null ? 'border' : 'success.500'}
        borderRadius='4px'
      >
        <Box as='span' overflowX='auto' mr='2'>
          {selectedFile?.name ?? t('editor.choose_picture')}
        </Box>

        <Icon icon='upload_file' />
      </Flex>
    </FormLabel>
  );
};
