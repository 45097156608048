import { HStack, Spacer, Switch, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

interface Props {
  onChange: (isVisible: boolean) => void;
  currentState: boolean;
}

export const VisibilityToggle: FC<Props> = ({ currentState, onChange }) => {
  const { t } = useTranslation();

  return (
    <HStack as='button' w='full' mt='4'>
      <Icon icon='visibility_on' boxSize='4' />
      <Text fontWeight='bold' onClick={() => onChange(!currentState)}>
        {t('editor.visibility')}
      </Text>
      <Spacer />
      <Switch
        isChecked={currentState}
        onChange={() => {
          onChange(!currentState);
        }}
      />
    </HStack>
  );
};
