import { Box } from '@chakra-ui/react';
import { ComponentProps, FC } from 'react';

interface Props {
  bg: ComponentProps<typeof Box>['bg'];
  borderColor?: ComponentProps<typeof Box>['borderColor'];
}

export const VisualizationBox: FC<Props> = ({ bg, borderColor = 'border' }) => {
  return (
    <Box
      as='button'
      layerStyle='VisualizationBox'
      bg={bg}
      borderColor={borderColor}
    />
  );
};
