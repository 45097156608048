import { Box, GridItem, GridItemProps, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props extends GridItemProps {
  children: ReactNode;
  active: boolean;
  label?: string;
}

export const PreviewCard: FC<Props> = ({
  children,
  active,
  label,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <GridItem gap='2' display='grid' cursor='pointer' {...rest}>
      <Box
        p='2px'
        border='2px'
        borderColor={active ? 'text' : 'transparent'}
        borderRadius='6px'
      >
        {children}
      </Box>

      <Text color='lighterText' fontSize='sm'>
        {label ?? t('dashboard.collection.preview')}
      </Text>
    </GridItem>
  );
};
