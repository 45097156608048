import { Box, TabPanel } from '@chakra-ui/react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { ComponentType, FC, PropsWithChildren, ReactNode } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { useIntersectionObserver } from '$/hooks/useIntersectionObserver';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { Material } from '$/services/usecases/materials';
import { infiniteMaterialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  ItemWrapper: ComponentType<PropsWithChildren>;
  item: (material: Material, index: number) => ReactNode;
  LoadingElement: ComponentType<{ index: number }>;
}

export const ColorGridPanel: FC<Props> = ({
  ItemWrapper,
  item,
  LoadingElement,
}) => {
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();

  const activeComponent = useInspirationEditorStore((state) =>
    state.getActiveComponent(),
  );

  const { data, isLoading, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(
      infiniteMaterialsQuery({
        limit: 100,
        filter,
        brightnessFilter,
        type: activeComponent?.materialTypes ?? [],
      }),
    );

  const materials = data?.pages.flatMap((page) => page.materials);

  const observer = useIntersectionObserver(
    (intersecting) => intersecting && !isFetchingNextPage && fetchNextPage(),
    { disabled: !data || isFetchingNextPage || materials?.length === 0 },
  );

  return (
    <TabPanel px='0'>
      <ItemWrapper>
        {materials?.map((material, i) => (
          <Box key={material.id}>
            {item(material, i)}
            <Show when={i === materials.length - 8}>
              <Box ref={observer} />
            </Show>
          </Box>
        ))}
        <Show when={!data || isLoading || isFetchingNextPage}>
          {repeat(50).map((i) => (
            <LoadingElement key={i} index={i} />
          ))}
        </Show>
      </ItemWrapper>
    </TabPanel>
  );
};
