import { createRoute } from '@tanstack/react-router';

import {
  authLoader,
  routeAuthGuard,
} from '$/components/core/Authentication/utils/authGuard';
import { ProfilePage } from '$/pages/Profile';
import { ProfileRouteLayout } from '$/routes/Layouts/ProfileRouteLayout';

export const ProfileRoute = createRoute({
  getParentRoute: () => ProfileRouteLayout,
  path: '/profile',
  component: ProfilePage,
  beforeLoad: routeAuthGuard,
  loader: authLoader,
});
