import {
  Center,
  FormLabel,
  Image,
  Input,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { TrackedSection } from '$/components/common/TrackedSection';
import { useToast } from '$/hooks/useToast';
import { ProfileRoute } from '$/routes/Account/ProfileRoute';
import { uploadProfilePicture } from '$/services/usecases/profile';
import { getInitials } from '$/utils/stringUtils';

const allowedExtensions = ['jpg', 'jpeg', 'png'];

export const ProfileImage = () => {
  const { t } = useTranslation();

  const toast = useToast();
  const user = ProfileRoute.useLoaderData();

  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const onImageChange = async (file: File | undefined) => {
    if (!file) return;

    const extension = file.name.split('.').pop()?.toLowerCase();
    if (!extension || !allowedExtensions.includes(extension)) {
      return;
    }

    try {
      setLoading(true);
      await uploadProfilePicture(file);
      setProfileImage(URL.createObjectURL(file));
      toast(
        t('dashboard.authentication.profile.upload.successTitle'),
        'success',
        t('dashboard.authentication.profile.upload.successDescription'),
      );
    } catch (_) {
      toast(
        t('dashboard.authentication.profile.upload.errorTitle'),
        'error',
        t('dashboard.authentication.profile.upload.errorDescription'),
      );
    }

    setLoading(false);
  };

  return (
    <FormLabel pos='relative' cursor='pointer' htmlFor='file'>
      <TrackedSection
        contentInteraction='ClickButton'
        contentName='CloudAccount'
        contentPiece='ManageAccount'
        contentTarget='Picture'
      >
        <Input
          display='none'
          accept={allowedExtensions.map((ext) => `.${ext}`).join(',')}
          id='file'
          onChange={(e) => onImageChange(e.target.files?.[0])}
          type='file'
        />
      </TrackedSection>
      <Icon icon='edit_pen' position='absolute' top='6px' right='6px' />
      {profileImage && !loading && (
        <Image w='120px' h='120px' borderRadius='4px' src={profileImage} />
      )}
      {!profileImage && !loading && (
        <Center w='120px' h='120px' bg='activeBackground' borderRadius='4px'>
          <Text color='ultralightText' fontSize='40px' fontWeight='bold'>
            {getInitials(user.data.name)}
          </Text>
        </Center>
      )}
      {loading && <Skeleton w='120px' h='120px' borderRadius='4px' />}
    </FormLabel>
  );
};
