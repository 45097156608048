import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ActionCard } from '$/components/core/Projects/components/Cards/ActionCard';

export const NewProjectCard = () => {
  const { t } = useTranslation();

  return (
    <Link to='/editor'>
      <ActionCard
        icon={<Icon icon='add_sign' h='8' w='8' mb='3' />}
        headerText={t('dashboard.welcome.newProjectButton')}
        background='bodyBackground'
        color='text'
      />
    </Link>
  );
};
