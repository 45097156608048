import { trackContentInteraction } from '$/logger';
import {
  PiwikContentName,
  PiwikContentPiece,
  PiwikContentTarget,
} from '$/utils/piwikUtils';

export function useTrackInteraction(
  contentName?: PiwikContentName,
  contentPiece?: PiwikContentPiece,
  contentTarget?: PiwikContentTarget,
) {
  return () => {
    if (contentName && contentPiece && contentTarget) {
      trackContentInteraction(
        'ClickButton',
        contentName,
        contentPiece,
        contentTarget,
      );
    }
  };
}
