import { dashboardLayerStyles } from '$/theme/layers/dashboard';
import { editorLayerStyles } from '$/theme/layers/editor';
import { inspirationLayerStyles } from '$/theme/layers/inspiration';

export const layerStyles = {
  ...inspirationLayerStyles,
  ...editorLayerStyles,
  ...dashboardLayerStyles,
  ActionIconButton: {
    height: '30px',
    minWidth: '30px',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    border: '1px solid',
    borderRadius: '12px',
    borderColor: 'border',
  },
  floatCardButton: {
    pos: 'absolute',
    top: '10px',
    right: '10px',
    width: '1.75rem',
    minWidth: '0',
    height: '1.75rem',
    bg: 'background',
    border: '1px solid',
    borderColor: 'border',
    borderRadius: '8px',
  },
};
