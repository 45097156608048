import { QueryClient } from '@tanstack/react-query';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { changeLanguage } from 'i18next';
import { z } from 'zod';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { trackPageView } from '$/logger';
import { LoginPage } from '$/pages/Authentication/pages/LoginPage';
import { NotFoundPage } from '$/pages/NotFoundPage';
import { projectsQuery } from '$/services/usecases/projects/queries';
import { usePiwikStore } from '$/stores/usePiwikStore';

interface RouterContext {
  client: QueryClient;
}

const globalSearchSchema = z.object({
  keyMode: z.boolean().optional(),
});

export const RootRoute = createRootRouteWithContext<RouterContext>()({
  notFoundComponent: NotFoundPage,
  beforeLoad: async ({ location, context: { client }, search }) => {
    const { user } = useAuthenticationStore.getState();
    let projectCount: number | null = null;

    if (search.keyMode != null) {
      localStorage.setItem('translationKeyMode', search.keyMode.toString());
    }

    const isTranslationKeyMode =
      search.keyMode ?? localStorage.getItem('translationKeyMode') === 'true';

    await changeLanguage(isTranslationKeyMode ? 'cimode' : 'de');

    if (user.role !== 'free') {
      const data = await client.ensureQueryData(projectsQuery);
      projectCount = data.projectCount ?? 0;
    }

    trackPageView(location.pathname, projectCount);
    usePiwikStore.setState({
      design: null,
      designName: null,
      designOrigin: null,
    });

    const expirationTime =
      user.authExpirationDate != null
        ? new Date(user.authExpirationDate).getTime()
        : 0;
    if (expirationTime < new Date().getTime()) {
      useAuthenticationStore.setState({
        user: { role: 'free', authExpirationDate: null, data: null },
      });
    }
  },
  component: () => {
    const isAuthenticated = localStorage.getItem('isAuthenticated');
    const username = import.meta.env.VITE_LOGIN_USER;
    const password = import.meta.env.VITE_LOGIN_PASSWORD;

    const loginEnabled = username != null && password != null;

    if (loginEnabled && !isAuthenticated) {
      return <LoginPage />;
    }

    return <Outlet />;
  },
  pendingComponent: () => null,
  validateSearch: globalSearchSchema,
});
