import { Box, Flex, FormLabel, MenuItem, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { LoadingCheckbox } from '$/components/common/LoadingCheckbox';

interface Props {
  id: string;
  name: string;
  isChecked: boolean;
  onChange: (id: string) => void;
  isLoading?: boolean;
  amount?: number;
  color?: string;
}

export const MultiSelectionMenuItem: FC<Props> = ({
  id,
  name,
  isChecked,
  onChange,
  color,
  amount,
  isLoading = false,
}) => {
  return (
    <MenuItem display='flex' w='full' minW='full' py='1.5'>
      <FormLabel
        justifyContent='space-between'
        display='flex'
        w='full'
        minW='full'
        m='0'
        cursor='pointer'
      >
        <Flex align='center' gap='2'>
          {color && (
            <Box
              w='32px'
              h='20px'
              bg={color}
              borderWidth='1px'
              borderColor={id.includes('white') ? 'border' : 'transparent'}
              borderRadius='2px'
            />
          )}
          <Text fontSize='sm'>{name}</Text>
        </Flex>
        <Flex align='center' gap='2'>
          {amount && (
            <Text color='lighterText' fontSize='sm'>
              {amount}
            </Text>
          )}
          <LoadingCheckbox
            isLoading={isLoading}
            isChecked={isChecked}
            isDisabled={isLoading}
            onChange={() => onChange(id)}
          />
        </Flex>
      </FormLabel>
    </MenuItem>
  );
};
