import {
  SimpleGrid,
  Stack,
  StackProps,
  Tab,
  TabList,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ColorGridPanel } from '$/components/core/Editor/ColorSelectionSidebar/ColorGridPanel';
import { AttributeHeader } from '$/pages/InspirationEditorPage/components/AttributeSection/components/AttributeHeader';
import { LoadingColorBox } from '$/pages/InspirationEditorPage/components/AttributeSection/components/LoadingColorBox';
import { MaterialListItem } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItem';
import { MaterialListItemSkeleton } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItemSkeleton';
import { ColorBox } from '$/pages/InspirationEditorPage/components/ColorBox';
import { Material } from '$/services/usecases/materials';

interface Props extends StackProps {
  material: Material | null | undefined;
  onColorChange: (material: Material) => void;
}

export const ColorGrid: FC<Props> = ({ material, onColorChange, ...props }) => {
  const { t } = useTranslation();

  const activeMaterialId = material?.id;

  return (
    <Stack w='full' h='25rem' {...props}>
      <AttributeHeader
        label={t('inspirationEditor.attributes.colorPresentation')}
        description='?'
        icon={<Icon icon='inspiration_marker' w='14px' />}
      />
      <Tabs overflow='auto' variant='unstyled'>
        <TabList gap='2'>
          <Tab layerStyle='ColorListTabIcon'>
            <Icon icon='grid' />
          </Tab>
          <Tab layerStyle='ColorListTabIcon'>
            <Icon icon='fan' />
          </Tab>
          <Tab layerStyle='ColorListTabIcon'>
            <Icon icon='list' />
          </Tab>
        </TabList>
        <TabPanels>
          <ColorGridPanel
            item={(material: Material) => (
              <ColorBox
                onClick={() => onColorChange(material)}
                materialColor={material.color}
                w='full'
                h={undefined}
                image={material.url()}
                aspectRatio='1/1'
                border='1px solid'
                borderColor={
                  material.id === activeMaterialId ? 'text' : 'border'
                }
              />
            )}
            ItemWrapper={({ children }) => (
              <SimpleGrid gap='2' overflowY='auto' columns={6}>
                {children}
              </SimpleGrid>
            )}
            LoadingElement={() => <LoadingColorBox />}
          />
          <ColorGridPanel
            item={(material: Material, index: number) => (
              <ColorBox
                onClick={() => onColorChange(material)}
                materialColor={material.color}
                image={material.url()}
                w='full'
                h={undefined}
                aspectRatio='1/1'
                borderRightRadius={index % 6 === 5 ? '4px' : '0'}
                borderLeftRadius={index % 6 === 0 ? '4px' : '0'}
                border='1px solid'
                borderRight={
                  index % 6 === 5 || material.id === activeMaterialId
                    ? 'auto'
                    : 'none'
                }
                borderColor={
                  material.id === activeMaterialId ? 'text' : 'border'
                }
              />
            )}
            ItemWrapper={({ children }) => (
              <SimpleGrid rowGap='2' overflowY='auto' columns={6}>
                {children}
              </SimpleGrid>
            )}
            LoadingElement={() => <LoadingColorBox />}
          />
          <ColorGridPanel
            item={(material: Material, index: number) => (
              <MaterialListItem
                id={index}
                material={material}
                showAsActive={material.id === activeMaterialId}
                onClick={() => onColorChange(material)}
              />
            )}
            ItemWrapper={({ children }) => <Stack>{children}</Stack>}
            LoadingElement={({ index }) => (
              <MaterialListItemSkeleton id={index} />
            )}
          />
        </TabPanels>
      </Tabs>
    </Stack>
  );
};
