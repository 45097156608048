import { VStack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ProfileModals } from '$/pages/Profile';
import { ProfileActionSection } from '$/pages/Profile/pages/ProfileModal/components/ProfileActionSection';
import { ProfileFormSection } from '$/pages/Profile/pages/ProfileModal/components/ProfileFormSection';
import { ProfileHeader } from '$/pages/Profile/pages/ProfileModal/components/ProfileHeader';
import { ProfileImage } from '$/pages/Profile/pages/ProfileModal/components/ProfileImage';

interface Props {
  onViewChange: (view: ProfileModals) => void;
}

export const ProfileModal: FC<Props> = ({ onViewChange }) => {
  const { t } = useTranslation();

  return (
    <>
      <ProfileHeader />

      <VStack align='flex-start'>
        <Text>{t('dashboard.authentication.profile.profilePicture')}</Text>
        <ProfileImage />
      </VStack>

      <ProfileFormSection />

      <ProfileActionSection onViewChange={onViewChange} />
    </>
  );
};
