import { t } from 'i18next';
import { FC, RefObject, useRef } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { AdditionalFilter } from '$/components/core/Collection/CollectionFilter/components/AdditionalFilter';
import { BrightnessFilter } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter';
import { FilterWrapper } from '$/components/core/Collection/CollectionFilter/components/FilterWrapper';
import { MultiFilter } from '$/components/core/Collection/CollectionFilter/components/MultiFilter';
import { SingleFilter } from '$/components/core/Collection/CollectionFilter/components/SingleFilter';
import { FilterDropdown } from '$/components/core/Collection/CollectionFilter/FilterDropdown';
import { FilterSkeleton } from '$/components/core/Collection/CollectionFilter/FilterSkeleton';
import { FilterType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
  type: FilterType;
  hideAmounts?: boolean;
}

export const DesktopFilterMenu: FC<Props> = ({
  sectionRef,
  type,
  hideAmounts = false,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollRefs = [wrapperRef, sectionRef];
  const possibleFilter = useCollectionStore.useFilter();

  return (
    <FilterWrapper ref={wrapperRef}>
      <Show
        fallback={() =>
          repeat(3).map((index) => (
            <FilterSkeleton key={index} title='Hersteller' />
          ))
        }
        when={possibleFilter}
      >
        {(filter) => {
          return (
            <>
              {filter.general.map((filterGroup) => {
                return (
                  <FilterDropdown
                    key={filterGroup.searchName}
                    title={filterGroup.displayName}
                    scrollRefs={scrollRefs}
                  >
                    {(onMenuClose) => {
                      if (filterGroup.selectionFilter === 'multi') {
                        return (
                          <MultiFilter
                            onMenuClose={onMenuClose}
                            type={filterGroup.searchName}
                            activeOptions={filterGroup.items}
                            hideAmounts={hideAmounts}
                          />
                        );
                      }

                      if (filterGroup.selectionFilter === 'single') {
                        return (
                          <SingleFilter
                            onMenuClose={onMenuClose}
                            options={filterGroup.items}
                            hideAmounts={hideAmounts}
                          />
                        );
                      }
                    }}
                  </FilterDropdown>
                );
              })}

              {filter.additional.length > 0 && (
                <FilterDropdown
                  title={t('dashboard.collection.filter.additional')}
                  scrollRefs={scrollRefs}
                >
                  {(onMenuClose) => (
                    <AdditionalFilter
                      onMenuClose={onMenuClose}
                      additionalFilter={filter.additional}
                    />
                  )}
                </FilterDropdown>
              )}

              <Show when={type === 'uni'}>
                {() => (
                  <FilterDropdown
                    title={t('dashboard.collection.filter.brightness')}
                    scrollRefs={scrollRefs}
                  >
                    {(onMenuClose) => (
                      <BrightnessFilter onMenuClose={onMenuClose} />
                    )}
                  </FilterDropdown>
                )}
              </Show>
            </>
          );
        }}
      </Show>
    </FilterWrapper>
  );
};
