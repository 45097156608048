import { createRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { MessagePage } from '$/pages/MessagePage';
import { RootRoute } from '$/routes/RootRoute';

const Component = () => {
  const { t } = useTranslation();

  return (
    <MessagePage
      headerText={t('dashboard.authentication.resetPassword.landingPageHeader')}
      icon='leaderboard'
      descriptionText={t(
        'dashboard.authentication.resetPassword.landingPageDescription',
      )}
      buttonText={t('general.backHomeShort')}
    />
  );
};

export const PasswordResetSuccessfulRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/password-reset-successful',
  component: Component,
});
