import { HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { ActionBar } from '$/components/core/Editor/ActionBar';
import { EditorZoomAction } from '$/components/core/Editor/ActionBar/components/EditorZoomAction';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';

interface Props {
  groups: EditorActionGroup[];
}

export const ActionBarSection: FC<Props> = ({ groups }) => {
  return (
    <HStack as='section' layerStyle='FloatingActionBar' top='0'>
      <ActionBar groups={groups}>
        <EditorZoomAction />
      </ActionBar>
    </HStack>
  );
};
