import { useBreakpointValue } from '@chakra-ui/react';

export const useMaterialGridMaxItems = () => {
  const value = useBreakpointValue(
    {
      base: 2,
      sm: 2,
      md: 3,
      lg: 4,
      xl: 5,
      '2xl': 6,
    },
    { ssr: false },
  );

  return value ?? 4;
};
