import {
  Box,
  Button,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileInput } from '$/pages/EditorPage/components/LoadModal/FileInput';
import { SupportedFormats } from '$/pages/EditorPage/components/LoadModal/SupportedFormats';
import { uploadProjectImage } from '$/services/usecases/editor';

export const LoadModal = () => {
  const { t } = useTranslation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const navigate = useNavigate();

  const goBack = () => {
    onClose();
    void navigate({ from: '.', to: '../' });
  };

  const onHandleUpload = async () => {
    const token = await uploadProjectImage(selectedFile);

    if (token != null) {
      void navigate({
        to: '/editor/$id',
        params: { id: token },
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody w='32rem' h='46rem' bg='background' borderRadius='12px'>
          <VStack
            justifyContent='left'
            w='full'
            h='full'
            m='4'
            pr='6'
            bg='background'
          >
            <Heading
              as='h3'
              w='full'
              mt='3'
              mb='10'
              fontSize='2xl'
              fontWeight='bold'
              textAlign='left'
            >
              {t('editor.new_project')}
            </Heading>
            <Box w='full' mb='3'>
              {t('editor.modal_text')}
            </Box>
            <SupportedFormats />
            <HStack justifyContent='space-between' w='full'>
              <Box w='full' fontSize='normal' fontWeight='bold'>
                {t('editor.upload_picture')}
              </Box>
              <Box
                display={selectedFile == null ? 'none' : 'unset'}
                pr='1'
                color='#99CC33'
              >
                {t('editor.valid_file')}
              </Box>
            </HStack>
            <FileInput
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
            />

            {t('editor.new_project')}
            <HStack justifyContent='space-between' w='full' mt='8'>
              <Button onClick={goBack} variant='text'>
                {t('editor.cancel')}
              </Button>
              <Button
                px='16'
                bg={selectedFile == null ? 'border' : 'editor.btnShareBg'}
                isDisabled={selectedFile == null}
                onClick={() => onHandleUpload()}
              >
                {t('editor.upload')}
              </Button>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
