import { VStack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { MaterialListItem } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItem';
import { materialsQuery } from '$/services/usecases/materials/queries';

export const CurrentColorSection = () => {
  const { t } = useTranslation();
  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();

  const activeComponent =
    activeComponentId == null ? null : components[activeComponentId];

  const { data } = useQuery(
    materialsQuery({
      ids: activeComponent?.materialId
        ? [activeComponent?.materialId]
        : undefined,
    }),
  );

  if (activeComponent?.materialId == null || data?.materials.length === 0)
    return;

  const material = data?.materials.length === 1 ? data.materials[0] : null;

  return (
    <>
      <VStack align='flex-start' gap='3' w='full' mt='2' mb='3' px='4'>
        <FilterHeader
          header={t('inspirationEditor.attributes.usedColor')}
          isActive={true}
          prefix={<Icon icon='inspiration_marker' w='14px' />}
        />
        <MaterialListItem id={1} material={material} />;
      </VStack>
      <SidebarDivider />
    </>
  );
};
