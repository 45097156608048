import { Input, Stack } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { useToast } from '$/hooks/useToast';
import { signOut } from '$/services/usecases/authentication';
import { deleteAccount } from '$/services/usecases/profile';

export const ConfirmationForm = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const toast = useToast();

  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);
      await deleteAccount();
      signOut();

      toast(
        t('dashboard.authentication.deleteAccount.successTitle'),
        'success',
        t('dashboard.authentication.deleteAccount.successDescription'),
      );
      await navigate({ from: '.', to: '/' });
    } catch (_) {
      toast(
        t('dashboard.authentication.deleteAccount.errorTitle'),
        'error',
        t('dashboard.authentication.deleteAccount.errorDescription'),
      );
    }

    setLoading(false);
  };

  const isValid =
    input.trim().toLowerCase() ===
    t('dashboard.authentication.deleteAccount.confirmation').toLowerCase();

  return (
    <Stack align='center' gap='2.5rem' w='full'>
      <Input
        onChange={(e) => setInput(e.target.value)}
        placeholder={t(
          'dashboard.authentication.deleteAccount.inputTextPlaceholder',
        )}
      />
      <TrackedButton
        w='fit-content'
        mt='0.5rem'
        isDisabled={!isValid || loading}
        isLoading={loading}
        onClick={onSubmit}
        contentName='CloudAccount'
        contentPiece='DeleteAccount'
        contentTarget='Delete'
      >
        {t('dashboard.authentication.deleteAccount.action')}
      </TrackedButton>
    </Stack>
  );
};
