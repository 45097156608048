import { object, z } from 'zod';

import {
  GeneralAuthResponse,
  parseErrorResult,
} from '$/services/mapper/authentication';

export const GenericCloudResponseSchema = object(GeneralAuthResponse).transform(
  (data) => {
    return parseErrorResult(data, () => {});
  },
);

export type RegistrationResponse = z.infer<typeof GenericCloudResponseSchema>;
