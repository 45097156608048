import { Divider, Stack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { ColorLayer } from '$/pages/InspirationEditorPage/components/LayerSection/components/ColorLayer';
import { LoadingColorLayer } from '$/pages/InspirationEditorPage/components/LayerSection/components/LoadingColorLayer';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { repeat } from '$/utils/arrayUtils';

export const Layers = () => {
  const { t } = useTranslation();

  const variant = useInspirationEditorStore((state) =>
    state.getActiveSceneVariant(),
  );

  return (
    <>
      <Text px='3' fontWeight='bold'>
        {t('inspirationEditor.layer')}
      </Text>

      <Divider />

      <Stack gap='0' overflowY='auto' w='full' maxH='full'>
        <For
          each={variant}
          fallback={() =>
            repeat(5).map((id: number) => (
              <LoadingColorLayer layerId={id} key={id} px='5' py='2' />
            ))
          }
        >
          {(layer) => <ColorLayer key={layer.id} layer={layer} px='5' py='2' />}
        </For>
      </Stack>
    </>
  );
};
