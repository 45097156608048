export const getContrastYIQ = (hexcolor: string) => {
  if (hexcolor === 'transparent') return 'black';
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
};

export const getColorBrightness = (hex: string) => {
  const [r, g, b] = hexToRgb(hex === 'transparent' ? '#ffffff' : hex);
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const hexToRgb = (hex: string) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b] as const;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  const componentToHex = (component: number) => {
    const hex = component.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
  };

  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};
