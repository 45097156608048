import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { AvailableIcons, Icon } from '$/components/common/Icon';
import { FilterGroup } from '$/services/usecases/filter';

interface Props {
  filterSearchName: string;
  filterGroup: FilterGroup | undefined;
  onSingleFilterSelected: (filterSearchName: string) => void;
  placeholder?: string;
  filterIcon?: AvailableIcons;
}

export const MobileFilterSingle: FC<Props> = ({
  filterSearchName,
  filterGroup,
  onSingleFilterSelected,
  placeholder,
  filterIcon = 'arrow_right',
}) => {
  return (
    <HStack
      justify='space-between'
      my='2'
      px='4'
      py='2'
      bg='background'
      border='1px solid'
      borderColor='border'
      borderRadius='8px'
      cursor='pointer'
      onClick={() => onSingleFilterSelected(filterSearchName)}
    >
      <Text
        color={
          filterGroup?.displayName
            ? 'textButton.color'
            : 'textButton.disabledColor'
        }
        fontSize='sm'
        fontWeight='bold'
      >
        {filterGroup?.displayName ?? placeholder}
      </Text>
      <Icon flex='flex-end' w='12px' icon={filterIcon} />
    </HStack>
  );
};
