import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

export type State = {
  query: string;
  dropdownVisible: boolean;
};

type Actions = {
  setQuery: (query: string) => void;
  toggleDropdown: (visible: boolean) => void;
};

export const initial: State = {
  query: '',
  dropdownVisible: false,
};

const store = create<State & Actions>()((set) => ({
  ...initial,
  setQuery: (query) => set({ query }),
  toggleDropdown: (dropdownVisible) => set({ dropdownVisible }),
}));

export const useMaterialSearchStore = createSelectorHooks(store);
