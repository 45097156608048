import { Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { FilterGroup } from '$/services/usecases/filter';

interface Props {
  isExpanded: boolean;
  activeFilter: FilterGroup[];
}

export const ActiveFilterText: FC<Props> = ({ isExpanded, activeFilter }) => {
  return (
    <Show when={!isExpanded && activeFilter.length > 0}>
      <Text mb='2' ml='5' fontSize='sm'>
        {activeFilter.map((i) => i.displayName).join(', ')}
      </Text>
    </Show>
  );
};
