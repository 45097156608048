import { createRoute } from '@tanstack/react-router';

import { PrivacyPage } from '$/pages/PrivacyPage';
import { RootRoute } from '$/routes/RootRoute';

export const PrivacyRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/privacy',
  component: PrivacyPage,
});
