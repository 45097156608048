import { Box } from '@chakra-ui/react';

import { BrushSidebar } from '$/pages/EditorPage/components/AttributesSection/components/BrushSidebar';
import { ColorSelectionSidebar } from '$/pages/EditorPage/components/AttributesSection/components/ColorSelectionSidebar';
import { LayerSidebar } from '$/pages/EditorPage/components/AttributesSection/components/LayerSidebar';
import { MagicWandSidebar } from '$/pages/EditorPage/components/AttributesSection/components/MagicWandSidebar';
import { RubberSidebar } from '$/pages/EditorPage/components/AttributesSection/components/RubberSidebar';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

export const AttributeSidebarContent = () => {
  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const activeLayerId = useEditorStore.useActiveLayerIndex();

  const workMode = useEditorStore.useWorkMode();

  if (workMode === 'brush') {
    return <BrushSidebar />;
  }

  if (workMode === 'rubber') {
    return <RubberSidebar />;
  }

  if (workMode === 'magicWand') {
    return <MagicWandSidebar />;
  }

  if (activeComponentId == null) {
    return <Box />;
  }

  if (activeLayerId != null) return <LayerSidebar />;

  return <ColorSelectionSidebar />;
};
