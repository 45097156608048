import { array, boolean, number, object, string, z } from 'zod';

const WorkModeOptionsEnum = z.enum([
  'addRect',
  'createeditpoly',
  'addEllipse',
  'select',
  'brush',
  'rubber',
  'grid',
  'image',
  'stencil',
  'magicWand',
]);
export type WorkMode = z.infer<typeof WorkModeOptionsEnum>;

const ZoomTypeOptionsEnum = z.enum([
  'zoomPercent',
  'zoomInnerFit',
  'zoomWorkspace',
  'zoomSelection',
]);

export type ZoomType = z.infer<typeof ZoomTypeOptionsEnum>;

const BrushTypeOptionsEnum = z.enum(['round', 'square']);
export type BrushType = z.infer<typeof BrushTypeOptionsEnum>;

const ObjectModeOptionsEnum = z.enum(['poly', 'bitmap']);
export type ObjectMode = z.infer<typeof ObjectModeOptionsEnum>;

export const EditorStatusSchema = object({
  action: string(),
  data: object({
    active: object({
      elementIdx: number().nullable().optional(),
      layerIdx: number().nullable().optional(),
    }),
    workMode: WorkModeOptionsEnum.optional(),
    zoomType: ZoomTypeOptionsEnum,
    zoomPercent: number(),
    brushsize: number().default(1),
    rubbersize: number().default(1),
    brushtype: BrushTypeOptionsEnum,
    rubbertype: BrushTypeOptionsEnum,
    limitToMask: boolean(),
    colorchecker: boolean(),
    objects: array(
      object({
        name: string(),
        height: number(),
        width: number(),
        material: object({
          matId: string(),
          unique: string(),
        })
          .nullable()
          .optional(),
        layer: array(
          object({
            type: string(),
            name: string(),
          }),
        ),
        grid: object({
          width: number(),
          height: number(),
        }),
        autoBrightness: number(),
        brightness: number(),
        contrast: number(),
        mode: ObjectModeOptionsEnum.optional().nullable(),
      }),
    ),
  }),
}).transform((status) => ({
  activeComponentIndex: status.data.active.elementIdx,
  activeLayerIndex: status.data.active.layerIdx,
  workMode: status.data.workMode ?? 'select',
  zoomType: status.data.zoomType,
  zoomPercent: status.data.zoomPercent,
  brushSize: status.data.brushsize,
  brushType: status.data.brushtype,
  rubberSize: status.data.rubbersize,
  rubberType: status.data.rubbertype,
  limitToMask: status.data.limitToMask,
  colorCheckerEnabled: status.data.colorchecker,
  components: status.data.objects.map((object, i) => ({
    grid: object.grid,
    height: object.height,
    width: object.width,
    layers: object.layer.map((layer, i) => ({
      type: layer.type,
      name: layer.name,
      index: i,
    })),
    name: object.name,
    mode: object.mode,
    materialId: object.material?.matId ?? null,
    index: i,
    autoBrightness: object.autoBrightness,
    brightness: object.brightness,
    contrast: object.contrast,
  })),
}));

export type EditorModuleStatus = z.infer<typeof EditorStatusSchema>;

export type EditorComponent = EditorModuleStatus['components'][0];

export type ComponentLayer = EditorComponent['layers'][0];
