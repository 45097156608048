import { Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';

export const LoginForFavoriteToastContent = () => {
  const { t } = useTranslation();
  const onOpenAuthModal = useAuthenticationStore.useOnModalOpen();

  return (
    <>
      <Text fontSize='sm' wordBreak='break-word'>
        {t('favorites.loginForFavorites')}
      </Text>
      <Button
        p='0'
        color='primaryButton.background'
        fontSize='sm'
        fontWeight='bold'
        onClick={() => onOpenAuthModal('login')}
        variant='text'
      >
        {t('dashboard.authentication.registerNow')}
      </Button>
    </>
  );
};
