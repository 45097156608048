import { AccordionPanel, Flex, FormLabel, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { LoadingCheckbox } from '$/components/common/LoadingCheckbox';
import { FilterGroup, FilterGroupOption } from '$/services/usecases/filter';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filter: FilterGroupOption;
  loadingItem: string;
  onToggle: (group: FilterGroup) => Promise<void>;
}

export const AccordionContent: FC<Props> = ({
  filter,
  loadingItem,
  onToggle,
}) => {
  const isFilterActive = useCollectionStore.useIsFilterActive();

  return (
    <AccordionPanel px='0'>
      {filter.items.map(({ displayName, intName, searchName, amount }) => (
        <FormLabel
          key={intName}
          alignItems='center'
          justifyContent='space-between'
          display='flex'
          m='0'
          px='6'
          py='2'
          borderRadius='sm'
          _hover={{ backgroundColor: 'bodyBackground' }}
          cursor='pointer'
        >
          <Text fontSize='sm'>{displayName}</Text>
          <Flex align='center' gap='2'>
            {amount && (
              <Text color='lighterText' fontSize='sm'>
                {amount}
              </Text>
            )}
            <LoadingCheckbox
              borderColor='text'
              isChecked={isFilterActive(searchName, intName)}
              isLoading={loadingItem === intName}
              onChange={() =>
                onToggle({ option: intName, type: searchName, displayName })
              }
            />
          </Flex>
        </FormLabel>
      ))}
    </AccordionPanel>
  );
};
