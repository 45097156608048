import {
  infiniteQueryOptions,
  keepPreviousData,
  queryOptions,
} from '@tanstack/react-query';
import { create } from 'zustand';

import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { MaterialType } from '$/services/mapper/uses';
import { BrightnessFilter, FilterGroup } from '$/services/usecases/filter';
import { Material, getMaterials } from '$/services/usecases/materials';
import { repeat } from '$/utils/arrayUtils';

export interface MaterialQueryPayload {
  ids?: string[];
  query?: string;
  limit?: number;
  isPaginated?: boolean;
  filter?: FilterGroup[];
  brightnessFilter?: BrightnessFilter;
  type?: MaterialType[];
}

interface MaterialCache {
  materials: Map<string, Material>;
}

const useMaterialCache = create<MaterialCache>()(() => ({
  materials: new Map(),
}));

const cacheMaterials = (mats: Material[]) => {
  const newMaterials = new Map(useMaterialCache.getState().materials);
  mats.forEach((material) => newMaterials.set(material.id, material));
  useMaterialCache.setState({ materials: newMaterials });
};

const cacheMaterialsByUniqueKey = (mats: Material[]) => {
  const newMaterials = new Map(useMaterialCache.getState().materials);
  mats.forEach((material) => newMaterials.set(material.uniqueKey, material));
  useMaterialCache.setState({ materials: newMaterials });
};

const queryFn = async (
  payload: MaterialQueryPayload,
  signal: AbortSignal,
  page: number,
  useUniqueKeys: boolean = false,
) => {
  const { ids, query, limit, filter, brightnessFilter, type } = payload;

  const fetchAllMaterials = async () => {
    const data = await getMaterials(
      {
        limit,
        query,
        filter,
        signal,
        page,
        type,
      },
      useUniqueKeys,
    );

    let materials = data.materials;

    if (
      brightnessFilter != null &&
      (brightnessFilter.from !== 0 || brightnessFilter.to !== 100)
    ) {
      materials = materials.filter(
        (material) =>
          (material.brightness >= brightnessFilter.from &&
            material.brightness <= brightnessFilter.to) ||
          material.brightness === -1,
      );
    }

    cacheMaterials(materials);
    return { ...data, materials };
  };

  const fetchMaterialsFromIds = async () => {
    const missingIds = ids!.filter(
      (id) => useMaterialCache.getState().materials.get(id) == null,
    );

    if (missingIds?.length !== 0) {
      const data = await getMaterials(
        {
          limit,
          signal,
          ids: missingIds,
          filter,
        },
        useUniqueKeys,
      );

      if (useUniqueKeys) {
        cacheMaterialsByUniqueKey(data.materials);
      } else {
        cacheMaterials(data.materials);
      }
    }

    const cachedMaterials = ids!
      .map((id) => useMaterialCache.getState().materials.get(id)!)
      .filter((material) => material != null);

    return {
      materialCount: cachedMaterials.length,
      materials: cachedMaterials,
    };
  };

  const { materials, materialCount } = ids
    ? await fetchMaterialsFromIds()
    : await fetchAllMaterials();

  return {
    materialCount,
    materials: materials.sort(
      (item1, item2) =>
        item1.position.page - item2.position.page ||
        item1.position.pagePosition - item2.position.pagePosition,
    ),
    unfilteredMaterials: materials,
  };
};

const localFilteringQueryFn = async (
  payload: MaterialQueryPayload,
  signal: AbortSignal,
  page: number,
) => {
  const { filter, brightnessFilter, limit, ids, query, type } = payload;
  // with all those ids the url query grows a lot and chrome has a limit of 2048 digits, so we introduce batching here
  const batchSize = 50;
  const batchAmount = Math.ceil((ids?.length ?? 0) / batchSize);

  const materialBatches = await Promise.all(
    repeat(batchAmount).map(async (_, index) => {
      const { materials } = await queryFn(
        { ids: ids?.slice(index * batchSize, (index + 1) * batchSize) },
        signal,
        page,
        true,
      );
      return materials;
    }),
  );

  const allMaterials = materialBatches.flat();

  const emptyFilterOptions = {
    producers: [],
    collections: [],
    colors: [],
  };

  const filteredMaterials = allMaterials.filter((material) => {
    const { collections, colors, producers } =
      filter?.reduce<{
        producers: string[];
        collections: string[];
        colors: string[];
      }>((acc, current) => {
        if (current.type === 'producer') acc.producers.push(current.option);
        if (current.type === 'collection') acc.collections.push(current.option);
        if (current.type === 'color') acc.colors.push(current.option);

        return acc;
      }, emptyFilterOptions) ?? emptyFilterOptions;

    const matchingUses =
      type == null ||
      material.type.some((materialType) => type?.includes(materialType));
    const matchingQuery =
      query == null ||
      material.info.toLowerCase().includes(query.toLowerCase());
    const matchingBrightness =
      brightnessFilter == null ||
      brightnessFilter.from === 0 ||
      brightnessFilter.to === 100 ||
      (material.brightness >= brightnessFilter.from &&
        material.brightness <= brightnessFilter.to);
    const matchingProducer =
      producers.length === 0 || producers.includes(material.rawProducer);
    const matchingCollection =
      collections.length === 0 || collections.includes(material.rawCollection);
    const matchingColor =
      colors.length === 0 ||
      colors.some((color) => material.rawColor.includes(color));

    return (
      matchingBrightness &&
      matchingCollection &&
      matchingProducer &&
      matchingColor &&
      matchingQuery &&
      matchingUses
    );
  });

  return {
    materialCount: filteredMaterials.length,
    unfilteredMaterials: allMaterials,
    materials: filteredMaterials
      .slice(0, limit)
      .sort(
        (item1, item2) =>
          item1.position.page - item2.position.page ||
          item1.position.pagePosition - item2.position.pagePosition,
      ),
  };
};

export const infiniteMaterialsQuery = (payload: MaterialQueryPayload = {}) =>
  infiniteQueryOptions({
    queryKey: ['materials', payload],
    queryFn: async ({ signal, pageParam: page }) =>
      queryFn(payload, signal, page),
    initialPageParam: 0,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      if (lastPage.materialCount / payload.limit! < lastPageParam + 1)
        return undefined;
      return lastPageParam + 1;
    },
    staleTime: STANDARD_STALE_TIME,
  });

export const materialsQuery = (
  payload: MaterialQueryPayload = {},
  keepPrevious: boolean = false,
) =>
  queryOptions({
    queryKey: ['materials', payload],
    queryFn: ({ signal }) => queryFn(payload, signal, 0),
    refetchOnWindowFocus: false,
    staleTime: STANDARD_STALE_TIME,
    placeholderData: keepPrevious ? keepPreviousData : undefined,
  });

export const localFilteredMaterialsQuery = (
  payload: MaterialQueryPayload = {},
) =>
  queryOptions({
    queryKey: ['local-materials', payload],
    queryFn: ({ signal }) => localFilteringQueryFn(payload, signal, 0),
    placeholderData: keepPreviousData,
    staleTime: STANDARD_STALE_TIME,
  });
