import { Button, Center, Heading, VStack } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Center h='100vh'>
      <VStack
        layerStyle='DashboardSection'
        gap='2.5rem'
        maxW='31rem'
        p='2.5rem'
      >
        <Heading fontSize='xl' textAlign='center'>
          {t('error.pageNotFoundHeader')}
        </Heading>
        <Link to='..' replace>
          <Button variant='primary'>{t('error.pageNotAllowedButton')}</Button>
        </Link>
      </VStack>
    </Center>
  );
};
