import { Heading, Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';

interface Props {
  onReturn: () => void;
}

export const ChangePasswordHeader: FC<Props> = ({ onReturn }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <TrackedButton
        justifyContent='flex-start'
        gap='2'
        px='0'
        fontSize='sm'
        leftIcon={
          <Icon icon='arrow_right' w='14px' transform='rotate(180deg)' />
        }
        onClick={onReturn}
        variant='text'
        contentName='CloudAccount'
        contentPiece='EditPassword'
        contentTarget='Close'
      >
        {t('dashboard.authentication.profile.backToProfile')}
      </TrackedButton>
      <Heading mb='0.5rem' fontSize='3xl' textAlign='center'>
        {t('dashboard.authentication.changePassword.action')}
      </Heading>
    </Stack>
  );
};
