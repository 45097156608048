import { HStack, StackProps, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { InfoTooltip } from '$/components/common/InfoTooltip';

interface Props extends StackProps {
  icon: ReactNode;
  label: string;
  description?: string;
}

export const AttributeHeader: FC<Props> = ({
  icon,
  label,
  description = '',
  ...props
}) => {
  return (
    <HStack align='center' justify='space-between' {...props}>
      <HStack>
        {icon}

        <Text color='lighterText' fontSize='sm' fontWeight='bold'>
          {label}
        </Text>
      </HStack>

      <Show when={description}>
        <InfoTooltip
          info={description}
          icon={<Icon icon='info' color='lighterText' cursor='help' w='12px' />}
        />
      </Show>
    </HStack>
  );
};
