import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

interface Props extends BoxProps {
  boxColor?: string | null;
  image?: string;
}

export const ColorBox: FC<Props> = ({ boxColor, image, ...props }) => {
  return (
    <Box
      bg={boxColor ?? 'transparent'}
      bgImage={image}
      bgSize='cover'
      border='1px'
      borderColor='border'
      aspectRatio='3/2'
      {...props}
    />
  );
};
