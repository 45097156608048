import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bg: 'bodyBackground',
    border: '1px solid',
    borderColor: 'border',
    _checked: {
      bg: 'primaryButton.background',
    },
  },
});

export const Switch = defineMultiStyleConfig({ baseStyle });
