import { Box, HStack, VStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { SliderInput } from '$/pages/EditorPage/components/AttributesSection/components/BrushSizeInput';
import { CurrentColorSection } from '$/pages/EditorPage/components/AttributesSection/components/CurrentColorSection';
import { MagicWandModeOptionButton } from '$/pages/EditorPage/components/AttributesSection/components/MagicWandModeOptionButton';
import { SidebarDivider } from '$/pages/EditorPage/components/AttributesSection/components/SidebarDivider';
import {
  Tip,
  TipSection,
} from '$/pages/EditorPage/components/AttributesSection/components/TipSection';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import { VisibilityToggle } from '$/pages/EditorPage/components/AttributesSection/components/VisibilityToggle';
import { VisualizationSection } from '$/pages/EditorPage/components/AttributesSection/components/VisualizationSection';

export const MagicWandSidebar = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const tips: Tip[] = [
    {
      tipHeader: t('editor.tips.magicWandUseCase.title'),
      tipContent: t('editor.tips.magicWandUseCase.content'),
    },
    {
      tipHeader: t('editor.tips.magicWandQuickHelp.title'),
      tipContent: t('editor.tips.magicWandQuickHelp.content'),
    },
  ];

  return (
    <VStack w='full' fontSize='sm'>
      <TitleSection title={t('editor.magicWand.title')} />
      <SidebarDivider />
      <CurrentColorSection />
      <VisualizationSection />
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <HStack>
          <Icon boxSize='4' icon='magic_wand' />
          <Text fontWeight='bold'>{t('editor.magicWand.mode')}</Text>
        </HStack>
        <HStack my='2'>
          <MagicWandModeOptionButton mode='new' />
          <MagicWandModeOptionButton mode='add' />
          <MagicWandModeOptionButton mode='subtract' />
        </HStack>
      </VStack>
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <Text fontWeight='bold'>{t('editor.magicWand.tolerance')}</Text>
        <SliderInput
          initialValue={42}
          minValue={0}
          maxValue={255}
          onChange={(val) => console.log(val)}
        />
      </VStack>
      <SidebarDivider />
      <VStack align='flex-start' w='full' px='4'>
        <HStack>
          <Icon boxSize='4' icon='magic_wand' />
          <Text fontWeight='bold'>{t('editor.magicWand.size')}</Text>
        </HStack>
        <SliderInput
          initialValue={42}
          minValue={0}
          maxValue={100}
          onChange={(val) => console.log(val)}
        />
      </VStack>
      <SidebarDivider />
      <Box mx='4' mt='4'>
        <TipSection tips={tips} />
        <VisibilityToggle currentState={isVisible} onChange={setIsVisible} />
      </Box>
    </VStack>
  );
};
