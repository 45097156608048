import { Image as ChakraImage } from '@chakra-ui/react';
import { FC } from 'react';

import { StyledLink } from '$/components/core/StyledLink';
import { useBlurryImagePreloader } from '$/hooks/useBlurryImagePreloader';
import { popularityToImageSize } from '$/pages/DashboardPages/pages/Inspiration/utils/popularityUtils';
import { endpointFactory } from '$/services/endpoints';

interface Props {
  id: string;
  name: string;
  popularity: number;
  isDisabled?: boolean;
}

export const SceneImage: FC<Props> = ({
  id,
  name,
  popularity,
  isDisabled = false,
}) => {
  const imageSize = popularityToImageSize(popularity, id);
  const props = useBlurryImagePreloader(
    endpointFactory.sceneImageUrl({ id, height: '32', width: '32' }),
    endpointFactory.sceneImageUrl({ id }),
  );

  return (
    <StyledLink
      link={{ to: '/inspirations/$id', params: { id }, disabled: isDisabled }}
      maxW='100%'
      borderRadius='12px'
      {...imageSize}
    >
      <ChakraImage
        layerStyle='SceneImage'
        w='100%'
        h='100%'
        textColor='transparent'
        _hover={
          !isDisabled
            ? { transform: 'scale(1.02)', transition: 'transform 300ms ease' }
            : {}
        }
        cursor={isDisabled ? 'not-allowed' : 'pointer'}
        alt={name}
        loading='lazy'
        {...props}
      />
    </StyledLink>
  );
};
