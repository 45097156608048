import { HStack, Stack, VStack, Text, Box, Button } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/pages/InspirationEditorPage/components/AttributeSection/components/AttributeHeader';
import {
  DEFAULTFOLDERNAME,
  FavoriteFolder,
  useFavoriteStore,
} from '$/stores/useFavoriteStore';

const defaultShowLimit = 5;

export const FavoriteFolderList = () => {
  const { t } = useTranslation();
  const folders = useFavoriteStore.useFavoriteFolders();
  const favorites = useFavoriteStore.useFavorites();
  const setActiveFolderId = useFavoriteStore.useSetActiveFolderId();
  const [showAll, setShowAll] = useState(false);

  const defaultFolder: FavoriteFolder = {
    id: DEFAULTFOLDERNAME,
    name: t('favorites.generalStorage'),
  };

  const folderItems = [defaultFolder, ...folders].map((folder) => {
    const itemsInFolder = favorites.filter((favorite) =>
      favorite.parentFolderIds.includes(folder.id),
    );

    return { ...folder, favoriteCount: itemsInFolder.length };
  });

  const shownFolders = showAll
    ? folderItems
    : folderItems.slice(0, defaultShowLimit);

  return (
    <Stack w='full' mb='4'>
      <Box px='5'>
        <AttributeHeader
          label={t('inspirationEditor.attributes.favorites')}
          icon={<Icon w='14px' icon='like' />}
          description='?'
        />
      </Box>

      {shownFolders.map((item) => (
        <HStack
          key={item.id}
          as='button'
          px='5'
          py='1'
          _hover={{ bg: 'bodyBackground' }}
          onClick={() => setActiveFolderId(item.id)}
        >
          <Icon
            icon='folder'
            bg='bodyBackground'
            borderRadius='2px'
            boxSize='24px'
            p='1'
          />
          <VStack alignItems='flex-start' gap='0' fontSize='sm'>
            <Text align='left' lineHeight='4' noOfLines={1}>
              {item.name}
            </Text>
            <Text color='textButton.color' lineHeight='4'>
              {t('inspirationEditor.folderFavoriteCount', {
                count: item.favoriteCount,
              })}
            </Text>
          </VStack>
        </HStack>
      ))}
      <Show when={folderItems.length > defaultShowLimit}>
        <Button
          h='2rem'
          mx='5'
          mt='2'
          fontSize='sm'
          fontWeight='normal'
          onClick={() => setShowAll((prev) => !prev)}
          variant='secondary'
        >
          {showAll
            ? t('dashboard.collection.showLess')
            : t('dashboard.collection.showMore')}
        </Button>
      </Show>
    </Stack>
  );
};
