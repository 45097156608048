import { Divider, Stack } from '@chakra-ui/react';
import { FC } from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { AvailableIcons, Icon } from '$/components/common/Icon';
import { AttributeHeader } from '$/pages/InspirationEditorPage/components/AttributeSection/components/AttributeHeader';
import { MaterialListItem } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItem';
import { MaterialListItemSkeleton } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItemSkeleton';
import { Layer } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { repeat } from '$/utils/arrayUtils';
import { unsecureHashCode, unsecureSeededIntRange } from '$/utils/numberUtils';

interface Props {
  items: Layer[];
  label: string;
  description: string;
  icon: AvailableIcons;
  isLoading: boolean;
}

export const SceneDetailSection: FC<Props> = ({
  items,
  label,
  description,
  icon,
  isLoading,
}) => {
  return (
    <Show when={items.length > 0 || isLoading}>
      <>
        <AttributeHeader
          icon={<Icon icon={icon} w='14px' />}
          label={label}
          description={description}
          px='5'
        />

        <Stack gap='3' w='full' px='5'>
          <For
            each={items}
            empty={() => null}
            isLoading={isLoading}
            fallback={() =>
              repeat(unsecureSeededIntRange(unsecureHashCode(label), 1, 3)).map(
                (id) => <MaterialListItemSkeleton key={id} id={id} />,
              )
            }
          >
            {({ material }, i) => (
              <MaterialListItem id={i} material={material} />
            )}
          </For>
        </Stack>

        <Divider pt='4' />
      </>
    </Show>
  );
};
