import { Box, BoxProps } from '@chakra-ui/react';
import { MotionProps, motion } from 'framer-motion';
import { FC, ReactNode } from 'react';

const item: MotionProps = {
  variants: {
    closed: { x: -16, opacity: 0 },
    open: { x: 0, opacity: 1 },
  },
  transition: { opacity: { duration: 0.2 } },
};

interface Props extends BoxProps {
  children: ReactNode;
}

export const DropdownItem: FC<Props> = ({ children, ...rest }) => {
  return (
    <Box {...rest}>
      <motion.div {...item}>{children}</motion.div>
    </Box>
  );
};
