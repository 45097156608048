import {
  Box,
  Button,
  Stack,
  StackDivider,
  VStack,
  Text,
  HStack,
  DrawerBody,
  DrawerHeader,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BrightnessFilterMenuItem } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessFilterMenuItem';
import { FilterActions } from '$/components/core/Collection/CollectionFilter/components/FilterActions';
import { MobileFilter } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilter';
import { FilterSkeleton } from '$/components/core/Collection/CollectionFilter/FilterSkeleton';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { TooltipLabel } from '$/pages/DashboardPages/components/TooltipLabel';
import { FilterType } from '$/services/mapper/uses';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  type: FilterType;
  closeFilterDrawer: () => void;
  setSubFilter: (filterSearchName: string) => void;
  amount: number | null;
}

export const MobileMainFilterPage: FC<Props> = ({
  type,
  closeFilterDrawer,
  setSubFilter,
  amount,
}) => {
  const { t } = useTranslation();
  const possibleFilter = useCollectionStore.useFilter();
  const filterGroups = useCollectionStore.useFilterGroups();
  const resetFilters = useCollectionStore.useResetFilter();
  const setBrightnessFilter = useCollectionStore.useSetBrightnessFilter();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const [brightness, setBrightness] = useState([
    brightnessFilter.from,
    brightnessFilter.to,
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeFilterDrawer(),
  });

  const getFilterPlaceholder = (filterSearchName: string) => {
    switch (filterSearchName) {
      case 'producer':
        return t('inspirationEditor.filter.producerDefault');
      case 'collection':
        return t('inspirationEditor.filter.collectionDefault');
      default:
        return undefined;
    }
  };

  const isAnyFilterActive =
    filterGroups.length > 0 ||
    brightnessFilter.from !== 0 ||
    brightnessFilter.to !== 100;

  const onResetFilter = async () => {
    setBrightness([0, 100]);
    await resetFilters();
  };

  const filters = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ].filter((filter) => filter.searchName !== 'collection');

  return (
    <Box>
      <DrawerHeader {...swipeEvents}>
        <HStack alignItems='baseline' justify='space-between'>
          <Text fontSize='md'>
            {t('dashboard.collection.filter.mobile.filterHeader')}
          </Text>
          <Button
            fontSize='sm'
            onClick={() => closeFilterDrawer()}
            variant='text'
          >
            {t('dashboard.collection.filter.mobile.cancelButton')}
          </Button>
        </HStack>
      </DrawerHeader>

      <DrawerBody>
        <VStack
          align='flex-start'
          divider={<StackDivider mt='1' borderColor='borderColor' />}
          spacing={4}
        >
          {filters.map((filter) => (
            <Box key={filter.searchName} w='full'>
              <Text fontSize='sm' fontWeight='bold'>
                {filter.displayName}
              </Text>
              {!isLoading && (
                <MobileFilter
                  filterSearchName={filter.searchName}
                  onSingleFilterSelected={setSubFilter}
                  setIsLoading={setIsLoading}
                  placeholder={getFilterPlaceholder(filter.searchName)}
                />
              )}
              {isLoading && <FilterSkeleton title={filter.displayName} />}
            </Box>
          ))}
          {type === 'uni' && (
            <Box w='full'>
              <Text fontWeight='bold'>
                {t('dashboard.collection.filter.brightness')}
              </Text>
              <BrightnessFilterMenuItem
                value={brightness}
                setValue={setBrightness}
              />
              <Stack gap='4' p='3'>
                <TooltipLabel
                  label={t('dashboard.collection.whatIsBrightness')}
                  info={t('dashboard.collection.brightnessExplanation')}
                />
                <FilterActions
                  anyFilterActive={brightness[0] !== 0 || brightness[1] !== 100}
                  activeChanges={
                    brightness[0] !== brightnessFilter.from ||
                    brightness[1] !== brightnessFilter.to
                  }
                  onSubmit={() => {
                    setIsLoading(true);
                    setBrightnessFilter({
                      from: brightness[0],
                      to: brightness[1],
                    });
                    setIsLoading(false);
                  }}
                  onReset={() => {
                    setBrightness([0, 100]);
                    setBrightnessFilter({ from: 0, to: 100 });
                  }}
                />
              </Stack>
            </Box>
          )}
          <HStack justify='space-between' gap='4' w='100%'>
            <Button
              h='32px'
              fontSize='sm'
              isDisabled={!isAnyFilterActive}
              isLoading={isLoading}
              onClick={onResetFilter}
              variant='text'
            >
              {t('dashboard.collection.resetFilter')}
            </Button>
            <Button
              px='6'
              py='3'
              fontSize='sm'
              isLoading={isLoading}
              onClick={closeFilterDrawer}
            >
              {t('dashboard.collection.resultsAmount.showAmount', {
                amount,
              })}
            </Button>
          </HStack>
        </VStack>
      </DrawerBody>
    </Box>
  );
};
