import { queryOptions } from '@tanstack/react-query';

import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { getAllProjects } from '$/services/usecases/projects';

export const projectsQuery = queryOptions({
  queryKey: ['projects'],
  queryFn: ({ signal }) => getAllProjects(signal),
  staleTime: STANDARD_STALE_TIME,
});
