import { createRoute } from '@tanstack/react-router';
import { Suspense, lazy } from 'react';

import { RootRoute } from '$/routes/RootRoute';

const ApiDocsPage = lazy(() =>
  import('$/pages/Documentation/pages/ApiDocsPage').then((res) => ({
    default: res.ApiDocsPage,
  })),
);

export const ApiDocumentationRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/docs/api',
  component: () => (
    <Suspense>
      <ApiDocsPage />
    </Suspense>
  ),
});
