export const de = {
  translation: {
    api: { cnf: 'spectrum-all' },
    brand: {
      spectrum: 'Spectrum',
    },
    general: {
      backHome: 'Zurück zur Startseite',
      backHomeShort: 'Zur Startseite',
      backToOverview: 'Zurück zur Übersicht',
      backToTop: 'Zurück nach oben',
      back: 'Zurück',
      additional: 'Weiteres',
    },
    roles: {
      free: 'Kostenlos',
      community: 'Community',
      standard: 'Standard',
      pro: 'Pro',
    },
    dashboard: {
      header: {
        createAccount: 'Account erstellen',
        subscriptions: 'Abomodelle',
        signIn: 'Anmelden',
        contact: 'Kontakt',
        languages: 'Sprachen',
        shortcuts: 'Shortcuts',
        termsOfCondition: 'AGBs...',
        theme: 'Theme',
        toStart: 'Zur Übersicht',
        manageAccount: 'Konto verwalten',
        signOut: 'Abmelden',
        openMenu: 'Menü öffnen',
        legal: 'Rechtliches',
        help: 'Hilfe & Support',
        imprint: 'Impressum',
        privacy: 'Datenschutz',
        terms: 'AGBs',
      },
      navbar: {
        welcomeLink: 'Willkommen',
        inspirationLink: 'Inspiration',
        collectionsLink: 'Kollektionen',
        projectsLink: 'Projekte',
        favoritesLink: 'Favoriten',
      },
      welcome: {
        yourSpaceText: 'Dein Bereich',
        newProjectButton: 'Neues Projekt',
        ownProjectPic: 'Projekte/Eigenes Bild',
        ownProjectCount:
          'Projekte/Eigenes Bild ({{userProjectCount}}/{{projectCountLimit}})',
        showAllButton: 'Alle anzeigen',
        inspirationText: 'Zuletzt verwendete Inspirationen',
        collectionsText: 'Kollektionen',
        createAccount: 'Account erstellen',
        createOwnProjects: 'um eigene Projekte zu erstellen',
        createFavorites: 'um Favoriten zu verwenden',
        createFurtherProject: 'um weitere Projekte zu erstellen',
        lastUsedCount:
          'Zuletzt verwendet ({{userProjectCount}}/{{projectCountLimit}})',
        lastUsed: 'Zuletzt verwendet',
        upgrade: 'Upgraden',
        collection: {
          colorTones: 'Farbtöne',
          wallCoverings: 'Wandbeläge',
          floorCoverings: 'Bodenbeläge',
          structures: 'Putze & Strukturen',
        },
      },
      inspiration: {
        header: 'Lass dich inspirieren',
        description:
          'Durchstöbere all unsere Inspirationen oder filtere nach Kategorien.',
        chosenColor: 'Ausgewählter Farbton:',
      },
      collection: {
        colorTones: 'Farbtöne',
        wallCoverings: 'Wandbeläge',
        floorCoverings: 'Bodenbeläge',
        exploreColors: 'Farbtöne entdecken',
        exploreWallCoverings: 'Wandbeläge entdecken',
        exploreFloorCoverings: 'Bodenbeläge entdecken',
        exploreCollections: 'Kollektionen entdecken',
        description:
          'Ob Farbgruppe, Kollektionshersteller oder traditionell nach dem Kollektionsnamen, hier wirst du fündig.',
        searchColors: 'In Kollektionen suchen',
        preview: 'Vorschau',
        colorize: 'Fläche einfärben',
        matchingMaterials: 'Passende Materialien',
        applyForInspiration: 'Für Inspirationen anwenden',
        whatIsBrightness: 'Was ist der Hellbezugswert?',
        brightnessExplanation:
          'Der sogenannte Hellbezugswert (HBW) misst die Helligkeit eines Farbtons und gibt an, welche Energiemenge im Bereich des sichtbaren Lichts von der Fassade reflektiert wird: Der Wert für Schwarz entspricht 0, der für Weiß 100.',
        show: 'Alle anzeigen',
        showMore: 'Mehr anzeigen',
        showLess: 'Weniger anzeigen',
        resetFilter: 'Alle Filter zurücksetzen',
        resultsAmount: {
          withFilter: '{{amount}} Ergebnisse für',
          withoutFilter: '{{amount}} Ergebnisse',
          showAmount: '{{amount}} anzeigen',
        },
        brightnessChip: 'Hellbezugswert {{from}} - {{to}}',
        filter: {
          producer: 'Hersteller',
          collections: 'Kollektionen',
          color: 'Farbgruppe',
          brightness: 'Hellbezugswert',
          productGroup: 'Produktgruppe',
          colorIntensity: 'Farbintensität',
          design: 'Design',
          floorSubstance: 'Material',
          commercialUse: 'Einsatzbereich',
          additional: 'Weitere Filter',
          mobile: {
            cancelButton: 'Abbrechen',
            filterButton: 'Filter',
            filterHeader: 'Filter',
            searchButton: 'Suche',
          },
        },
        sorting: 'Sortieren',
        search: {
          colorTones: 'Farbtöne',
          noColorTones: 'Keine Farbtöne',
          wallCoverings: 'Wandbeläge',
          noWallCoverings: 'Keine Wandbeläge',
          floorCoverings: 'Bodenbeläge',
          noFloorCoverings: 'Keine Bodenbeläge',
        },
      },
      projects: {
        header: 'Deine Projekte',
      },
      authentication: {
        firstNameLabel: 'Vorname',
        lastNameLabel: 'Nachname',
        emailLabel: 'E-Mail Adresse',
        passwordLabel: 'Passwort',
        insertPasswordLabel: 'Passwort vergeben',
        repeatPasswordLabel: 'Passwort wiederholen',
        loginButton: 'Anmelden',
        createAccountButton: 'Account erstellen',
        createAccountSuccessfulTitle: 'Erfolgreich!',
        createAccountSuccessfulDescription:
          'Super nice, eine E-Mail ist unterwegs zu dir, bitte bestätigen und so weiter und so fort. Wir freuen uns auf dich!',
        forgotPassword: 'Passwort vergessen?',
        createAccountDescription:
          'Schön, dass du dir einen Account erstellen willst! Damit wirst du viele neue Funktionen haben, unter anderem: Dies Das Dies und das',
        forgotPasswordDescripton:
          'Gib deine E-Mail Adresse ein, um dein altes Passwort zurückzusetzen. Du erhälst im Anschluss eine E-Mail, mit der du ein neues Passwort festlegen kannst.',
        forgotPasswordSuccessMessage:
          'Eine Mail mit den Anweisungen zum zurücksetzen des Passwords wurde an {{email}} gesendet',
        createAccountLabel:
          'Indem du auf "Account erstellen" klickst, stimmst du den <TermsLink>Nutzungsbedingungen</TermsLink> und unserer <PrivacyLink>Datenschutzrichtlinie</PrivacyLink> zu.',
        signInSuccessfulTitle: 'Erfolgreich!',
        signInSuccessfulDescription:
          'Du bist jetzt angemeldet und kannst loslegen!',
        registerNow: 'Jetzt anmelden',
        mailSent: 'Email wurde versandt',
        errorMessages: {
          registrationFailed: 'Registrierung fehlgeschlagen',
          isRequired: 'Eingabe notwendig',
          wrongFormat: 'Falsches Format',
          passwordMismatch: 'Passwörter stimmen nicht überein',
          passwordRulesMismatch: 'Passwort nicht sicher genug',
          minimumCharacters: 'Mindestens {{count}} Zeichen',
          highAndLowCase: 'Groß- und Kleinbuchstaben enthalten',
          minimumSpecialCharacter: 'Min. 1 Sonderzeichen',
          minimumNumber: 'Min. 1 Zahl',
          emailRules: 'Beispiel: xxx@yyy.zz',
          passwordRules:
            'Länge min. 10 Zeichen, Groß- und Kleinbuchstaben, min. 1 Sonderzeichen, min. 1 Zahl',
          '-1': 'Ein unerwarteter Fehler ist aufgetreten, bitte versuche es später erneut',
          '1001':
            'Ein unerwarteter Fehler ist aufgetreten, bitte versuche es später erneut',
          '1002': 'Das Passwort ist falsch oder der Nutzer existiert nicht',
          '1003': 'Die Anmeldung war unvollständig',
          '1004': 'Das Passwort erfüllt nicht die Mindestanforderungen',
          '1005': 'Die Emailadresse enthält unzulässige Zeichen',
          '1006': 'Der Benutzer existiert bereits',
          '1007': 'Der Nutzer ist bereits aktiv',
          '1008':
            'Ein unerwarteter Fehler ist aufgetreten, bitte versuche es später erneut',
          '1009': 'Der Nutzer wurde nicht gefunden',
          '1010': 'Der Nutzer hat keine Ordner',
          '1011': 'Der Nutzer hat keine Projekte',
          '1012': 'Das Projekt konnte nicht gelöscht werden',
          '1013': 'Nur ein Teil des Projekts konnte gelöscht werden',
          '1014': 'Keine Projekte des Nutzers gefunden',
          '1015': 'Das Projekt existiert bereits',
        },
        defaultPlaceholder: '{{field}} eingeben...',
        profile: {
          backToDashboard: 'Zurück zum Dashboard',
          backToProfile: 'Zurück zum Profil',
          yourProfile: 'Dein Account',
          profilePicture: 'Profilbild',
          changePassword: 'Passwort ändern',
          deleteAccount: 'Account löschen',
          nameLabel: 'Name',
          emailLabel: 'E-Mail Adresse',
          upload: {
            successTitle: 'Upload erfolgreich',
            successDescription:
              'Dein Profilbild wurde erfolgreich hochgeladen!',
            errorTitle: 'Upload fehlgeschlagen',
            errorDescription: 'Das Profilbild konnte nicht hochgeladen werden!',
          },
          changeData: {
            successTitle: 'Änderung erfolgreich',
            successDescription: 'Deine Daten wurden erfolgreich geändert!',
            errorTitle: 'Änderung fehlgeschlagen',
            errorDescription: 'Die Daten konnten nicht geändert werden!',
          },
        },
        deleteAccount: {
          action: 'Account löschen',
          instruction:
            'Sind Sie sicher, dass Sie Ihr Spectrum-Konto löschen möchten? Wenn Sie Ihr Konto <strong>{{email}}</strong> löschen, werden alle damit verbundenen Daten wie Ihre Dateien, Komponenten und Bilder gelöscht. <br> Sie können diesen Vorgang nicht rückgängig machen.<br><br>Weitere Informationen darüber, wie wir mit Ihren Daten umgehen, finden Sie in unserer Datenschutzerklärung. <br><br> <strong>Zur Bestätigung geben Sie bitte unten "{{confirmation}}" ein.</strong>',
          inputTextPlaceholder: 'Text eingeben...',
          confirmation: 'Mein Konto löschen',
          errorTitle: 'Löschen fehlgeschlagen',
          errorDescription:
            'Das Profil konnte nicht gelöscht werden. Bitte versuche es erneut.',
          successTitle: 'Löschen erfolgreich',
          successDescription:
            'Dein Profil wurde erfolgreich gelöscht. Schade dass du uns verlässt!',
        },
        changePassword: {
          action: 'Passwort ändern',
          currentInfo: 'Gib hier dein aktuelles Passwort ein:',
          newInfo: 'Gib hier dein neues Passwort ein:',
          currentPassword: 'Aktuelles Passwort',
          newPassword: 'Neues Passwort',
          repeatPassword: 'Neues Passwort wiederholen',
          inputPassword: 'Passwort eingeben...',
          invalidRequirements:
            'Das neue Passwort erfüllt nicht die Mindestanforderungen:',
          errorTitle: 'Passwort ändern fehlgeschlagen',
          errorDescription: 'Das Passwort konnte nicht geändert werden!',
          successTitle: 'Passwort erfolgreich geändert!',
          successDescription:
            'Dein Passwort wurde erfolgreich geändert. Kehre zur Startseite zurück und melde dich mit deinem neuen Passwort wieder an!',
        },
        resetPassword: {
          action: 'Passwort zurücksetzen',
          landingPageHeader: 'Passwort erfolgreich zurückgesetzt!',
          landingPageDescription:
            'Dein Passwort wurde erfolgreich zurückgesetzt. Kehre zur Startseite zurück und melde dich mit deinem neuen Passwort wieder an!',
        },
        registration: {
          landingPageHeader: 'Email Adresse bestätigt',
          landingPageDescription:
            'Deine Email Adresse wurde bestätigt, du kannst dich nun einloggen.',
        },
      },
    },
    favorites: {
      addFavorit: 'Favorit hinzufügen',
      addFolder: 'Ordner hinzufügen',
      addMaterialTo: 'Hinzufügen zu',
      newFolder: 'Neuer Ordner',
      insertNameLabel: 'Namen eingeben',
      defaultFolderName: 'Ordner {{ letter }}',
      deleteButton: 'Löschen',
      cancelButton: 'Abbrechen',
      deleteFolderHeader: 'Ordner löschen',
      removeFavoriteHeader: 'Favorit löschen',
      renameButton: 'Ordner umbenennen',
      duplicateFolder: 'Ordner duplizieren',
      duplicatedFolderSuffix: 'neu',
      yourFolders: 'Deine Ordner',
      saveButton: 'Speichern',
      finish: 'Fertigstellen',
      favoriteSavedToastHeader: 'Favorit erfolgreich gespeichert',
      favoriteSavedToastDescription:
        'Das Material "<strong>{{ materialName }}</strong>" wurde erfolgreich in die "<strong>Allgemeine Ablage</strong>" hinzugefügt"',
      deleteFolderInformation:
        'Durch das Löschen des Ordners werden auch alle Favoriten innerhalb des Ordners entfernt',
      pageHeader: 'Deine Favoriten',
      empty: {
        title: 'Noch keine Favoriten hinzugefügt',
        description:
          'Hier werden alle Farbtöne, Wandbeläge und Bodenbeläge gesammelt, die du als Favorit markiert hast. Du kannst beim Bearbeiten von Bildern im Editor direkt darauf zugreifen und hast somit deine Lieblingsfarben jederzeit griffbereit. \nFange jetzt direkt an, in der Kollektiosübersicht Farbtöne zu deinen Favoriten hinzuzufügen, indem du das Favoriten Icon anklickst.',
        discoverCollections: 'Kollektionen durchsuchen',
        noMatchingFavorites:
          'Keine passenden Favoriten für diese Komponente vorhanden',
      },
      generalStorage: 'Allgemeine Ablage',
      removeFavoriteInformation:
        'Bist du sicher, dass du das Material "<strong>{{ materialName }}</strong>" aus deinen Favoriten entfernen willst? \nEs ist aktuell in folgenden Ordnern enthalten:',
      removeFromFolder: 'Aus Ordner entfernen',
      moveFavorite: 'Verschieben',
      changeFolder: 'Ordner ändern',
      loginForFavorites: 'Logge dich ein, um Favoriten auszuwählen',
    },
    inspirationEditor: {
      layer: 'Ebenen',
      perspectives: 'Perspektiven',
      generatePdf: 'PDF generieren',
      perspectiveName: 'Perspektive {{number}}',
      variantName: 'Variante {{letter}}',
      showAsFullscreen: 'Als Vollbild anzeigen',
      chooseExportFormatDescription:
        'Wähle das Format aus, in dem dein Projekt exportiert werden soll.',
      chooseExportOptions:
        'Wähle die Perspektiven und Varianten aus, die exportiert werden sollen:',
      inspirations: 'Inspirationen',
      colorsUsed: 'Verwendete Farbtöne',
      structuresUsed: 'Verwendete Strukturen',
      folderFavoriteCount: '{{count}} Favoriten',
      rename: 'Umbenennen',
      delete: 'Löschen',
      pdfExport: {
        createdWith: 'Created with',
        trueToColor: 'Farbtreue: ',
        pdfFooterText:
          'Bitte beachten Sie, dass Abweichungen vom Originalfarbton in Abhängigkeit von Drucker und Papier auftreten. Wir empfehlen Ihnen eine Musterfläche anzulegen.',
      },
      attributes: {
        usedColors: 'Verwendete Farbtöne',
        usedColor: 'Verwendeter Farbton',
        usedWalls: 'Verwendete Wandbeläge',
        usedFloors: 'Verwendete Bodenbeläge',
        changeColors: 'Farbe ändern',
        colorSelection: 'Farbtonauswahl',
        colorPresentation: 'Farbtondarstellung',
        favorites: 'Favoriten',
        search: 'Suchen...',
      },
      filter: {
        fanSelection: 'Farbtonfächerauswahl',
        colorGroups: 'Farbgruppen',
        filter: 'Filter',
        producer: 'Hersteller',
        collections: 'Kollektionen',
        producerDefault: 'Herstellerübergreifend',
        collectionDefault: 'Kollektionsübergreifend',
        searchResults: '{{amount}} Suchergebnisse',
        fanPage: 'Fächerseite',
      },
      search: {
        noResults: 'Keine passenden Ergebnisse gefunden :(',
        reset: 'Suche zurücksetzen',
      },
      material: {
        producer: 'Hersteller',
        collection: 'Kollektion',
        name: 'Name',
        brightness: 'Hellbezugswert',
        rgb: 'RGB',
        lch: 'LCH',
        position: 'Fächerseite',
        family: 'Familie',
      },
    },
    editor: {
      componentListHeader: 'Ebenen/Objektliste',
      addComponentIconAlt: 'Bauteil hinzufügen',
      backToHome: 'Zurück zur Homepage',
      action: 'Aktion {{key}} auswählen',
      zoom_out: 'Rauszoomen',
      zoom_in: 'Reinzoomen',
      shareButton: 'Teilen',
      exportText: 'Exportieren',
      exportButton: 'Projekt exportieren',
      moduleTitle: 'Editor Modul',
      load_picture: 'Bild laden',
      new_project: 'Neues Projekt',
      upload_picture: 'Bild hochladen',
      choose_picture: 'Bild auswählen',
      cancel: 'Abbrechen',
      upload: 'Hochladen',
      component_name: 'Bauteil {{letter}}',
      modal_text:
        'Lade ein Bild hoch, um dein eigenes Projekt zu erstellen und deinen Raum oder dein Haus nach deinen Wünschen zu gestalten.',
      supported_formats: 'Folgende Formate werden unterstützt:',
      invalid_file:
        'Ungültiger Dateityp! Bitte eine .jpg- oder .png-Datei auswählen.',
      valid_file: 'Passt',
      share_project: 'Projekt teilen',
      share_modal_text:
        'Teile dein Projekt mit anderen Personen, damit diese deine Entwürfe sehen und bearbeiten können.',
      copy_link_here: 'Link zum Kopieren',
      invite_per_mail: 'Per E-Mail Adresse einladen',
      put_in_address: 'E-Mail Adresse eingeben...',
      invalid_mail_address: 'Ungültige E-Mail',
      compare: 'Vergleichen',
      variants: 'Varianten',
      variant: 'Variante {{letter}}',
      delete_variant: 'Variante löschen',
      brush: 'Pinsel',
      selectMode: 'Auswahlmodus',
      visualization: 'Darstellung',
      brushSize: 'Pinselgröße (px)',
      brushShape: 'Pinselform',
      rectangle: 'Rechteck',
      circle: 'Kreis',
      visibility: 'Sichtbarkeit',
      warning: 'Achtung',
      brushTool: 'Pinselwerkzeugs',
      rubber: {
        title: 'Radierer',
        tool: 'Radierwerkzeug',
        size: 'Radiergröße (px)',
        shape: 'Radierform',
      },
      magicWand: {
        title: 'Zauberstab',
        tool: 'Zauberstab',
        tolerance: 'Toleranz',
        size: 'Zauberstabgröße (px)',
        mode: 'Selektionsmodus',
      },
      newElementCreatedToastTitle: 'Neues Bauteil erstellt',
      newElementCreatedToastContent:
        'Ein neues Bauteil wurde automatisch erstellt, da der Pinsel auf keinem aktiven Bauteil verwendet wurde.',
      vectorWarning:
        'Mit dem Verwenden des {{tool}} werden alle bisher erstellten Vektorgrafiken gerastert. Diese können dann nicht mehr bearbeitet werden.',
      tips: {
        header: 'Tipps',
        brushUseCase: {
          title: 'Verwendungszweck',
          content:
            'Halte beim Verwenden des Pinselwerkzeugs “Shift” gedrückt, um eine gerade Linie zu erstellen.',
        },
        drawStraightLine: {
          title: 'Gerade Linie ziehen',
          content:
            'Halte beim Verwenden des Pinselwerkzeugs “Shift” gedrückt, um eine gerade Linie zu erstellen.',
        },
        magicWandUseCase: {
          title: 'Verwendungszweck',
          content: 'Kurze Erklärung wofür der Zauberstab da ist.',
        },
        magicWandQuickHelp: {
          title: 'Schnellhilfe',
          content:
            'Hier sollte ein Tipp über die Nutzung des Zauberstabs stehen.',
        },
      },
    },
    error: {
      pageNotFoundHeader: 'Seite nicht gefunden',
      pageNotAllowedHeader: 'Kein Zugriff',
      pageNotAllowedText:
        'Du hast nicht die notwendigen Berechtigungen, um auf diese Seite zuzugreifen.',
      pageNotAllowedButton: 'Zurück zur Übersicht',
      accountNecessary: 'Account notwendig',
      linkExpiredHeader: 'Link nicht mehr gültig',
      linkExpiredDescription:
        'Dieser Link ist älter als drei Tage und daher leider nicht mehr gültig. Um dein Passwort erneut zurückzusetzen, kehre zur Startseite zurück und lass dir einen neuen Link zusenden.',
    },
  },
};
