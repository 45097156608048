import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

export type Tip = {
  tipHeader: string;
  tipContent: string;
};

interface Props {
  tips: Tip[];
}

export const TipSection: FC<Props> = ({ tips }) => {
  const { t } = useTranslation();

  return (
    <VStack
      align='flex-start'
      gap='2'
      p='4'
      bg='absoluteColor'
      border='1px solid'
      borderColor='border'
      borderRadius='4'
    >
      <HStack>
        <Icon icon='lightbulb' boxSize='4' />
        <Text fontWeight='bold'>{t('editor.tips.header')}</Text>
      </HStack>
      {tips.map((tip) => (
        <Box key={tip.tipHeader} fontSize='xs'>
          <Text fontWeight='bold'>{tip.tipHeader}</Text>
          <Text lineHeight='standard'>{tip.tipContent}</Text>
        </Box>
      ))}
    </VStack>
  );
};
