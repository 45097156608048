import { Stack, StackProps, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

interface Props extends StackProps {
  icon: ReactNode;
  headerText: string;
  lowerText?: string;
}

export const ActionCard: FC<Props> = ({
  icon,
  headerText,
  lowerText,
  ...stackProps
}) => {
  return (
    <Stack layerStyle='WelcomeCard' {...stackProps}>
      {icon}
      <Stack align='center' gap='0'>
        <Text mb='1' fontSize='sm' fontWeight='bold'>
          {headerText}
        </Text>
        <Text maxW='70%' fontSize='sm' fontWeight='normal' lineHeight='4'>
          {lowerText}
        </Text>
      </Stack>
    </Stack>
  );
};
