import {
  Button,
  DrawerBody,
  DrawerHeader,
  Flex,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { FC } from 'react';

import { MobileDrawer } from '$/components/common/Drawer/MobileDrawer';
import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { SearchDropdownResults } from '$/components/core/Collection/MaterialSearch/components/SearchDropdownResults';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { usePiwikSearchTracking } from '$/hooks/usePiwikSearchTracking';
import { useSwipeHook } from '$/hooks/useSwipeHook';
import { mapFilterTypeToMaterialTypes } from '$/services/mapper/uses';
import { Material } from '$/services/usecases/materials';
import {
  localFilteredMaterialsQuery,
  materialsQuery,
} from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  searchLabel: string;
  idFilter?: string[];
  isDrawerOpen: boolean;
  openSearchDrawer: () => void;
  closeSearchDrawer: () => void;
  onResultClick?: (material: Material) => void;
}

export const MobileSearchDrawer: FC<Props> = ({
  searchLabel,
  idFilter,
  isDrawerOpen,
  openSearchDrawer,
  closeSearchDrawer,
  onResultClick,
}) => {
  const activeType = useCollectionStore.useActiveType();
  const query = useMaterialSearchStore.useQuery();
  const swipeEvents = useSwipeHook({
    onSwipeVertical: (direction) => direction === 'down' && closeSearchDrawer(),
  });

  const activeMaterialTypes = activeType
    ? mapFilterTypeToMaterialTypes(activeType)
    : undefined;

  const usedQuery = idFilter
    ? localFilteredMaterialsQuery({
        query,
        limit: 10,
        ids: idFilter,
        type: activeMaterialTypes,
      })
    : materialsQuery({ query, limit: 10, type: activeMaterialTypes });

  const { data, isLoading } = useQuery(usedQuery);

  usePiwikSearchTracking(data);

  return (
    <>
      <Flex justify='flex-start'>
        <IconButton
          bg='background'
          borderWidth='1px'
          borderColor='border'
          aria-label={t('dashboard.collection.filter.mobile.filterButton')}
          icon={<Icon icon='search' w='16px' />}
          onClick={openSearchDrawer}
          variant='text'
        />
      </Flex>
      <MobileDrawer isOpen={isDrawerOpen} onClose={closeSearchDrawer}>
        <>
          <DrawerHeader {...swipeEvents}>
            <HStack gap='4'>
              <HStack h='48px'>
                <SearchInput searchLabel={searchLabel} />
              </HStack>
              <Button fontSize='sm' onClick={closeSearchDrawer} variant='text'>
                {t('dashboard.collection.filter.mobile.cancelButton')}
              </Button>
            </HStack>
          </DrawerHeader>

          <DrawerBody overflowY='auto'>
            <Show when={query.length >= 3}>
              <SearchDropdownResults
                materials={data?.materials}
                isLoading={isLoading}
                onResultClick={onResultClick}
                isMobile={true}
              />
            </Show>
          </DrawerBody>
        </>
      </MobileDrawer>
    </>
  );
};
