import { cloudEndpointFactory } from '$/services/endpoints/cloudEndpoints';
import { fetcher } from '$/services/fetcher';
import {
  AllProjectsSchema,
  Project,
} from '$/services/usecases/projects/mapper/allProjects';

const dummyProjects: Project[] = [
  {
    id: 'RPBK4K',
    name: 'Projekt 1 Wohnzimmer',
  },
  {
    id: 'ZT89UN',
    name: 'Projekt 2 Wohnzimmer',
  },
  {
    id: 'NPPXS8',
    name: 'Projekt 3 Wohnzimmer',
  },
  {
    id: '126',
    name: 'Projekt 4 Wohnzimmer',
  },
  {
    id: '127',
    name: 'Projekt 5 Wohnzimmer',
  },
  {
    id: '128',
    name: 'Projekt 6 Wohnzimmer',
  },
  {
    id: '129',
    name: 'Projekt 7 Wohnzimmer',
  },
  {
    id: '130',
    name: 'Projekt 8 Wohnzimmer',
  },
  {
    id: '131',
    name: 'Projekt 8 Wohnzimmer',
  },
];

export const getAllProjects = async (signal: AbortSignal) => {
  try {
    const result = await fetcher(cloudEndpointFactory.allProjects(), {
      msg: 'could not fetch users projects',
      parser: (data) => AllProjectsSchema.parse(data),
      signal,
      includeCredentials: true,
    });

    if (!result.isSuccessful) {
      throw new Error('Could not load user projects');
    }

    return { projects: result.projects, projectCount: result.projects.length };
  } catch (e) {
    // TODO: Remove this when the backend enables to create projects
    return { projects: dummyProjects, projectCount: dummyProjects.length };
  }
};
