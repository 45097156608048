import { Button, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const ShowFullscreenSection = () => {
  const { t } = useTranslation();

  return (
    <Stack w='full' mt='4' px='5' pb='6' bg='background'>
      <Button h='2rem' fontSize='sm' fontWeight='normal' variant='secondary'>
        {t('inspirationEditor.showAsFullscreen')}
      </Button>
    </Stack>
  );
};
