import { ResponsiveValue } from '@chakra-ui/react';
import { useParams } from '@tanstack/react-router';
import { Property } from 'csstype';
import { useTranslation } from 'react-i18next';
import { ZodError } from 'zod';

import { useToast } from '$/hooks/useToast';
import { logError } from '$/logger';
import { ModuleReceiveAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import {
  getLetterFromIndex,
  useEditorStore,
} from '$/pages/EditorPage/stores/useEditorStore';
import { EditorStatusSchema } from '$/services/usecases/editor/mapper/editorStatus';
import { HoverStatusSchema } from '$/services/usecases/editor/mapper/hoverStatus';
import { ModuleCommandResponseSchema } from '$/services/usecases/editor/mapper/moduleCommandResponse';

type ModuleReceiveMessage = {
  action: ModuleReceiveAction;
} & Record<string, unknown>;

export const useEditorModuleListener = () => {
  const { id }: { id: string | undefined } = useParams({ strict: false });
  const toast = useToast();

  const { t } = useTranslation();

  const setIsEditorModuleLoading = useEditorStore.useSetIsEditorModuleLoading();
  const setIsProjectLoading = useEditorStore.useSetIsProjectLoading();
  const setHoveredObject = useEditorStore.useSetHoveredObject();
  const syncState = useEditorStore.useSyncState();
  const { loadPicture, createComponent, getModuleState } = useEditorActions();

  const showReceivedToast = (
    title: string,
    content: string,
    success: boolean = true,
    color?: ResponsiveValue<Property.Color>,
  ) => {
    toast(title, success ? 'success' : 'error', content, {
      customIcon: 'arrow_right',
      customColor: color,
      customIconProps: { transform: 'rotate(180deg)' },
    });
  };

  const moduleMessageListener = (event: MessageEvent) => {
    const data = (
      typeof event.data == 'string' ? JSON.parse(event.data) : event.data
    ) as ModuleReceiveMessage;

    if (data.action == null) {
      logError({
        message: 'Unexpected message format received',
        data,
      });
      return;
    }
    data.action = data.action.toLocaleLowerCase() as ModuleReceiveAction;

    // Keep the log and the toasts until we always got the messages reliably
    console.log('RECEIVED MESSAGE', data);

    switch (data.action) {
      case ModuleReceiveAction.Ready:
        setIsEditorModuleLoading(false);
        showReceivedToast(
          `Module Ready`,
          JSON.stringify(data),
          true,
          '#a903fc',
        );
        if (id != null) {
          loadPicture(id);
        }
        break;
      case ModuleReceiveAction.ProjectLoaded:
        setIsProjectLoading(false);

        showReceivedToast(
          `Project Loaded`,
          JSON.stringify(data),
          true,
          '#a903fc',
        );
        getModuleState();
        break;
      case ModuleReceiveAction.StatusSync:
        try {
          const statusMessage = EditorStatusSchema.parse(data);
          syncState(statusMessage, data);
          if (statusMessage.components.length === 0) {
            const componentName = t('editor.component_name', {
              letter: getLetterFromIndex(1),
            });
            createComponent(componentName);
          }
        } catch (error) {
          const zodErrors = (error as ZodError).errors.map((error) => ({
            message: error.message,
            path: error.path.join(' -> '),
          }));
          showReceivedToast(
            `ValidationError`,
            JSON.stringify(zodErrors),
            false,
          );
          logError(zodErrors);
        }
        break;
      case ModuleReceiveAction.CommandReceived: {
        const response = ModuleCommandResponseSchema.parse(data);
        if (
          response.data.vars.action === 'getStatus' ||
          response.data.vars.action?.includes('hover')
        )
          break;
        getModuleState();
        break;
      }
      case ModuleReceiveAction.NewLayer: {
        getModuleState();
        break;
      }
      case ModuleReceiveAction.HoverObject: {
        const { elementIndex, layerIndex } = HoverStatusSchema.parse(data.data);
        setHoveredObject(elementIndex, layerIndex);
        break;
      }
      case ModuleReceiveAction.HoverOffObject: {
        setHoveredObject(null, null);
        break;
      }
      default:
        return; // this switch will be filled with actions depending on the actions sent by the module
    }
  };

  return { moduleMessageListener };
};
