import { FC, useEffect, useRef } from 'react';
import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from 'react-zoom-pan-pinch';

import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useKeyPress } from '$/hooks/useKeyPress';
import { useWindowSize } from '$/hooks/useWindowSize';
import { ZoomableImage } from '$/pages/InspirationEditorPage/components/ImageSection/components/ZoomableImage';

interface Props {
  id: string;
}

export const ImageSection: FC<Props> = ({ id }) => {
  const zoomIn = useActionBarStore.useZoomIn();
  const zoomOut = useActionBarStore.useZoomOut();
  const resetZoom = useActionBarStore.useResetZoom();

  const ref = useRef<ReactZoomPanPinchRef>(null);
  const { width } = useWindowSize();
  useEffect(() => ref.current?.resetTransform(), [width]);

  useKeyPress(({ key, ctrl }) => {
    if (ctrl && key === '0') return resetZoom();
    if (key === '+') return zoomIn();
    if (key === '-') return zoomOut();
  }, []);

  return (
    <TransformWrapper centerOnInit minScale={0.025} ref={ref}>
      <TransformComponent wrapperStyle={{ width: '100%', height: '100%' }}>
        <ZoomableImage id={id} />
      </TransformComponent>
    </TransformWrapper>
  );
};
