import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { RenderedInspirationImage } from '$/pages/InspirationEditorPage/components/RenderedInspirationImage';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface ComparisonModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ComparisonModal: FC<ComparisonModalProps> = ({
  isOpen,
  onClose,
}) => {
  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();
  const { t } = useTranslation();

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'full', sm: 'xl' }}
    >
      <ModalOverlay />
      <ModalContent
        w='auto'
        maxW={{ base: 'full', sm: 'full' }}
        mx={{ base: '0', sm: '3.4375rem' }}
        p='10'
      >
        <ModalHeader fontSize='3xl'>{t('editor.compare')}</ModalHeader>
        <ModalCloseButton mt='2.5rem' mr='2.5rem' onClick={onClose} />
        <ModalBody>
          <Grid
            gap='6'
            templateColumns={{
              base: 'none',
              sm: `repeat(${sceneLayersVariants.length}, 1fr)`,
            }}
          >
            {sceneLayersVariants.map((variant, index) => (
              <VStack key={index} pos='relative' align='flex-start' h='full'>
                <Text borderRadius='8px'>
                  {t('editor.variant', {
                    letter: sceneVariantLabelOrder[index],
                  })}
                </Text>
                <RenderedInspirationImage
                  layers={variant}
                  sceneId={scene?.id ?? '0'}
                  key={index}
                  maxHeight='26rem'
                  borderRadius='8px'
                  isAutoWidth={true}
                />
              </VStack>
            ))}
          </Grid>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
