import { Stack, StackProps, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { CollectionDropdown } from '$/components/core/Editor/ColorSelectionSidebar/Filter/components/CollectionDropdown';
import { ProducerDropdown } from '$/components/core/Editor/ColorSelectionSidebar/Filter/components/ProducerDropdown';
import { AttributeHeader } from '$/pages/InspirationEditorPage/components/AttributeSection/components/AttributeHeader';

export const CategoryFilter: FC<StackProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Stack {...props} mb='6'>
      <AttributeHeader
        label={t('inspirationEditor.filter.fanSelection')}
        icon={<Icon icon='filter' w='14px' />}
      />

      <Stack gap='0'>
        <Text fontSize='sm'>{t('inspirationEditor.filter.producer')}</Text>
        <ProducerDropdown />
      </Stack>

      <Stack gap='0'>
        <Text fontSize='sm'>{t('inspirationEditor.filter.collections')}</Text>
        <CollectionDropdown />
      </Stack>
    </Stack>
  );
};
