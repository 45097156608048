import { Stack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { SearchDropdownSkeleton } from '$/components/core/Collection/MaterialSearch/components/SearchDropdownSkeleton';
import { SearchLabel } from '$/components/core/Collection/MaterialSearch/components/SearchLabel';
import { SearchResultItem } from '$/components/core/Collection/MaterialSearch/components/SearchResultItem';
import { Material } from '$/services/usecases/materials';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  materials: Material[] | null | undefined;
  isLoading: boolean;
  onResultClick?: (material: Material) => void;
  isMobile?: boolean;
}

export const SearchDropdownResults: FC<Props> = ({
  materials,
  isLoading,
  onResultClick,
  isMobile = false,
}) => {
  const { t } = useTranslation();

  const activeType = useCollectionStore.useActiveType();

  const hasError = !materials && !isLoading;

  const colorMaterials = materials?.filter((material) =>
    material.type.includes('uni'),
  );
  const wallMaterials = materials?.filter((material) =>
    material.type.includes('wall'),
  );
  const floorMaterials = materials?.filter((material) =>
    material.type.includes('floor'),
  );

  const showMaterialType = (
    materials: Material[] | null | undefined,
    categoryType: string,
  ) => {
    return (
      (activeType === 'all' || activeType === categoryType) &&
      (materials?.length ?? 0) > 0
    );
  };

  const showColorMaterials = showMaterialType(colorMaterials, 'uni');
  const showWallMaterials = showMaterialType(wallMaterials, 'wall');
  const showFloorMaterials = showMaterialType(floorMaterials, 'floor');

  return (
    <Stack mb='16' py={!hasError ? '0' : '3'}>
      <Show when={isLoading || showColorMaterials}>
        <>
          <SearchLabel label={t('dashboard.collection.search.colorTones')} />
          <Stack gap={isMobile ? '2' : '1'}>
            {isLoading && <SearchDropdownSkeleton />}
            {!isLoading &&
              colorMaterials?.map((material) => (
                <SearchResultItem
                  key={material.id}
                  material={material}
                  onClick={onResultClick}
                  isMobile={isMobile}
                />
              ))}
          </Stack>
        </>
      </Show>

      <Show when={isLoading || showWallMaterials}>
        <>
          <SearchLabel label={t('dashboard.collection.search.wallCoverings')} />
          <Stack gap={isMobile ? '2' : '1'}>
            {isLoading && <SearchDropdownSkeleton />}
            {!isLoading &&
              wallMaterials?.map((material) => (
                <SearchResultItem
                  key={material.id}
                  material={material}
                  onClick={onResultClick}
                  isMobile={isMobile}
                />
              ))}
          </Stack>
        </>
      </Show>

      <Show when={isLoading || showFloorMaterials}>
        <>
          <SearchLabel
            label={t('dashboard.collection.search.floorCoverings')}
          />
          <Stack gap={isMobile ? '2' : '1'}>
            {isLoading && <SearchDropdownSkeleton />}
            {!isLoading &&
              floorMaterials?.map((material) => (
                <SearchResultItem
                  key={material.id}
                  material={material}
                  onClick={onResultClick}
                  isMobile={isMobile}
                />
              ))}
          </Stack>
        </>
      </Show>
    </Stack>
  );
};
