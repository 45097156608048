export const colors = {
  neutral: {
    1000: '#222222',
    '1000/30': 'rgba(34, 34, 34, 0.3)',
    950: '#2D2D2D',
    900: '#393939',
    850: '#434343',
    800: '#4E4E4E',
    700: '#656565',
    600: '#7A7A7A',
    500: '#909090',
    400: '#A7A7A7',
    300: '#BDBDBD',
    200: '#D3D3D3',
    150: '#DEDEDE',
    100: '#E9E9E9',
    50: '#F4F4F4',
  },
  primaryViolet: {
    1000: '#3E0057',
    900: '#5A007E',
    800: '#77008B',
    700: '#870092',
    600: '#990099',
    500: '#A6039E',
    400: '#B539AC',
    300: '#C35FBA',
    200: '#D58ECE',
    100: '#E5BBE1',
    50: '#F5E4F3',
  },
  primaryBlue: {
    1000: '#263351',
    900: '#1A3673',
    800: '#214796',
    700: '#3059B6',
    600: '#466CD1',
    500: '#6281E7',
    400: '#7F96F6',
    300: '#9CACFF',
    200: '#BAC2FF',
    100: '#D6DAFF',
    50: '#F2F3FF',
  },
  success: {
    500: '#99CC33',
    900: '#C0F994',
    1000: '#00CC52',
  },
  danger: {
    900: '#CC0000',
    1000: '#FF0000',
  },
  warning: {
    1000: '#E88502',
  },
  badgeLight: {
    blue: {
      text: '#151386',
      background: '#D9D8FD',
    },
    red: {
      text: '#861313',
      background: '#FDD8D8',
    },
    brown: {
      text: '#694610',
      background: '#FCE5C2',
    },
    green: {
      text: '#0C5043',
      background: '#BAF1E7',
    },
  },
  badgeDark: {
    blue: {
      text: '#121070',
      background: '#C2C0FC',
    },
    red: {
      text: '#6F1010',
      background: '#FCC0C0',
    },
    brown: {
      text: '#FCC0C0',
      background: '#FBDBAD',
    },
    green: {
      text: '#093A31',
      background: '#A6ECE0',
    },
  },
  colorGroup: {
    yellow: '#FFE602',
    brown: '#61F1D7',
    purple: '#7F7DF7',
    green: '#2Edd2A',
    orange: '#F7C67D',
    red: '#F77D7D',
    pink: '#F77DCE',
    blue: '#0085FF',
    lightGrey: '#C7BEB3',
    darkGrey: '#808080',
  },
  visualization: {
    transparentBlue: 'rgba(0, 133, 255, 0.2)',
    white: '#FFFFFF',
    black: '#000000',
    pink: '#FF00F5',
    blue: '#0085FF',
    red: '#FF0000',
    orange: '#FF9900',
    green: '#00CC52',
  },
};
