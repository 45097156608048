import {
  ZustandHookSelectors,
  createSelectorHooks,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { i18n } from '$/components/core/Localization/18n';
import { logError, trackEvent } from '$/logger';
import { MaterialType } from '$/services/mapper/uses';
import { mapMaterialTypeToCategory } from '$/utils/piwikUtils';

export const DEFAULTFOLDERNAME = 'default';

export type Favorite = {
  materialId: string;
  parentFolderIds: string[];
};

export type FavoriteFolder = {
  name: string;
  id: string;
};

type State = {
  favorites: Favorite[];
  favoriteFolders: FavoriteFolder[];
  activeFolderId: string | null;
};

type Actions = {
  addFavorite: (
    materialId: Favorite['materialId'],
    materialType?: MaterialType,
    materialLabel?: string,
    parentFolders?: Favorite['parentFolderIds'],
  ) => void;
  removeFavorite: (favoriteId: Favorite['materialId']) => void;
  toogleFavorite: (
    favoriteId: Favorite['materialId'],
    favoriteType?: MaterialType,
    favoriteLabel?: string,
  ) => void;
  changeFoldersOfFavorite: (
    favoriteId: Favorite['materialId'],
    folders: Favorite['parentFolderIds'],
  ) => void;
  removeFavoriteFromFolder: (
    favoriteId: Favorite['materialId'],
    folderId: Favorite['parentFolderIds'][number],
  ) => void;
  addFavoriteToFolder: (
    favoriteId: Favorite['materialId'],
    folderId: Favorite['parentFolderIds'][number],
  ) => void;
  isFavorite: (materialId: Favorite['materialId']) => boolean;
  addFolder: (name?: FavoriteFolder['name']) => FavoriteFolder['id'];
  removeFolder: (id: FavoriteFolder['id']) => void;
  duplicateFolder: (id: FavoriteFolder['id'], nameSuffix: string) => void;
  renameFolder: (
    id: FavoriteFolder['id'],
    newName: FavoriteFolder['name'],
  ) => void;
  setActiveFolderId: (id: FavoriteFolder['id'] | null) => void;
};

const initialValues: State = {
  favorites: [],
  favoriteFolders: [],
  activeFolderId: null,
};

const store = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialValues,
      addFavorite: (materialId, materialType, materialLabel, parentFolders) => {
        if (materialId && materialType && materialLabel) {
          trackEvent(
            mapMaterialTypeToCategory(materialType),
            'AddFavorite',
            materialLabel,
          );
        } else {
          logError({
            message: 'Could not track Add Favorite event. Missing data.',
            data: {
              materialId,
              materialType,
              materialLabel,
            },
          });
        }

        const newFavorites = get().favorites;
        newFavorites.push({
          materialId,
          parentFolderIds: parentFolders
            ? [DEFAULTFOLDERNAME, ...parentFolders]
            : [DEFAULTFOLDERNAME],
        });
        set({
          favorites: newFavorites,
        });
      },
      removeFavorite: (materialId) => {
        set(({ favorites }) => ({
          favorites: favorites.filter(
            (favorite) => favorite.materialId !== materialId,
          ),
        }));
      },
      toogleFavorite: (materialId, materialType, materialLabel) => {
        if (get().isFavorite(materialId)) {
          get().removeFavorite(materialId);
        } else {
          get().addFavorite(materialId, materialType, materialLabel);
        }
      },
      isFavorite: (materialId) => {
        return (
          get().favorites.find(
            (favorite) => favorite.materialId === materialId,
          ) != null
        );
      },
      changeFoldersOfFavorite: (materialId, folderIds) => {
        const favoriteIndex = get().favorites.findIndex(
          (favorite) => favorite.materialId === materialId,
        );
        const updatedFavorite = get().favorites[favoriteIndex];
        updatedFavorite.parentFolderIds = folderIds;

        const updatedFavorites = [...get().favorites];
        updatedFavorites.splice(favoriteIndex, 1, updatedFavorite);
        set({ favorites: updatedFavorites });
      },
      removeFavoriteFromFolder: (favoriteId, folderId) => {
        const favorite = get().favorites.find(
          (favorite) => favorite.materialId === favoriteId,
        );

        if (favorite == null) return;

        get().changeFoldersOfFavorite(
          favoriteId,
          favorite?.parentFolderIds.filter((folder) => folder !== folderId),
        );
      },
      addFavoriteToFolder: (favoriteId, folderId) => {
        const favorite = get().favorites.find(
          (favorite) => favorite.materialId === favoriteId,
        );

        if (favorite == null) return;

        get().changeFoldersOfFavorite(favoriteId, [
          ...favorite.parentFolderIds,
          folderId,
        ]);
      },
      addFolder: (name) => {
        const id = new Date().getTime().toString();
        const newFolder: FavoriteFolder = {
          name:
            name ??
            i18n.t('favorites.defaultFolderName', {
              letter: get().favoriteFolders.length + 1,
            }),
          id,
        };
        set({ favoriteFolders: [...get().favoriteFolders, newFolder] });
        return id;
      },
      removeFolder: (id: string) => {
        const updatedFavorites = get().favorites.map<Favorite>((favorite) => {
          if (!favorite.parentFolderIds.includes(id)) return favorite;

          const newFolders = favorite.parentFolderIds.filter(
            (folderId) => folderId != id,
          );

          return {
            materialId: favorite.materialId,
            parentFolderIds:
              newFolders.length > 0 ? newFolders : [DEFAULTFOLDERNAME],
          };
        });

        set(({ favoriteFolders }) => ({
          favoriteFolders: favoriteFolders.filter((folder) => folder.id != id),
          favorites: updatedFavorites,
        }));

        if (get().activeFolderId === id) {
          set({ activeFolderId: null });
        }
      },
      renameFolder: (id: string, newName: string) => {
        const folders = [...get().favoriteFolders];
        const folderIndex = folders.findIndex((folder) => folder.id === id);
        folders[folderIndex] = {
          id,
          name: newName,
        };
        set({ favoriteFolders: folders });
      },
      duplicateFolder: (id, nameSuffix) => {
        const folder = get().favoriteFolders.find((f) => f.id === id);

        if (folder == null) return;
        const newFolderId = get().addFolder(`${folder.name} ${nameSuffix}`);
        const updatedFavorites = get().favorites.map<Favorite>((favorite) => {
          if (!favorite.parentFolderIds.includes(id)) return favorite;

          return {
            materialId: favorite.materialId,
            parentFolderIds: [...favorite.parentFolderIds, newFolderId],
          };
        });

        set({ favorites: updatedFavorites });
      },
      setActiveFolderId: (id: string | null) => {
        set({ activeFolderId: id });
      },
    }),
    {
      name: 'favorite-store',
      version: 1,
      migrate: (persistedState, version) => {
        const state = persistedState as State & Actions;
        if (version === 0) {
          state.favorites = [];
        }
        return state;
      },
    },
  ),
);

export const useFavoriteStore = createSelectorHooks(store) as typeof store &
  ZustandHookSelectors<State & Actions>;
