import { FormLabel, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { TrackedCheckbox } from '$/components/common/Checkbox/TrackedCheckbox';
import { shortenText } from '$/utils/stringUtils';

interface Props {
  label: string;
  value: string;
}

export const MoveFavoriteCheckbox: FC<Props> = ({ label, value }) => {
  return (
    <FormLabel m='0' _hover={{ bg: 'bodyBackground' }} cursor='pointer'>
      <HStack
        justifyContent='space-between'
        mx='6'
        py='0.375rem'
        fontSize='sm'
        fontWeight='normal'
      >
        <Text title={label}>{shortenText(label, 28)}</Text>
        <TrackedCheckbox
          value={value}
          contentName='Favorite'
          contentPiece='EditFolder'
        />
      </HStack>
    </FormLabel>
  );
};
