import { HStack, Tag } from '@chakra-ui/react';
import { FC } from 'react';

import { GenericTagContent } from '$/pages/DashboardPages/pages/Collection/components/CollectionGrid/DataSortingRow/components/GenericTagContent';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props {
  filterSearchName: string;
  setIsLoading?: (isLoading: boolean) => void;
}

export const MobileFilterMulti: FC<Props> = ({
  filterSearchName,
  setIsLoading,
}) => {
  const possibleFilter = useCollectionStore.useFilter();
  const filterGroups = useCollectionStore.useFilterGroups();
  const activateFilterGroup = useCollectionStore.useActivateFilterGroup();

  const filter = [
    ...(possibleFilter?.general ?? []),
    ...(possibleFilter?.additional ?? []),
  ].find((x) => x.searchName === filterSearchName);

  const isFilterSelected = (
    filterSearchName: string,
    filterItemIntName: string,
  ) =>
    filterGroups.some(
      (filterGroup) =>
        filterGroup.type === filterSearchName &&
        filterGroup.option === filterItemIntName,
    );

  return (
    <HStack gap='2' overflowX='auto' mt='2' pt='2' pr='4' pb='2'>
      {filter?.items.map((item) => {
        return (
          <Tag
            key={item.intName}
            layerStyle={
              isFilterSelected(filter.searchName, item.intName)
                ? 'FilterTagSelected'
                : 'FilterTag'
            }
            px='4'
            borderColor='border'
            borderRadius='full'
            onClick={async () => {
              setIsLoading?.(true);
              await activateFilterGroup(
                {
                  displayName: item.displayName,
                  type: item.searchName,
                  option: item.intName,
                  payload: item.payload,
                },
                true,
              );
              setIsLoading?.(false);
            }}
          >
            <GenericTagContent
              label={item.displayName}
              color={item.payload}
              hideLabel={false}
            />
          </Tag>
        );
      })}
    </HStack>
  );
};
