import { Button, HStack, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ExportInspirationModal } from '$/pages/InspirationEditorPage/components/ExportModal';

export const ExportSection = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Stack w='full' px='5' pb='6' bg='background'>
      <HStack justifyItems='left' gap='2' fontWeight='normal'>
        <Icon fill='lighterText' icon='upload_file' />
        <Text color='lighterText' fontSize='sm' fontWeight='bold'>
          {t('editor.exportText')}
        </Text>
      </HStack>
      <Button
        h='2rem'
        fontSize='sm'
        fontWeight='normal'
        onClick={onOpen}
        variant='secondary'
      >
        {t('editor.exportButton')}
      </Button>
      <ExportInspirationModal isOpen={isOpen} onClose={onClose} />
    </Stack>
  );
};
