import { cloudEndpointFactory } from '$/services/endpoints/cloudEndpoints';
import { fetcher } from '$/services/fetcher';
import { GenericCloudResponseSchema } from '$/services/usecases/authentication/mapper/genericCloudResponse';
import { UpdateDataSchema } from '$/services/usecases/profile/mapper/updateData';

export type ProfileData = {
  name: string;
  email: string;
};

export const uploadProfilePicture = async (image: File) => {
  // TODO: CHECK IF CORRECT ENDPOINTS ARE AVAILABLE
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const formData = new FormData();
  formData.append('image', image);

  return await fetcher(cloudEndpointFactory.uploadProfilePicture(), {
    method: 'POST',
    msg: 'Failed to upload image',
    body: formData,
    parser: (data) => GenericCloudResponseSchema.parse(data),
  });
};

export const updateUserData = async (data: ProfileData) => {
  // TODO: CHECK IF CORRECT ENDPOINTS ARE AVAILABLE
  await new Promise((resolve) => setTimeout(resolve, 2000));

  const result = await fetcher(cloudEndpointFactory.updateUserData(data), {
    msg: 'Failed to update user data',
    parser: (data) => UpdateDataSchema.parse(data),
  });

  if (!result.isSuccessful) {
    throw new Error('Failed to update user data');
  }

  return { data: result.data };
};

export const deleteAccount = async () => {
  // TODO: CHECK IF CORRECT ENDPOINTS ARE AVAILABLE
  await new Promise((resolve) => setTimeout(resolve, 2000));

  return await fetcher(cloudEndpointFactory.deleteAccount(), {
    msg: 'Sign in failed',
    parser: (data) => GenericCloudResponseSchema.parse(data),
  });
};
