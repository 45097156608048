import { Button, IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorAction } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useToast } from '$/hooks/useToast';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  action: EditorAction;
}

export const ActionButton: FC<Props> = ({ action }) => {
  const { t } = useTranslation();

  const actions = useEditorActions();
  const toast = useToast();

  const workMode = useEditorStore.useWorkMode();

  const isActive = action.mode != null && action.mode === workMode;

  const handleActionBarClick = (action: EditorAction) => {
    if (!isActive) action.onClick?.(actions, { t, toast });
  };

  const buttonProps = {
    key: action.key,
    variant: isActive ? 'primary' : 'text',
    onClick: () => handleActionBarClick(action),
    layerStyle: 'ActionIconButton',
    'aria-label': t('editor.action', { key: action.key }),
    borderRadius: '2px',
  };

  if (!action.label) {
    return <IconButton icon={action.icon} {...buttonProps} />;
  }

  return (
    <Button px='1' rightIcon={action.icon} {...buttonProps}>
      {action.label}
    </Button>
  );
};
