import { createRoute } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { MessagePage } from '$/pages/MessagePage';
import { RootRoute } from '$/routes/RootRoute';

const Component = () => {
  const { t } = useTranslation();

  return (
    <MessagePage
      headerText={t('error.linkExpiredHeader')}
      icon='sad_emoji'
      descriptionText={t('error.linkExpiredDescription')}
      buttonText={t('editor.backToHome')}
    />
  );
};

export const LinkExpiredRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/link-expired',
  component: Component,
});
