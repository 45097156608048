import {
  Box,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';

const maxZoomLevel = 800;
const minZoomLevel = 10;

export const EditorZoomAction = () => {
  const { t } = useTranslation();
  const zoomIn = useActionBarStore.useZoomIn();
  const zoomOut = useActionBarStore.useZoomOut();
  const resetZoom = useActionBarStore.useResetZoom();
  const setZoomLevel = useActionBarStore.useSetZoomLevel();
  const zoomLevel = useActionBarStore.useZoomLevel();
  const [zoomInput, setZoomInput] = useState<number>(
    Math.round(zoomLevel * 100),
  );

  useEffect(() => {
    setZoomInput(Math.round(zoomLevel * 100));
  }, [zoomLevel]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newZoomLevel = Number(event.target.value);
    setZoomInput(Math.min(maxZoomLevel, newZoomLevel));
  };

  const handleInputSubmit = () => {
    const boundedZoomLevel = Math.max(
      minZoomLevel,
      Math.min(zoomInput, maxZoomLevel),
    );
    setZoomLevel(boundedZoomLevel / 100);
  };

  return (
    <HStack gap='0' h='full'>
      <IconButton
        aria-label={t('editor.zoom_out')}
        icon={<Icon icon='zoom_out' />}
        onClick={zoomOut}
        variant='text'
      />
      <InputGroup w='20'>
        <Input
          pr='3'
          fontSize='sm'
          bg='transparent'
          borderColor='transparent'
          _focus={{
            borderColor: 'border',
            boxShadow: 'none',
          }}
          onChange={handleInputChange}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleInputSubmit();
            }
          }}
          type='number'
          value={zoomInput}
        />
        <InputRightElement fontSize='sm'>%</InputRightElement>
      </InputGroup>

      <IconButton
        aria-label={t('editor.zoom_in')}
        icon={<Icon icon='zoom_in' />}
        onClick={zoomIn}
        variant='text'
      />
      <Box w='2px' h='40%' mx='2' bg='header.divider' borderRadius='2px' />
      <IconButton
        aria-label={t('editor.zoom_in')}
        icon={<Icon icon='zoom_in_map' />}
        onClick={resetZoom}
        variant='text'
      />
    </HStack>
  );
};
