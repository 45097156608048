import { Box, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { Material } from '$/services/usecases/materials';
import { useMaterialStore } from '$/stores/useMaterialStore';

interface Props {
  material: Material;
}

export const ActiveMaterialCard: FC<Props> = ({ material }) => {
  const { t } = useTranslation();

  const setActiveMaterial = useMaterialStore.useSetActiveMaterial();
  const bg = !material.url() ? material.color ?? 'transparent' : 'transparent';

  return (
    <HStack justify='space-between'>
      <HStack gap='5'>
        <Box
          w='64px'
          h='64px'
          bg={bg}
          bgImage={material.url()}
          border='solid 1px'
          borderColor='border'
          borderRadius='4px'
        />
        <Stack gap='0'>
          <Text color='neutralText' fontSize='sm'>
            {t('dashboard.inspiration.chosenColor')}
          </Text>
          <Text color='text' fontSize='2xl' fontWeight='bold'>
            {material.info}
          </Text>
          <Text color='neutralText' fontSize='sm' fontWeight='bold'>
            {material.collection}
          </Text>
        </Stack>
      </HStack>
      <IconButton
        aria-label=''
        icon={<Icon icon='trash_can' />}
        onClick={() => setActiveMaterial(null)}
        variant='text'
      />
    </HStack>
  );
};
