import { Divider, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/ShareSection';
import { ShowFullscreenSection } from '$/components/core/Editor/AttributeSidebar/components/ShowFullscreenSection';
import { ColorGrid } from '$/components/core/Editor/ColorSelectionSidebar/ColorGrid';
import { CurrentMaterialSection } from '$/components/core/Editor/ColorSelectionSidebar/CurrentMaterialSection';
import { FanPageSelector } from '$/components/core/Editor/ColorSelectionSidebar/FanPageSelector';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/FavoriteFolderList';
import { FavoriteFolderView } from '$/components/core/Editor/ColorSelectionSidebar/FavoriteFolderView';
import { BrightnessFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/BrightnessFilter';
import { CategoryFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/CategoryFilter';
import { ColorFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/ColorFilter';
import { SearchResult } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

export const ColorSelectionSidebar = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const activeFolderId = useFavoriteStore.useActiveFolderId();

  const activeComponent = useInspirationEditorStore((state) =>
    state.getActiveComponent(),
  );

  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  const query = useMaterialSearchStore.useQuery();

  if (activeFolderId != null) {
    return (
      <FavoriteFolderView
        materialTypes={activeComponent?.materialTypes}
        activeMaterialId={activeComponent?.material?.id}
        onClickItem={changeComponentMaterial}
      />
    );
  }

  return (
    <>
      <ShareSection />
      <Divider />
      <Stack gap='0' w='full' minW='0' h='35px' px='5'>
        <SearchInput searchLabel={t('inspirationEditor.attributes.search')} />
      </Stack>
      <Show when={query.length < 3} fallback={() => <SearchResult />}>
        <Divider />
        <CurrentMaterialSection material={activeComponent?.material} px='5' />
        <FanPageSelector
          material={activeComponent?.material}
          onColorChange={changeComponentMaterial}
        />
        <CategoryFilter px='5' w='full' />
        <Divider />
        <ColorFilter px='5' w='full' />
        <Divider />
        <BrightnessFilter px='5' w='full' />
        <Divider />
        <ColorGrid
          material={activeComponent?.material}
          onColorChange={changeComponentMaterial}
          px='5'
        />
        <Divider />
        <Show when={hasPermission('Favorites_Select')}>
          <FavoriteFolderList />
          <Divider />
        </Show>

        <ExportSection />
        <Divider />
        <ShowFullscreenSection />
      </Show>
    </>
  );
};
