import { Box, HStack } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { ActionGroup } from '$/components/core/Editor/ActionBar/components/ActionGroup';
import { HorizontalGroup } from '$/components/core/Editor/ActionBar/components/HorizontalGroup';
import {
  EditorActionGroup,
  useActionBarStore,
} from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useKeyPress } from '$/hooks/useKeyPress';

interface Props {
  groups: EditorActionGroup[];
  children?: ReactNode;
}

export const ActionBar: FC<Props> = ({ groups, children }) => {
  const selectAction = useActionBarStore.useSelectAction();

  useKeyPress(({ key }) => key === 'Escape' && selectAction(null));

  return (
    <HStack gap='0' h='full'>
      {groups.map((group) => (
        <HStack key={group.id} h='100%'>
          <Box display={{ base: 'none', xl: 'flex' }}>
            <HorizontalGroup group={group} />
          </Box>

          <Box display={{ base: 'flex', xl: 'none' }}>
            <ActionGroup group={group} />
          </Box>

          <Box w='2px' h='40%' bg='header.divider' borderRadius='2px' />
        </HStack>
      ))}

      {children}
    </HStack>
  );
};
