import { HStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { ProfileModals } from '$/pages/Profile';

interface Props {
  onViewChange: (view: ProfileModals) => void;
}

export const ProfileActionSection: FC<Props> = ({ onViewChange }) => {
  const { t } = useTranslation();

  return (
    <HStack justify='space-between' w='full'>
      <TrackedButton
        px='0'
        fontSize='sm'
        onClick={() => onViewChange('change_password')}
        variant='text'
        contentName='CloudAccount'
        contentPiece='ManageAccount'
        contentTarget='EditPassword'
      >
        {t('dashboard.authentication.profile.changePassword')}
      </TrackedButton>

      <TrackedButton
        fontSize='sm'
        onClick={() => onViewChange('delete_account')}
        variant='danger'
        contentName='CloudAccount'
        contentPiece='ManageAccount'
        contentTarget='DeleteAccount'
      >
        {t('dashboard.authentication.profile.deleteAccount')}
      </TrackedButton>
    </HStack>
  );
};
