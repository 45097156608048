import { Center } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { ColorBox } from '$/pages/InspirationEditorPage/components/ColorBox';
import { Material } from '$/services/usecases/materials';
import { getColorBrightness } from '$/utils/colorUtils';

interface Props {
  material: Material | null;
  boxSize?: string;
}

export const ColorLayerImage: FC<Props> = ({ material, boxSize = '20px' }) => {
  if (material === null) {
    return (
      <Center
        layerStyle='ColorImage'
        boxSize={boxSize}
        minW={boxSize}
        border='1px solid'
        borderColor='border'
        borderRadius='2px'
        aspectRatio={1}
      >
        <Icon icon='subtract' width='60%' />
      </Center>
    );
  }

  const brightness = getColorBrightness(material.color);

  return (
    <ColorBox
      layerStyle='ColorImage'
      materialColor={material.color}
      image={material.url()}
      border='1px solid'
      borderColor={brightness > 200 ? 'border' : 'transparent'}
      borderRadius='2px'
      boxSize={boxSize}
    />
  );
};
