import { AccordionButton, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';

interface Props {
  label: string;
  isExpanded: boolean;
}

export const AccordionHeader: FC<Props> = ({ label, isExpanded }) => {
  return (
    <AccordionButton justifyContent='space-between' w='full' fontSize='sm'>
      <Text fontWeight='bold'>{label}</Text>
      <Icon
        icon='chevron_down'
        transform={isExpanded ? 'rotate(180deg)' : ''}
        w='12px'
        transition='transform 0.2s ease'
      />
    </AccordionButton>
  );
};
