import { createRoute } from '@tanstack/react-router';

import { HtmlContentPage } from '$/pages/HtmlContentPage';
import { RootRoute } from '$/routes/RootRoute';
import { legalEndpoints } from '$/services/endpoints';

export const ImprintRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/imprint',
  component: () => (
    <HtmlContentPage contentEndpoint={legalEndpoints.IMPRINT_URL} />
  ),
});
