import { createRoute } from '@tanstack/react-router';

import { NotFoundPage } from '$/pages/NotFoundPage';
import { RootRoute } from '$/routes/RootRoute';

export const PricingRoute = createRoute({
  getParentRoute: () => RootRoute,
  path: '/pricing',
  component: NotFoundPage,
});
