import { useTranslation } from 'react-i18next';

import { SingleFilter } from '$/components/core/Collection/CollectionFilter/components/SingleFilter';
import { FilterDropdown } from '$/components/core/Collection/CollectionFilter/FilterDropdown';
import { LoadingDropdown } from '$/components/core/Editor/ColorSelectionSidebar/Filter/components/LoadingDropdown';
import { useCollectionStore } from '$/stores/useCollectionStore';

export const CollectionDropdown = () => {
  const { t } = useTranslation();

  const filter = useCollectionStore.useFilter();
  const getActiveInCategory = useCollectionStore.useGetActiveInCategory();

  const collectionFilter = filter?.general.find(
    (item) => item.searchName === 'collection',
  );

  const activeCollections = collectionFilter
    ? getActiveInCategory(collectionFilter.searchName)
    : [];

  const title =
    activeCollections.length > 0
      ? activeCollections[0].displayName
      : t('inspirationEditor.filter.collectionDefault');

  return (
    <FilterDropdown
      title={title}
      loadingComponent={<LoadingDropdown title={title} />}
      bg='bodyBackground'
      border='1px solid'
      borderColor='border'
      textAlign='start'
      h='8'
      w='full'
      borderRadius='4'
      px='3'
      fontSize='sm'
    >
      {(onMenuClose) => (
        <SingleFilter
          onMenuClose={onMenuClose}
          options={collectionFilter?.items ?? []}
          itemWidth='204px'
        />
      )}
    </FilterDropdown>
  );
};
