import { HStack, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { HookFormPasswordInput } from '$/components/core/Form/HookFormPasswordInput';
import { i18n } from '$/components/core/Localization/18n';
import { useToast } from '$/hooks/useToast';
import { LoginData, signInUser } from '$/services/usecases/authentication';
import { emailFormatRegex } from '$/utils/regexUtils';

const LoginInput = (props: HookFormInputProps<LoginData>) => (
  <HookFormInput<LoginData> {...props} />
);

export const LoginForm = () => {
  const { t } = useTranslation();

  const formMethods = useForm<LoginData>({ mode: 'onTouched' });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const showToast = useToast();

  const closeModal = useAuthenticationStore.useOnModalClose();
  const setAuthenticationModalType = useAuthenticationStore.useSetModalType();
  const authenticate = useAuthenticationStore.useAuthenticate();

  useEffect(() => {
    formMethods.setFocus('email');
  }, [formMethods]);

  const onLogin = async (data: LoginData) => {
    setLoading(true);

    try {
      const signInPayload = await signInUser(data, i18n.language);

      if (signInPayload.isSuccessful) {
        authenticate(signInPayload.token);
        closeModal();
        showToast(
          t('dashboard.authentication.signInSuccessfulTitle'),
          'success',
          t('dashboard.authentication.signInSuccessfulDescription'),
        );
      } else {
        setError(
          t(
            `dashboard.authentication.errorMessages.${signInPayload.error.code}`,
          ),
        );
      }
    } catch (error) {
      setError(t(`dashboard.authentication.errorMessages.1001`));
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <VStack as='form' gap='8' onSubmit={formMethods.handleSubmit(onLogin)}>
        <LoginInput
          type='email'
          accessor='email'
          externalError={error !== null}
          onChange={() => setError(null)}
          label={t('dashboard.authentication.emailLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.emailLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            pattern: {
              value: emailFormatRegex,
              message: t('dashboard.authentication.errorMessages.wrongFormat'),
            },
          }}
        />
        <HookFormPasswordInput<LoginData>
          accessor='password'
          label={t('dashboard.authentication.passwordLabel')}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
          }}
        />
        {error && (
          <Text color='danger.1000' fontWeight='bold'>
            {error}
          </Text>
        )}
        <HStack justifyContent='stretch' gap='4' w='full' mt='6'>
          <TrackedButton
            variant='text'
            onClick={() => setAuthenticationModalType('passwordForgotten')}
            contentName='CloudAccount'
            contentPiece='Login'
            contentTarget='ForgetPassword'
          >
            {t('dashboard.authentication.forgotPassword')}
          </TrackedButton>
          <TrackedButton
            flexGrow='1'
            isLoading={loading}
            type='submit'
            variant='primary'
            contentName='CloudAccount'
            contentPiece='Login'
            contentTarget='Login'
          >
            {t('dashboard.header.signIn')}
          </TrackedButton>
        </HStack>
      </VStack>
    </FormProvider>
  );
};
