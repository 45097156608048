import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyLinkSection } from '$/components/core/Editor/AttributeSidebar/components/CopyLinkSection';
import { SendPerMailSection } from '$/components/core/Editor/AttributeSidebar/components/SendPerMailSection';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ShareModal: FC<ShareModalProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        layerStyle='modal'
        w='32rem'
        px='2.5rem'
        py='2rem'
        bg='background'
      >
        <ModalHeader m='0' pb='3.25rem' pl='0' fontSize='3xl'>
          {t('editor.share_project')}
        </ModalHeader>
        <ModalCloseButton mt='2rem' mr='1rem' />
        <ModalBody h='full' m='0' p='0'>
          <VStack gap='2.25rem'>
            <Text fontSize='sm' fontWeight='light' fontStyle='bold'>
              {t('editor.share_modal_text')}
            </Text>
            <SendPerMailSection />
            <CopyLinkSection />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
