import { VStack } from '@chakra-ui/react';

import { NavbarLinks } from '$/components/layouts/BaseDashboardLayout/components/NavbarLinks';

export const Navbar = () => {
  return (
    <VStack
      layerStyle='DashboardSection'
      flex='1'
      gap='0'
      overflow='hidden'
      w={{ base: 'fit-content', md: '14rem' }}
      h='100%'
    >
      <NavbarLinks />
    </VStack>
  );
};
