import { VStack, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  HookFormInput,
  HookFormInputProps,
} from '$/components/core/Form/HookFormInput';
import { useToast } from '$/hooks/useToast';
import { forgotPassword } from '$/services/usecases/authentication';
import { emailFormatRegex } from '$/utils/regexUtils';

type ForgotPasswordData = {
  email: string;
};

const ForgotPasswordInput = (props: HookFormInputProps<ForgotPasswordData>) => (
  <HookFormInput<ForgotPasswordData> {...props} />
);

export const ForgotPasswordForm = () => {
  const { t, i18n } = useTranslation();
  const formMethods = useForm<ForgotPasswordData>({ mode: 'onTouched' });
  const [error, setError] = useState<string | null>(null);
  const setModalType = useAuthenticationStore.useSetModalType();
  const toast = useToast();

  const onSubmit = async (data: ForgotPasswordData) => {
    const { error, isSuccessful } = await forgotPassword(
      data.email,
      i18n.language,
    );

    if (isSuccessful) {
      toast(
        t('dashboard.authentication.mailSent'),
        'success',
        t('dashboard.authentication.forgotPasswordSuccessMessage', {
          email: data.email,
        }),
      );
      setModalType('login');
    } else {
      const code = error?.code ?? -1;
      setError(t(`dashboard.authentication.errorMessages.${code}`));
    }
  };

  return (
    <FormProvider {...formMethods}>
      <VStack as='form' gap='10' onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Text fontSize='sm'>
          {t('dashboard.authentication.forgotPasswordDescripton')}
        </Text>
        <ForgotPasswordInput
          type='email'
          accessor='email'
          label={t('dashboard.authentication.emailLabel')}
          placeholder={t('dashboard.authentication.defaultPlaceholder', {
            field: t('dashboard.authentication.emailLabel'),
          })}
          registerOptions={{
            required: t('dashboard.authentication.errorMessages.isRequired'),
            pattern: {
              value: emailFormatRegex,
              message: t('dashboard.authentication.errorMessages.wrongFormat'),
            },
          }}
        />
        {error && (
          <Text color='danger.1000' fontWeight='bold'>
            {error}
          </Text>
        )}
        <TrackedButton
          flexGrow='1'
          mt='3'
          type='submit'
          variant='primary'
          contentName='CloudAccount'
          contentPiece='ForgetPassword'
          contentTarget='Reset'
        >
          {t('dashboard.authentication.resetPassword.action')}
        </TrackedButton>
      </VStack>
    </FormProvider>
  );
};
