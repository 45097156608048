import {
  RangeSlider,
  RangeSliderThumb,
  RangeSliderTrack,
} from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  value: number[];
  setValue: (value: number[]) => void;
}

export const BrightnessSlider: FC<Props> = ({ value, setValue }) => {
  return (
    <RangeSlider
      max={100}
      min={0}
      onChange={(val) => {
        if (val[0] !== value[0]) {
          setValue([val[0] === val[1] ? val[0] - 1 : val[0], value[1]]);
        }
        if (val[1] !== value[1]) {
          setValue([value[0], val[0] === val[1] ? val[1] + 1 : val[1]]);
        }
      }}
      value={value}
    >
      <RangeSliderTrack
        h='9px'
        bgGradient='linear(to-r, neutral.1000, white)'
        borderWidth='1px'
        borderColor='border'
        borderRadius='full'
      />
      <RangeSliderThumb
        w='15px'
        h='15px'
        bg='background'
        borderWidth='1px'
        borderColor='border'
        index={0}
      />
      <RangeSliderThumb
        w='15px'
        h='15px'
        bg='background'
        borderWidth='1px'
        borderColor='border'
        index={1}
      />
    </RangeSlider>
  );
};
