import { HStack } from '@chakra-ui/react';
import { FC } from 'react';

import { ActionButton } from '$/components/core/Editor/ActionBar/components/ActionButton';
import { EditorActionGroup } from '$/components/core/Editor/ActionBar/store/useActionBarStore';

interface Props {
  group: EditorActionGroup;
}

export const HorizontalGroup: FC<Props> = ({ group }) => {
  return (
    <HStack gap='0' ml='8px'>
      {group.actions.map((action) => (
        <ActionButton key={action.key} action={action} />
      ))}
    </HStack>
  );
};
