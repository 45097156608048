import { useQuery } from '@tanstack/react-query';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import {
  UserRole,
  userRoleDefinition,
} from '$/services/usecases/authentication/mapper/jwt';
import { projectsQuery } from '$/services/usecases/projects/queries';

const permissionMatrix = {
  General_Login_Register: ['free'],
  General_User_Profile: ['community', 'standard', 'pro'],
  General_Project_Page: ['pro', 'standard'],
  General_Abo_Page: ['free', 'community', 'standard'],
  General_Welcome_Project_Count: ['community', 'standard'],
  Favorites_Open: ['community', 'standard', 'pro'],
  Favorites_Select: ['community', 'standard', 'pro'],
  Project_Create: ['community', 'standard', 'pro'],
  Project_Open: ['community', 'standard', 'pro'],
};

export type Permission = keyof typeof permissionMatrix;

export const hasPermissionFactory =
  (userRole: UserRole) => (requestedPermission: Permission) => {
    const allowedRoles = permissionMatrix[requestedPermission] ?? [];
    return allowedRoles.includes(userRole);
  };

const getProjectCountLimit = (userRole: UserRole) => () => {
  return userRoleDefinition[userRole].projectLimit;
};

const canCreateMoreProjects =
  (userRole: UserRole, userProjectCount: number) => () => {
    return getProjectCountLimit(userRole)() > userProjectCount;
  };

export const useAuthorization = () => {
  const { role } = useAuthenticationStore.useUser();

  const projectCount = useQuery(projectsQuery).data?.projectCount ?? 0;

  return {
    hasPermission: hasPermissionFactory(role),
    canCreateMoreProjects: canCreateMoreProjects(role, projectCount),
    getProjectCountLimit: getProjectCountLimit(role),
  };
};
