import {
  AbsoluteCenter,
  Box,
  Button,
  Image,
  ImageProps,
} from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { endpointFactory } from '$/services/endpoints';
import { useMaterialStore } from '$/stores/useMaterialStore';

interface Props extends ImageProps {
  id: string;
  materialId: string;
  onButtonClickAction: () => void;
  showColorizeButton?: boolean;
}

export const InspirationBox: FC<Props> = ({
  id,
  materialId,
  showColorizeButton = false,
  onButtonClickAction,
  ...props
}) => {
  const { t } = useTranslation();

  const setActiveMaterial = useMaterialStore.useSetActiveMaterial();
  const clearCollection = useMaterialDetailStore.useClearActive();

  const handleApplyInspiration = () => {
    setActiveMaterial(materialId);
    onButtonClickAction();
    clearCollection();
  };

  return (
    <Box pos='relative'>
      <Image
        border='1px'
        borderColor='border'
        borderRadius='4px'
        alt={id}
        aspectRatio='3/2'
        src={endpointFactory.sceneImageUrl({ id })}
        {...props}
      />
      {showColorizeButton && (
        <AbsoluteCenter>
          <Link
            to='/inspirations/$id'
            params={{ id }}
            onClick={handleApplyInspiration}
          >
            <Button px='16' variant='black'>
              {t('dashboard.collection.colorize')}
            </Button>
          </Link>
        </AbsoluteCenter>
      )}
    </Box>
  );
};
