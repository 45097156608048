import { Button } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';

export const NewProjectButton = () => {
  const { t } = useTranslation();

  const { canCreateMoreProjects, hasPermission } = useAuthorization();

  return (
    <Button
      gap='1'
      h='10'
      borderRadius='4px'
      isDisabled={!hasPermission('Project_Create') || !canCreateMoreProjects()}
      rightIcon={<Icon color='reverseText' icon='add_sign' w='4' />}
    >
      <Link to='/editor'>{t('dashboard.welcome.newProjectButton')}</Link>
    </Button>
  );
};
