import { Scene } from '$/services/usecases/scenes';
import { unsecureSeededRandom } from '$/utils/numberUtils';

const popularities = {
  normal: 3,
  liked: 2,
  trending: 1,
};

const popularityMapper = {
  big: {
    gridColumn: 'span 2',
    gridRow: 'span 2',
    height: '100%',
    width: '100%',
    aspectRatio: '25/17',
  },
  wide: {
    gridColumn: 'span 2',
    gridRow: undefined,
    height: 'auto',
    width: '100%',
    aspectRatio: '50/17',
  },
  tall: {
    gridColumn: undefined,
    gridRow: 'span 2',
    height: '100%',
    width: undefined,
    aspectRatio: '25/34',
  },
  normal: {
    gridColumn: undefined,
    gridRow: undefined,
    height: '100%',
    width: '100%',
    aspectRatio: '25/17',
  },
};

export const getRandomPopularity = (seed: number) => {
  const random = unsecureSeededRandom(seed);
  if (random < 0.05) return popularities.trending;
  if (random < 0.25) return popularities.liked;
  return popularities.normal;
};

export const createPopularityRank = (scenes: Scene[]) => {
  const sortedScenes = [...scenes].sort((a, b) => b.clicks - a.clicks);
  return (scene: Scene) => {
    const position = sortedScenes.findIndex((s) => s.id === scene.id);

    const trendingThreshold = Math.floor(0.075 * scenes.length);
    const likedThreshold = Math.floor(0.25 * scenes.length);

    let popularity = popularities.normal;

    if (position < likedThreshold) popularity = popularities.liked;
    if (position < trendingThreshold) popularity = popularities.trending;

    return { ...scene, popularity };
  };
};

export const popularityToImageSize = (popularity: number, id: string) => {
  if (popularity === popularities.trending) {
    return popularityMapper.big;
  }

  if (popularity === popularities.liked) {
    return unsecureSeededRandom(parseInt(id)) < 0.5
      ? popularityMapper.wide
      : popularityMapper.tall;
  }

  return popularityMapper.normal;
};
