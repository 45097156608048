import { Box, UnorderedList, ListItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const SupportedFormats = () => {
  const { t } = useTranslation();

  return (
    <Box w='full'>
      {t('editor.supported_formats')}
      <UnorderedList mb='10' pl='3'>
        <ListItem>JPG</ListItem>
        <ListItem>PNG</ListItem>
      </UnorderedList>
    </Box>
  );
};
