import { Skeleton } from '@chakra-ui/react';

import { DropdownItem } from '$/components/common/Dropdown/DropdownItem';
import { repeat } from '$/utils/arrayUtils';

export const SearchDropdownSkeleton = () => {
  return repeat(10).map((_, i) => (
    <DropdownItem key={i} px='5' py='1px'>
      <Skeleton>XXXXXXXXXX</Skeleton>
    </DropdownItem>
  ));
};
