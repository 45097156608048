import { GridItem, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { PerspectiveImage } from '$/pages/InspirationEditorPage/components/LayerSection/components/PerspectiveSection/components/PerspectiveImage';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { Scene } from '$/services/usecases/scenes';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  scene?: Scene | null;
}

export const PerspectiveSection: FC<Props> = ({ scene }) => {
  const { t } = useTranslation();
  const activeViewIndex = useInspirationEditorStore.useActiveViewIndex();
  const setActiveSceneIndex =
    useInspirationEditorStore.useSetActiveSceneIndex();

  const resetZoom = useActionBarStore.useResetZoom();

  const onChangePerspective = (index: number) => {
    setActiveSceneIndex(index);
    resetZoom();
  };

  return (
    <Stack w='full' px='5' pb='4'>
      <Text fontWeight='bold'>{t('inspirationEditor.perspectives')}</Text>
      <SimpleGrid w='full' h='56px' columns={3}>
        <For
          each={scene?.views}
          fallback={() =>
            repeat(3).map((key) => (
              <Skeleton key={key} mx='4px' borderRadius='4px' />
            ))
          }
        >
          {(view, index) => (
            <GridItem
              key={view}
              h='inherit'
              p='2px'
              border='2px'
              borderColor={activeViewIndex === index ? 'text' : 'transparent'}
              borderRadius='4px'
              cursor='pointer'
              onClick={() => onChangePerspective(index)}
            >
              <PerspectiveImage id={scene!.id} view={view} />
            </GridItem>
          )}
        </For>
      </SimpleGrid>
    </Stack>
  );
};
