import { array, number, object, string } from 'zod';

export const FandeckInfoSchema = object({
  status: number(),
  collection: string(),
  page: number(),
  sort: string().optional(),
  page_list: array(string()),
}).transform((data) => {
  if (data.status === 0) return [];

  return data.page_list;
});
