import {
  Box,
  Divider,
  HStack,
  IconButton,
  Skeleton,
  Stack,
  StackProps,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';
import { BrandMenu } from '$/components/core/BrandMenu';
import { StyledLink } from '$/components/core/StyledLink';

interface Props {
  projectName?: string;
  children: ReactNode;
}

export const LayerSidebar: FC<StackProps & Props> = ({
  projectName,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      layerStyle='EditorFloatingSidebar'
      pos='fixed'
      bottom='0'
      w='16rem'
      m='4'
      fontSize='sm'
      {...rest}
    >
      <Box pl='4'>
        <BrandMenu />
      </Box>

      <Divider />

      <HStack gap='3' w='full' px='3'>
        <StyledLink link={{ to: '/' }}>
          <IconButton
            minW='0'
            h='auto'
            minH='0'
            p='5px'
            aria-label={t('editor.backToHome')}
            icon={<Icon icon='home' fill='editor.icon' />}
            variant='text'
          />
        </StyledLink>
        <Show
          when={projectName}
          fallback={() => <Skeleton>424242424242</Skeleton>}
        >
          <Tooltip hasArrow label={projectName} placement='top'>
            <Text fontWeight='bold' textOverflow='ellipsis' isTruncated>
              {projectName}
            </Text>
          </Tooltip>
        </Show>
      </HStack>

      <Divider />

      {children}
    </Stack>
  );
};
