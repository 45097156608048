import { Button, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { HtmlContent } from '$/components/common/HtmlContent';
import { StyledLink } from '$/components/core/StyledLink';

interface Props {
  contentEndpoint: string;
}

export const HtmlContentPage: FC<Props> = ({ contentEndpoint }) => {
  const { t } = useTranslation();
  return (
    <VStack>
      <Button as={StyledLink} mt='10' link={{ to: '/', search: '' }}>
        {t('general.backHome')}
      </Button>
      <HtmlContent contentEndpoint={contentEndpoint} />
    </VStack>
  );
};
