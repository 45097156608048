import { Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  title: string;
}

export const LoadingDropdown: FC<Props> = ({ title }) => {
  return (
    <Skeleton h='32px' fontSize='sm' rounded='md'>
      {title}
    </Skeleton>
  );
};
