import { Button, Heading, Stack, Text } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

export const ChangePasswordSuccessModal = () => {
  const { t } = useTranslation();

  return (
    <Stack align='center' gap='3.5rem'>
      <Heading mb='0.5rem' fontSize='3xl' textAlign='center'>
        {t('dashboard.authentication.changePassword.successTitle')}
      </Heading>
      <Stack gap='1.5rem'>
        <Icon icon='leaderboard' w='65px' h='65px' mx='auto' />
        <Text color='lighterText' fontSize='sm'>
          {t('dashboard.authentication.changePassword.successDescription')}
        </Text>
      </Stack>
      <Link to='/'>
        <Button>{t('general.backHomeShort')}</Button>
      </Link>
    </Stack>
  );
};
