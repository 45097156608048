import { endpointFactory } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { FandeckInfoSchema } from '$/services/usecases/fandeck/mapper/fandeck';

interface Payload {
  collection: string;
  page: number;
  signal: AbortSignal;
}

export const getFandeckInfo = async ({ collection, page, signal }: Payload) => {
  return await fetcher(endpointFactory.fandeckInfo({ collection, page }), {
    signal,
    parser: (data) => FandeckInfoSchema.parse(data),
    msg: `Could not get the fandeck info for collection ${collection} page ${page}`,
  });
};
