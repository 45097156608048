import { Grid } from '@chakra-ui/react';
import { Await } from '@tanstack/react-router';
import { Suspense } from 'react';

import { For } from '$/components/common/Flow/For';
import { SceneImage } from '$/pages/DashboardPages/pages/Inspiration/components/SceneImage';
import { LoadingSceneImage } from '$/pages/DashboardPages/pages/Inspiration/components/SceneImage/LoadingSceneImage';
import { useInspirationStore } from '$/pages/DashboardPages/pages/Inspiration/stores/useInspirationStore';
import { createPopularityRank } from '$/pages/DashboardPages/pages/Inspiration/utils/popularityUtils';
import { InspirationsRoute } from '$/routes/Inspirations/InspirationsRoute';
import { materialTemplateColumn } from '$/theme/utils/templateColumn';
import { repeat } from '$/utils/arrayUtils';

export const Scenes = () => {
  const { data } = InspirationsRoute.useLoaderData();

  const activeCategory = useInspirationStore.useActiveCategory();

  const getShuffledScenes = (scenes: Awaited<typeof data>['scenes']) => {
    const sortedScenes = Object.entries(scenes)
      .filter(([key]) => (activeCategory ? key === activeCategory.id : true))
      .flatMap((item) => item[1]);

    return sortedScenes.map(createPopularityRank(sortedScenes));
  };

  return (
    <Grid
      gap={{ base: '3', md: '6' }}
      autoFlow='dense'
      templateColumns={materialTemplateColumn()}
    >
      <Suspense
        fallback={repeat(50).map((id) => (
          <LoadingSceneImage key={id} id={id} />
        ))}
      >
        <Await promise={data}>
          {({ scenes }) => (
            <For each={getShuffledScenes(scenes)}>
              {(scene) => (
                <SceneImage
                  key={scene.id}
                  id={scene.id}
                  name={scene.name}
                  popularity={scene.popularity}
                />
              )}
            </For>
          )}
        </Await>
      </Suspense>
    </Grid>
  );
};
