import { QueryClient } from '@tanstack/react-query';

import { logError } from '$/logger';

export const STANDARD_STALE_TIME = 1000 * 60 * 10;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

export const fetcher = async <T>(
  url: string,
  {
    msg,
    parser,
    signal,
    body,
    method = 'GET',
    includeCredentials = false,
  }: {
    msg: string;
    parser: (data: unknown) => T;
    signal?: AbortSignal;
    body?: RequestInit['body'] | object;
    method?: RequestInit['method'];
    includeCredentials?: boolean;
  },
): Promise<T> => {
  const response = await fetch(url, {
    signal,
    body: typeof body === 'object' ? JSON.stringify(body) : body,
    method,
    headers: method === 'POST' ? { 'Content-Type': 'application/json' } : {},
    credentials: includeCredentials ? 'include' : 'omit',
  });

  // await new Promise((resolve) => setTimeout(resolve, 2000));

  if (response.status !== 200) {
    logError([msg, response.status, await response.text()]);
    throw new Error(msg);
  }

  try {
    return parser(await response.json());
  } catch (e) {
    logError([msg, e, response.body]);
    throw new Error(msg);
  }
};

export const parser = <T>(data: unknown, parser: (data: unknown) => T): T => {
  try {
    return parser(data);
  } catch (e) {
    logError([e]);
    throw new Error(String(e));
  }
};
