import { useEffect } from 'react';

export const useCookieDeclaration = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'CookieDeclaration';
    script.src =
      'https://consent.cookiebot.com/5aada34a-d344-4fce-a2f2-b04854c1c851/cd.js';
    script.type = 'text/javascript';
    script.async = true;

    const container = document.getElementById('cookie-declaration');

    container?.appendChild(script);

    return () => {
      container?.removeChild(script);
    };
  }, []);
};
