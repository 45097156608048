import { HStack, Spinner, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { Show } from '$/components/common/Flow/Show';
import { Icon } from '$/components/common/Icon';

interface Props {
  prefix: ReactNode;
  isActive: boolean;
  header: string;
  onClick?: () => void;
  isLoading?: boolean;
  isCollapsible?: boolean;
}

export const FilterHeader: FC<Props> = ({
  isActive,
  header,
  prefix,
  onClick,
  isLoading = false,
  isCollapsible = false,
}) => {
  return (
    <HStack
      justify='space-between'
      w='full'
      cursor={onClick ? 'pointer' : 'default'}
      onClick={() => !isLoading && onClick && onClick()}
    >
      <HStack>
        {prefix}
        <Text color='lighterText' fontSize='sm' fontWeight='bold'>
          {header}
        </Text>
      </HStack>

      <Show when={isCollapsible}>
        <Show
          when={!isLoading}
          fallback={() => (
            <Spinner w='13px' h='13px' emptyColor='neutral.1000/30' />
          )}
        >
          <Icon
            icon='chevron_down'
            transform={`rotate(${isActive ? '180deg' : '0deg'})`}
            width='14px'
          />
        </Show>
      </Show>
    </HStack>
  );
};
