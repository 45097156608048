import { forwardRef, ButtonProps, Button } from '@chakra-ui/react';

export const FilterButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
  <Button
    ref={ref}
    layerStyle='FilterButton'
    justifyContent='start'
    color='text'
    bg={props.isActive ? 'secondaryBackground' : 'transparent'}
    variant='filter'
    {...props}
  />
));
