import { List } from '@chakra-ui/react';
import { Droppable } from '@hello-pangea/dnd';
import { FunctionComponent } from 'react';

import { ComponentObjectListItem } from '$/pages/EditorPage/components/ComponentSection/ComponentObjectListItem';
import { ComponentLayer } from '$/services/usecases/editor/mapper/editorStatus';

interface IComponentObjectListProps {
  parentIndex: number;
  componentObjects: ComponentLayer[];
}

export const ComponentObjectList: FunctionComponent<
  IComponentObjectListProps
> = ({ componentObjects, parentIndex }) => {
  return (
    <Droppable
      droppableId={`objectList-${parentIndex}`}
      type={`editorComponentObject-${parentIndex}`}
    >
      {(provided) => (
        <List ref={provided.innerRef}>
          {componentObjects
            .slice()
            .reverse()
            .map((componentObject, index) => (
              <ComponentObjectListItem
                componentObject={componentObject}
                dragIndex={index}
                key={componentObject.index}
                index={componentObject.index}
                parentIndex={parentIndex}
              />
            ))}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  );
};
