import { Text } from '@chakra-ui/react';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ConfirmationForm } from '$/pages/Profile/pages/DeleteAccountModal/components/ConfirmationForm';
import { DeleteAccountHeader } from '$/pages/Profile/pages/DeleteAccountModal/components/DeleteAccountHeader';
import { ProfileRoute } from '$/routes/Account/ProfileRoute';

interface Props {
  onReturn: () => void;
}

export const DeleteAccountModal: FC<Props> = ({ onReturn }) => {
  const { t } = useTranslation();

  const { data } = ProfileRoute.useLoaderData();

  return (
    <>
      <DeleteAccountHeader onReturn={onReturn} />

      <Icon icon='sad_emoji' w='80px' h='80px' />
      <Text fontSize='sm'>
        <Trans
          i18nKey='dashboard.authentication.deleteAccount.instruction'
          values={{
            email: data.email,
            confirmation: t(
              'dashboard.authentication.deleteAccount.confirmation',
            ),
          }}
        />
      </Text>

      <ConfirmationForm />
    </>
  );
};
