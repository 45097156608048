import { Box, Skeleton } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  title: string;
}

export const FilterSkeleton: FC<Props> = ({ title }) => {
  return (
    <Skeleton
      h={{ base: '35px', sm: '24px' }}
      minH={{ base: '35px', sm: '24px' }}
      px={{ base: '4', sm: '0' }}
      borderRadius={{ base: 'full', sm: 'md' }}
    >
      <Box pr='3'>{title}</Box>
    </Skeleton>
  );
};
