import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useToast } from '$/hooks/useToast';
import { ExportOptionsSelection } from '$/pages/InspirationEditorPage/components/ExportModal/ExportOptionsSelection';
import {
  ExportType,
  FileTypeSelection,
} from '$/pages/InspirationEditorPage/components/ExportModal/FileTypeSelection';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export interface Props {
  isOpen: boolean;
  onClose: VoidFunction;
}

export type ExportFormData = {
  exportType: ExportType;
  variants: number[];
  perspectives: number[];
};

const reducer = (state: ExportFormData, newData: Partial<ExportFormData>) => {
  return { ...state, ...newData };
};

export const ExportInspirationModal: FC<Props> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();
  const scene = useInspirationEditorStore.useScene();
  const sceneVariantLabelOrder =
    useInspirationEditorStore.useSceneVariantLabelOrder();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [state, dispatch] = useReducer(reducer, {
    exportType: 'jpg',
    variants: [0],
    perspectives: [0],
  });

  const onExport = async () => {
    setIsLoading(true);
    if (scene != null) {
      try {
        const exportModule = await import(
          '$/pages/InspirationEditorPage/components/ExportModal/utils/ExportHelper'
        );
        await exportModule.generateExportZipFolderWithContent(
          state,
          scene,
          sceneLayersVariants,
          sceneVariantLabelOrder,
          t,
        );
      } catch (error: unknown) {
        console.error(error);
        toast(
          'Something went wrong',
          'error',
          'There was an error generating your export',
        );
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: 'full', sm: 'xl' }}>
      <ModalOverlay />
      <ModalContent minW='31rem'>
        <ModalHeader p='10'>
          <HStack alignItems='baseline' justifyContent='space-between'>
            <Box as='span' fontSize='2xl'>
              {t('editor.exportText')}
            </Box>
            <ModalCloseButton
              pos='relative'
              top='auto'
              right='auto'
              w='8'
              h='8'
            />
          </HStack>
        </ModalHeader>
        <ModalBody px='10' pt='3' pb='10'>
          <FileTypeSelection
            exportType={state.exportType}
            dispatch={dispatch}
          />
          <ExportOptionsSelection state={state} dispatch={dispatch} />
          <HStack justifyContent='center' mt='3.5rem'>
            <Button
              isDisabled={
                state.variants.length == 0 || state.perspectives.length == 0
              }
              isLoading={isLoading}
              onClick={onExport}
              variant='primary'
            >
              {t('editor.exportText')}
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
