import { HStack, IconButton, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ModuleSendSelectAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';

interface Props {
  title: string;
}

export const TitleSection: FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  const { selectAction } = useEditorActions();

  return (
    <HStack justify='space-between' w='full' px='4' pt='1'>
      <Text fontWeight='bold'>{title}</Text>
      <IconButton
        boxSize='4'
        aria-label={t('editor.selectMode')}
        icon={<Icon icon='close' boxSize='3' />}
        onClick={() => selectAction(ModuleSendSelectAction.SelectPoly)}
        variant='text'
      />
    </HStack>
  );
};
