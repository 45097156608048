import { IconButton } from '@chakra-ui/react';
import { FC } from 'react';

import { Icon } from '$/components/common/Icon';
import { useActionBarStore } from '$/components/core/Editor/ActionBar/store/useActionBarStore';
import { useHoverAndActiveState } from '$/pages/InspirationEditorPage/hooks/useHoverAndActiveState';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

interface Props {
  markerId: string;
}

export const ImageMarkerButton: FC<Props> = ({ markerId }) => {
  const activeId = useInspirationEditorStore.useActiveComponentId();

  const scaling = useActionBarStore.useZoomLevel();

  const changeComponent = useInspirationEditorStore.useChangeComponent();
  const getActiveHoverProps = useHoverAndActiveState(() => {
    changeComponent(markerId);
  });

  const dimensions = scaling <= 0.25 ? `${40 * scaling * 3}px` : '40px';

  return (
    <IconButton
      w={dimensions}
      minW={dimensions}
      h={dimensions}
      bg='background'
      transition='width 0.2s, height 0.2s, min-width 0.2s'
      aria-label={markerId}
      icon={
        <Icon
          fill={activeId === markerId ? 'primaryButton.color' : 'text'}
          icon='drop'
          h='70%'
          maxH='16px'
        />
      }
      {...getActiveHoverProps(markerId, 'background', true)}
    />
  );
};
