import { Box, Flex, Grid, Skeleton } from '@chakra-ui/react';

export const LoadingProjectCard = () => {
  return (
    <Box pos='relative'>
      <Grid
        templateRows='1fr auto'
        overflow='hidden'
        border='1px solid'
        borderColor='border'
        borderRadius='12px'
        aspectRatio='3/2'
      >
        <Skeleton />
        <Flex
          justify='center'
          gap='3'
          p='2'
          borderTop='1px solid'
          borderTopColor='border'
        >
          <Skeleton w='50%' fontSize='sm'>
            AAAAAA
          </Skeleton>
        </Flex>
      </Grid>
    </Box>
  );
};
