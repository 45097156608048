import { Heading, VStack } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { TrackedButton } from '$/components/common/Button/TrackedButton';
import { Icon } from '$/components/common/Icon';

export const ProfileHeader = () => {
  const { t } = useTranslation();

  return (
    <VStack align='flex-start'>
      <Link to='/'>
        <TrackedButton
          gap='2'
          px='0'
          fontSize='sm'
          leftIcon={
            <Icon icon='arrow_right' w='14px' transform='rotate(180deg)' />
          }
          variant='text'
          contentName='CloudAccount'
          contentPiece='ManageAccount'
          contentTarget='Close'
        >
          {t('dashboard.authentication.profile.backToDashboard')}
        </TrackedButton>
      </Link>
      <Heading fontSize='4xl'>
        {t('dashboard.authentication.profile.yourProfile')}
      </Heading>
    </VStack>
  );
};
