import { Box, HStack, Stack, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLink, TypedToOptions } from '$/components/core/StyledLink';
import { useIsMobile } from '$/hooks/useIsMobile';

interface Props {
  headline: string;
  showAllUrl?: TypedToOptions;
  children: ReactNode;
  customActionElement?: ReactNode;
}

export const DashboardSection: FC<Props> = ({
  showAllUrl,
  headline,
  children,
  customActionElement,
}) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Stack alignItems='flex-start' gap='3' w='full'>
      <HStack
        justify='space-between'
        flexWrap='wrap'
        w={isMobile ? 'full' : 'calc(100% - 15px)'}
        fontWeight='bold'
      >
        <Text pr='8'>{headline}</Text>
        {showAllUrl && (
          <StyledLink
            link={{ to: showAllUrl, params: {}, search: {} }}
            px='0'
            color='lighterText'
            fontSize='sm'
            borderRadius='4'
            _hover={{ bg: 'bodyBackground' }}
          >
            {t('dashboard.welcome.showAllButton')}
          </StyledLink>
        )}
        {customActionElement}
      </HStack>
      <Box w='full'>{children}</Box>
    </Stack>
  );
};
