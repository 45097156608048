import { Await, Link } from '@tanstack/react-router';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';

import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { DashboardSection } from '$/pages/DashboardPages/components/DashboardSection';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { DashboardRoute } from '$/routes/DashboardRoute';
import { repeat, shuffle } from '$/utils/arrayUtils';

export const CollectionSection = () => {
  const { t } = useTranslation();

  const { materials } = DashboardRoute.useLoaderData();

  return (
    <DashboardSection
      headline={t('dashboard.welcome.collectionsText')}
      showAllUrl='/collections'
    >
      <DashboardSectionRow>
        <Suspense
          fallback={repeat(4).map((i) => (
            <LoadingMaterialCard key={i} />
          ))}
        >
          <Await promise={materials}>
            {({
              colorMaterial,
              floorMaterial,
              wallMaterial,
              structureMaterial,
            }) => {
              const cards = [
                <Link to='/collections/colors' key={colorMaterial.id}>
                  <MaterialCard
                    key={colorMaterial.id}
                    material={colorMaterial}
                    labelOverwrite={t(
                      'dashboard.welcome.collection.colorTones',
                    )}
                    editMode='none'
                    cursor='pointer'
                  />
                </Link>,
                <Link to='/collections/floors' key={floorMaterial.id}>
                  <MaterialCard
                    material={floorMaterial}
                    key={floorMaterial.id}
                    labelOverwrite={t(
                      'dashboard.welcome.collection.floorCoverings',
                    )}
                    editMode='none'
                    cursor='pointer'
                  />
                </Link>,
                <Link to='/collections/walls' key={wallMaterial.id}>
                  <MaterialCard
                    key={wallMaterial.id}
                    material={wallMaterial}
                    labelOverwrite={t(
                      'dashboard.welcome.collection.wallCoverings',
                    )}
                    editMode='none'
                    cursor='pointer'
                  />
                </Link>,
                <Link to='/collections/colors' key={structureMaterial.id}>
                  <MaterialCard
                    key={structureMaterial.id}
                    material={structureMaterial}
                    labelOverwrite={t(
                      'dashboard.welcome.collection.structures',
                    )}
                    editMode='none'
                    cursor='pointer'
                  />
                </Link>,
              ];

              return <>{shuffle(cards)}</>;
            }}
          </Await>
        </Suspense>
      </DashboardSectionRow>
    </DashboardSection>
  );
};
