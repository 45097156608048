import { createRoute } from '@tanstack/react-router';

import { CollectionDashboard } from '$/pages/DashboardPages/pages/Collection';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { FilterType } from '$/services/mapper/uses';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';

const type: FilterType = 'all';

export const CollectionsRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/collections',
  component: CollectionDashboard,
  loader: ({ context: { client } }) => {
    useCollectionStore.setState({
      filter: undefined,
      filterGroups: [],
      activeType: type,
    });

    const { filterGroups } = useCollectionStore.getState();
    void client
      .ensureQueryData(filterOptionsQuery({ type, filter: filterGroups }))
      .then((filter) => useCollectionStore.setState({ filter }));
  },
  staleTime: 0,
});
