import { Container, Grid, GridItem } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-router';
import { FunctionComponent } from 'react';

import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { Header } from '$/components/layouts/BaseDashboardLayout/components/Header';
import { MobileHeader } from '$/components/layouts/BaseDashboardLayout/components/MobileHeader';
import { useIsMobile } from '$/hooks/useIsMobile';

export const HeaderDashboardLayout: FunctionComponent = () => {
  const isMobile = useIsMobile();

  return (
    <Container
      maxW={{ base: 'container.2xl', lg: '100%' }}
      h='100vh'
      p={{ base: '16px', mobile: '32px', xl: '64px' }}
    >
      <Grid
        gap={{ base: '16px', xl: '32px' }}
        templateRows='auto minmax(0, 1fr)'
        h='100%'
      >
        {!isMobile && (
          <GridItem>
            <Header />
          </GridItem>
        )}
        {isMobile && (
          <GridItem>
            <MobileHeader />
          </GridItem>
        )}
        <Outlet />
      </Grid>
      <AuthenticationModal />
    </Container>
  );
};
