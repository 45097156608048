import { Button, ButtonProps } from '@chakra-ui/react';
import { FC } from 'react';

import { trackContentInteraction } from '$/logger';
import {
  PiwikContentName,
  PiwikContentPiece,
  PiwikContentTarget,
} from '$/utils/piwikUtils';

export interface TrackedButtonProps extends ButtonProps {
  contentName?: PiwikContentName;
  contentPiece?: PiwikContentPiece;
  contentTarget?: PiwikContentTarget;
}

export const TrackedButton: FC<TrackedButtonProps> = ({
  contentName,
  contentPiece,
  contentTarget,
  onClick,
  ...props
}) => {
  return (
    <Button
      onClick={(e) => {
        if (contentName && contentPiece && contentTarget) {
          trackContentInteraction(
            'ClickButton',
            contentName,
            contentPiece,
            contentTarget,
          );
        }

        if (onClick) {
          onClick(e);
        }
      }}
      {...props}
    />
  );
};
