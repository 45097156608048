import { Tag, TagProps, forwardRef } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Props extends TagProps {
  children: ReactNode;
}

export const ShowAdditionalFilterTagsButton = forwardRef<Props, 'div'>(
  ({ children, ...tagProps }, ref) => {
    return (
      <Tag
        ref={ref}
        as='button'
        layerStyle='CollectionTag'
        borderRadius='full'
        {...tagProps}
      >
        {children}
      </Tag>
    );
  },
);
