import { Box, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { NoResultCard } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult/components/NoResultCard';
import { usePiwikSearchTracking } from '$/hooks/usePiwikSearchTracking';
import { MaterialListItem } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItem';
import { MaterialListItemSkeleton } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItemSkeleton';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';
import { Material } from '$/services/usecases/materials';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { repeat } from '$/utils/arrayUtils';

export const SearchResult = () => {
  const { t } = useTranslation();

  const query = useMaterialSearchStore.useQuery();
  const setQuery = useMaterialSearchStore.useSetQuery();

  const changeComponentMaterial =
    useInspirationEditorStore.useChangeComponentMaterial();

  const { data, isLoading } = useQuery(materialsQuery({ query }));

  usePiwikSearchTracking(data);

  const handleResultClick = (material: Material) => {
    changeComponentMaterial(material);
    setQuery('');
  };

  return (
    <Stack overflow='hidden' px='5'>
      <Text color='lighterText' fontSize='sm' fontWeight='bold'>
        {t('inspirationEditor.filter.searchResults', {
          amount: data?.materialCount,
        })}
      </Text>
      <Show when={!data && !isLoading}>
        <NoResultCard />
      </Show>
      <Stack gap='3' overflowY='auto' px='5'>
        <For
          each={data?.materials}
          fallback={() =>
            isLoading &&
            repeat(50).map((i) => <MaterialListItemSkeleton key={i} id={i} />)
          }
        >
          {(material, i) => (
            <Box cursor='pointer' onClick={() => handleResultClick(material)}>
              <MaterialListItem id={i} material={material} />
            </Box>
          )}
        </For>
      </Stack>
    </Stack>
  );
};
