import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { FC, RefObject, useEffect, useRef } from 'react';

import { For } from '$/components/common/Flow/For';
import { Show } from '$/components/common/Flow/Show';
import { LoadingMaterialCard } from '$/components/core/Collection/LoadingMaterialCard';
import { MaterialCard } from '$/components/core/Collection/MaterialCard';
import { useMaterialDetailStore } from '$/components/core/Collection/MaterialDetailsModal/store/useMaterialDetailStore';
import { TypedToOptions } from '$/components/core/StyledLink';
import { useMaterialGridMaxItems } from '$/hooks/useMaterialGridMaxItems';
import { useWindowSize } from '$/hooks/useWindowSize';
import { DashboardSection } from '$/pages/DashboardPages/components/DashboardSection';
import { DashboardSectionRow } from '$/pages/DashboardPages/components/DashboardSectionRow';
import { MaterialType } from '$/services/mapper/uses';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { repeat } from '$/utils/arrayUtils';

interface Props {
  headline: string;
  showAllUrl: TypedToOptions;
  type: MaterialType;
  wrapperRef?: RefObject<HTMLDivElement>;
  onResult: (count: number, type: MaterialType) => void;
}

export const CollectionSection: FC<Props> = ({
  headline,
  showAllUrl,
  type,
  wrapperRef,
  onResult,
}) => {
  const setActiveMaterial = useMaterialDetailStore.useSetActive();
  const filter = useCollectionStore.useFilterGroups();

  const materialWrapperRef = useRef<HTMLDivElement>(null);

  const { data, isLoading, isFetching } = useQuery(
    materialsQuery({ filter, limit: 10, type: [type] }),
  );

  useEffect(() => {
    data && onResult(data.materialCount, type);
  }, [data, onResult, type]);

  useWindowSize();

  const materialCardLimit = useMaterialGridMaxItems();

  const wrapperHeaderHeight = 150;
  const sectionHeaderHeight = (36 + 24) * 3;
  const gridGap = 24;

  const wrapperHeight = wrapperRef?.current?.offsetHeight;
  const cardHeight = materialWrapperRef.current?.offsetHeight;

  const possibleCards =
    wrapperHeight && cardHeight
      ? Math.floor(
          (wrapperHeight - sectionHeaderHeight - wrapperHeaderHeight) /
            (cardHeight + gridGap),
        )
      : undefined;

  const rowsPerSection = possibleCards
    ? Math.max(1, Math.ceil(possibleCards / 3))
    : undefined;

  const visibleCards =
    rowsPerSection && materialCardLimit
      ? materialCardLimit * rowsPerSection
      : undefined;

  return (
    <Show when={isLoading || (data && data.materials.length > 0)}>
      <DashboardSection headline={headline} showAllUrl={showAllUrl}>
        <DashboardSectionRow>
          <For
            each={
              data?.materials.slice(0, visibleCards ?? materialCardLimit) ?? []
            }
            isLoading={isLoading || isFetching}
            empty={() => null}
            fallback={() =>
              repeat(materialCardLimit ?? 0).map((_, key) => (
                <LoadingMaterialCard key={key} />
              ))
            }
          >
            {(material, index) => (
              <Box
                key={index}
                ref={index === 0 ? materialWrapperRef : undefined}
              >
                <MaterialCard
                  material={material}
                  onClick={() => setActiveMaterial(material)}
                  materialType={type}
                />
              </Box>
            )}
          </For>
        </DashboardSectionRow>
      </DashboardSection>
    </Show>
  );
};
