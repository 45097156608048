import { IconButton } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ModuleSendBrushShapeAction } from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

interface Props {
  type: 'round' | 'square';
  mode?: ModuleSendBrushShapeAction;
}

export const BrushShapeOptionButton: FC<Props> = ({
  type,
  mode = ModuleSendBrushShapeAction.BrushShape,
}) => {
  const { t } = useTranslation();
  const { setBrushShape } = useEditorActions();

  const activeBrushType = useEditorStore.useBrushType();
  const activeRubberType = useEditorStore.useRubberType();

  const isActive =
    (mode === ModuleSendBrushShapeAction.BrushShape &&
      activeBrushType === type) ||
    (mode === ModuleSendBrushShapeAction.RubberShape &&
      activeRubberType === type);

  return (
    <IconButton
      bg={isActive ? 'visualization.transparentBlue' : 'transparent'}
      border='1px solid'
      borderColor={isActive ? 'visualization.blue' : 'border'}
      aria-label={t(type === 'round' ? 'editor.circle' : 'editor.rectangle')}
      icon={
        <Icon icon={type === 'round' ? 'shape_circle' : 'shape_rectangle'} />
      }
      onClick={() => setBrushShape(mode, type)}
      variant='text'
    />
  );
};
