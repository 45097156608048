import { redirect } from '@tanstack/react-router';

import { useAuthenticationStore } from '$/components/core/Authentication/stores/useAuthenticationStore';
import { User } from '$/services/usecases/authentication/mapper/jwt';
import { NonNullableFields } from '$/utils/typeUtils';

export const routeAuthGuard = () => {
  const { user } = useAuthenticationStore.getState();

  if (!user || !user.data) {
    throw redirect({ to: '/not-allowed', replace: true });
  }

  return { data: user.data, role: user.role };
};

export const authLoader = () => {
  const { user } = useAuthenticationStore.getState();
  return user as NonNullableFields<User>;
};
