import { createRoute, defer } from '@tanstack/react-router';

import { InspirationDashboard } from '$/pages/DashboardPages/pages/Inspiration';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { STANDARD_STALE_TIME } from '$/services/fetcher';
import {
  categoriesQuery,
  scenesQuery,
} from '$/services/usecases/scenes/queries';

export const InspirationsRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/inspirations',
  component: InspirationDashboard,
  loader: ({ context: { client } }) => {
    const data = async () => ({
      categories: await client.ensureQueryData(categoriesQuery),
      scenes: await client.ensureQueryData(scenesQuery),
    });

    return { data: defer(data()) };
  },
  staleTime: STANDARD_STALE_TIME,
});
