import { HStack, Stack, StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { BrightnessInputElement } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessInputElement';
import { BrightnessSlider } from '$/components/core/Collection/CollectionFilter/components/BrightnessFilter/BrightnessSlider';
import { FilterHeader } from '$/components/core/Editor/ColorSelectionSidebar/Filter/FilterHeader';
import { useDebouncedState } from '$/hooks/useDebouncedState';
import { useCollectionStore } from '$/stores/useCollectionStore';

export const BrightnessFilter: FC<StackProps> = (props) => {
  const { t } = useTranslation();

  const filter = useCollectionStore.useFilter();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const setBrightnessFilter = useCollectionStore.useSetBrightnessFilter();

  const [value, setValue] = useDebouncedState<number[]>(
    [brightnessFilter.from, brightnessFilter.to],
    (val) => setBrightnessFilter({ from: val[0], to: val[1] }),
    250,
  );

  return (
    <Stack align='center' {...props}>
      <FilterHeader
        header={t('dashboard.collection.filter.brightness')}
        prefix={<Icon icon='brightness' w='14px' />}
        isActive={true}
        isLoading={filter === undefined}
      />

      <Stack w='90%'>
        <BrightnessSlider value={value} setValue={setValue} />
        <HStack justify='space-between'>
          <BrightnessInputElement
            value={value[0]}
            validate={(curr) => curr >= 0 && curr <= 100 && curr < value[1]}
            transformer={(curr) => (curr <= 0 ? 0 : value[1] - 1)}
            onChange={(newValue) => setValue([newValue, value[1]])}
            w='50px'
          />
          <BrightnessInputElement
            value={value[1]}
            validate={(curr) => curr >= 0 && curr <= 100 && curr > value[0]}
            transformer={(curr) => (curr >= 100 ? 100 : value[0] + 1)}
            onChange={(newValue) => setValue([value[0], newValue])}
            w='50px'
          />
        </HStack>
      </Stack>
    </Stack>
  );
};
