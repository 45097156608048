import { useQuery } from '@tanstack/react-query';

import { FavoriteItem } from '$/pages/DashboardPages/pages/Favorite';
import { MaterialType } from '$/services/mapper/uses';
import { localFilteredMaterialsQuery } from '$/services/usecases/materials/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { DEFAULTFOLDERNAME, useFavoriteStore } from '$/stores/useFavoriteStore';

export const useGroupedFavorites = (
  parentFolder: string,
  types?: MaterialType[],
  disableFilters: boolean = false,
) => {
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const favorites = useFavoriteStore.useFavorites();
  const favoriteIds = favorites.map((favorite) => favorite.materialId);

  const { data, isLoading } = useQuery(
    localFilteredMaterialsQuery({
      ids: favoriteIds,
      filter: disableFilters ? undefined : filter,
      brightnessFilter,
      type: types,
    }),
  );

  const hasNoMatchingMaterials =
    (parentFolder === DEFAULTFOLDERNAME && data?.materials.length === 0) ||
    data?.unfilteredMaterials.length === 0;

  if (isLoading || hasNoMatchingMaterials || favorites.length === 0) {
    return {
      isLoading,
      allFavorites: [] as FavoriteItem[],
      colorFavorites: [] as FavoriteItem[],
      wallFavorites: [] as FavoriteItem[],
      floorFavorites: [] as FavoriteItem[],
    };
  }

  const allFavorites = favorites.reduce<FavoriteItem[]>(
    (existingFavorites, current) => {
      if (!current.parentFolderIds.includes(parentFolder))
        return existingFavorites;

      const material =
        parentFolder === DEFAULTFOLDERNAME
          ? data?.materials.find(
              (material) => material?.uniqueKey === current.materialId,
            )
          : data?.unfilteredMaterials.find(
              (material) => material?.uniqueKey === current.materialId,
            );

      if (material != null) {
        existingFavorites.push({
          material,
          parentFolders: current.parentFolderIds,
        } as FavoriteItem);
      }

      return existingFavorites;
    },
    [],
  );

  const colorFavorites = allFavorites.filter((favorite) =>
    favorite.material.type.includes('uni'),
  );

  const wallFavorites = allFavorites.filter((favorite) =>
    favorite.material.type.includes('wall'),
  );

  const floorFavorites = allFavorites.filter((favorite) =>
    favorite.material.type.includes('floor'),
  );

  return {
    isLoading: false,
    allFavorites,
    colorFavorites,
    wallFavorites: wallFavorites,
    floorFavorites: floorFavorites,
  };
};
