import { Drawer, DrawerOverlay, DrawerContent } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

export const MobileDrawer: FC<Props> = ({ children, isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement='bottom' size='full'>
      <DrawerOverlay />
      <DrawerContent
        overflowY='auto'
        maxH='98dvh'
        bg='background'
        borderRadius='12px'
      >
        {children}
      </DrawerContent>
    </Drawer>
  );
};
