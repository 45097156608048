import { HStack, Stack, StackProps, Text } from '@chakra-ui/react';
import { FC } from 'react';

import { MaterialListItemSkeleton } from '$/pages/InspirationEditorPage/components/AttributeSection/components/SceneDetails/components/MaterialListItemSkeleton';
import { ColorBox } from '$/pages/InspirationEditorPage/components/ColorBox';
import { Material } from '$/services/usecases/materials';
import { getColorBrightness } from '$/utils/colorUtils';

interface Props {
  id: number;
  material?: Material | null;
  showAsActive?: boolean;
  onClick?: () => void;
}

export const MaterialListItem: FC<Props> = ({
  material,
  id,
  showAsActive = false,
  onClick,
}) => {
  if (!material) return <MaterialListItemSkeleton id={id} />;

  const brightness = getColorBrightness(material.color ?? 'transparent');

  const borderColor = () => {
    if (showAsActive) return 'text';
    if (brightness > 200) return 'border';
    return 'transparent';
  };

  const clickableElementProps: StackProps =
    onClick != null
      ? {
          onClick,
          cursor: 'pointer',
          as: 'button',
        }
      : {};

  return (
    <HStack {...clickableElementProps} textAlign='start'>
      <ColorBox
        w='24px'
        h='24px'
        materialColor={material.color}
        image={material.url()}
        border='1px solid'
        borderColor={borderColor()}
      />
      <Stack gap='2px'>
        <Text fontSize='sm' lineHeight='1.2'>
          {material.info}
        </Text>
        <Text color='lighterText' fontSize='12px' lineHeight='1.2'>
          {material.collection}
        </Text>
      </Stack>
    </HStack>
  );
};
