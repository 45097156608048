import { Divider, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import { Show } from '$/components/common/Flow/Show';
import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { SearchInput } from '$/components/core/Collection/MaterialSearch/SearchInput';
import { useMaterialSearchStore } from '$/components/core/Collection/MaterialSearch/store/useMaterialSearchStore';
import { ExportSection } from '$/components/core/Editor/AttributeSidebar/components/ExportSection';
import { ShareSection } from '$/components/core/Editor/AttributeSidebar/components/ShareSection';
import { ShowFullscreenSection } from '$/components/core/Editor/AttributeSidebar/components/ShowFullscreenSection';
import { ColorGrid } from '$/components/core/Editor/ColorSelectionSidebar/ColorGrid';
import { CurrentMaterialSection } from '$/components/core/Editor/ColorSelectionSidebar/CurrentMaterialSection';
import { FanPageSelector } from '$/components/core/Editor/ColorSelectionSidebar/FanPageSelector';
import { FavoriteFolderList } from '$/components/core/Editor/ColorSelectionSidebar/FavoriteFolderList';
import { FavoriteFolderView } from '$/components/core/Editor/ColorSelectionSidebar/FavoriteFolderView';
import { BrightnessFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/BrightnessFilter';
import { CategoryFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/CategoryFilter';
import { ColorFilter } from '$/components/core/Editor/ColorSelectionSidebar/Filter/ColorFilter';
import { SearchResult } from '$/components/core/Editor/ColorSelectionSidebar/SearchResult';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';
import { Material } from '$/services/usecases/materials';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

export const ColorSelectionSidebar = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();
  const activeFolderId = useFavoriteStore.useActiveFolderId();
  const { setMaterial, setColorChecker } = useEditorActions();

  const setMaterialState = useEditorStore.useSetMaterial();
  const activeComponentId = useEditorStore.useActiveComponentIndex();
  const components = useEditorStore.useComponents();
  const colorCheckerEnabled = useEditorStore.useColorCheckerEnabled();

  const activeComponent =
    activeComponentId == null ? null : components[activeComponentId];

  const { data } = useQuery(
    materialsQuery({
      ids: activeComponent?.materialId
        ? [activeComponent?.materialId]
        : undefined,
    }),
  );

  const material = data?.materials.length === 1 ? data.materials[0] : null;

  const onChangeMaterial = (material: Material) => {
    setMaterial(material.id);
    if (activeComponentId != null) {
      setMaterialState(activeComponentId, material.id);
    }
  };

  const query = useMaterialSearchStore.useQuery();

  if (activeFolderId != null) {
    return (
      <FavoriteFolderView
        activeMaterialId={material?.id}
        onClickItem={(material) => setMaterial(material.id)}
      />
    );
  }

  // TODO Colorchecker clean code
  return (
    <>
      <ShareSection />
      <Divider />
      <Stack gap='0' w='full' minW='0' h='35px' px='5'>
        <SearchInput
          searchLabel={t('inspirationEditor.attributes.search')}
          h='48px'
        />
      </Stack>
      <Show when={query.length < 3} fallback={SearchResult}>
        <Divider />
        <CurrentMaterialSection material={material} px='5' />
        <FanPageSelector material={material} onColorChange={onChangeMaterial} />
        <CategoryFilter px='5' w='full' />
        <Divider />
        <ColorFilter px='5' w='full' />
        <BrightnessFilter px='5' w='full' />
        <Divider />
        <ColorGrid
          material={material}
          onColorChange={onChangeMaterial}
          px='5'
        />
        <Divider />
        <Show when={hasPermission('Favorites_Select')}>
          <FavoriteFolderList />
          <Divider />
        </Show>
        <HStack justify='space-between' px='5' fontSize='sm'>
          <Text>Colorchecker</Text>
          <Switch
            isChecked={colorCheckerEnabled}
            onChange={(e) => setColorChecker(e.target.checked)}
          />
        </HStack>
        <Divider />
        <ExportSection />
        <Divider />
        <ShowFullscreenSection />
      </Show>
    </>
  );
};
