import { FC, RefObject } from 'react';

import { DesktopFilterMenu } from '$/components/core/Collection/CollectionFilter/components/DesktopFilterMenu';
import { MobileFilterDrawer } from '$/components/core/Collection/CollectionFilter/components/MobileFilter/MobileFilterDrawer';
import { useIsMobile } from '$/hooks/useIsMobile';
import { FilterType } from '$/services/mapper/uses';

interface Props {
  sectionRef: RefObject<HTMLDivElement>;
  type: FilterType;
  hideAmounts?: boolean;
  amount: number | null;
}

export const CollectionFilter: FC<Props> = ({
  sectionRef,
  type,
  hideAmounts = false,
  amount,
}) => {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <MobileFilterDrawer type={type} amount={amount} />;
  }

  return (
    <DesktopFilterMenu
      sectionRef={sectionRef}
      type={type}
      hideAmounts={hideAmounts}
    />
  );
};
