import { HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  label: string;
  value: string | number;
}

export const DetailRow: FC<Props> = ({ label, value }) => {
  return (
    <HStack justify='space-between' fontSize='sm'>
      <Text>{label}</Text>
      <Text fontWeight='bold'>{value}</Text>
    </HStack>
  );
};
