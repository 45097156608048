import { Input, InputProps } from '@chakra-ui/react';
import { FC, useEffect, useRef } from 'react';

export const AutoSelectInputField: FC<InputProps> = (props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.select();
  }, []);

  return <Input ref={inputRef} {...props} />;
};
