import { useQuery } from '@tanstack/react-query';

import { fandeckQuery } from '$/services/usecases/fandeck/queries';
import { Material } from '$/services/usecases/materials';
import { materialsQuery } from '$/services/usecases/materials/queries';

export const useFanMaterials = (material?: Material | null) => {
  const materialIds = useQuery(
    fandeckQuery(material?.rawCollection, material?.position.page),
  );

  const materials = useQuery(materialsQuery({ ids: materialIds.data }));

  return (
    materials.data?.materials.sort(
      (a, b) => a.position.pagePosition - b.position.pagePosition,
    ) ?? []
  );
};
