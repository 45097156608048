import { HStack, Input, Text, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RespondingIconButton } from '$/components/core/Editor/ActionBar/components/RespondingIconButton';

export const CopyLinkSection = () => {
  const [inputValueCopy, setInputValueCopy] = useState('');
  const { t } = useTranslation();

  const handleCopy = async () => {
    if (inputValueCopy) {
      await navigator.clipboard.writeText(inputValueCopy);
    }
  };
  return (
    <HStack alignItems='flex-end' w='full'>
      <VStack w='full'>
        <Text w='full' fontSize='sm'>
          {t('editor.copy_link_here')}
        </Text>
        <Input
          onChange={(e) => setInputValueCopy(e.target.value)}
          placeholder='Link'
          value={inputValueCopy}
        />
      </VStack>
      <RespondingIconButton
        aria-label='copy'
        icon='project_copy'
        iconColor='reverseText'
        onClick={handleCopy}
      />
    </HStack>
  );
};
