import { endpointFactory } from '$/services/endpoints';
import { fetcher } from '$/services/fetcher';
import { filterGroupsToQuery } from '$/services/mapper/filter';
import { FilterType } from '$/services/mapper/uses';
import { FilterOptionsSchema } from '$/services/usecases/filter/mapper/searchValues';

export type Filter = Awaited<ReturnType<typeof getFilterOptions>>;
export type FilterGroupOption = Filter['general'][number];

export type BrightnessFilter = { from: number; to: number };

export type FilterGroup = {
  type: string;
  option: string;
  displayName: string;
  payload?: string;
};

export const getFilterOptions = async (payload: {
  signal: AbortSignal;
  filter?: FilterGroup[];
  type?: FilterType;
}) => {
  const { signal, filter, type = 'all' } = payload;
  const [searchKeys, searchValues] = filterGroupsToQuery(filter);

  const cnf = { msg: 'Could not fetch filter options', signal };
  const data = await fetcher(
    endpointFactory.materialSearchValues({ type, searchValues, searchKeys }),
    { ...cnf, parser: FilterOptionsSchema.parse },
  );

  const options = Object.values(data) as NonNullable<
    (typeof data)['collection']
  >[];

  return {
    general: options.filter((option) => option.filterType === 'standard'),
    additional: options.filter((option) => option.filterType === 'additional'),
  };
};
