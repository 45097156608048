import {
  HStack,
  IconButton,
  SimpleGrid,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { AddVariantButton } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/AddVariantButton';
import { ComparisonModal } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/ComparisonModal';
import { VariantCard } from '$/pages/InspirationEditorPage/components/LayerSection/components/VariantSection/components/VariantCard';
import { useInspirationEditorStore } from '$/pages/InspirationEditorPage/stores/useInspirationEditorStore';

export const VariantSection = () => {
  const { t } = useTranslation();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const sceneLayersVariants =
    useInspirationEditorStore.useSceneLayersVariants();

  return (
    <VStack w='full'>
      <HStack justifyContent='space-between' w='full' px='5'>
        <Text fontWeight='bold'>{t('editor.variants')}</Text>
        <IconButton
          minW='0'
          h='auto'
          minH='0'
          aria-label={t('editor.compare')}
          icon={<Icon icon='view_compact_alt' fill='editor.icon' />}
          onClick={onToggle}
          variant='text'
        />
      </HStack>
      <SimpleGrid gap='0.25rem' w='full' h='56px' px='5' columns={3}>
        {sceneLayersVariants.map((_, index) => (
          <VariantCard key={index} variantButtonIndex={index} />
        ))}
        {sceneLayersVariants.length < 3 && <AddVariantButton />}
      </SimpleGrid>
      <ComparisonModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};
