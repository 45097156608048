export const emailFormatRegex =
  /^(?!.*\.\.)(?=.{1,64}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const containsUppercase = (value: string) => /[A-Z]/.test(value);

export const containsLowercase = (value: string) => /[a-z]/.test(value);

export const containsNumber = (value: string) => /[0-9]/.test(value);

export const containsSpecialChar = (value: string) =>
  /[!@#$%^&*()\-+={}[\]:;"'<>,.?/|\\]/.test(value);

export const containsSpaces = (value: string) => /[\r\t\n ]/.test(value);

export const escapeStringForRegex = (str: string) => {
  return str.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, '\\$&');
};
