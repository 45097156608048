import { useRef, useEffect } from 'react';

type UseIntersectionObserverProps = {
  disabled?: boolean;
  threshold?: number;
};

export const useIntersectionObserver = <
  T extends HTMLDivElement = HTMLDivElement,
>(
  onIntersect: (intersection: boolean) => void,
  { disabled, threshold }: UseIntersectionObserverProps,
) => {
  const observerTarget = useRef<T>(null);

  useEffect(() => {
    const observerRef = observerTarget.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (disabled) return;
        onIntersect(entries[0].isIntersecting);
      },
      { threshold: threshold ?? 1 },
    );

    observerRef && observer.observe(observerRef);

    return () => {
      observerRef && observer.unobserve(observerRef);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observerTarget.current, disabled]);

  return observerTarget;
};
