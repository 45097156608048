import { createRoute } from '@tanstack/react-router';

import { FavoriteDashboard } from '$/pages/DashboardPages/pages/Favorite';
import { BaseRouteLayout } from '$/routes/Layouts/BaseRouteLayout';
import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { filterOptionsQuery } from '$/services/usecases/filter/queries';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { useFavoriteStore } from '$/stores/useFavoriteStore';

export const FavoritesRoute = createRoute({
  getParentRoute: () => BaseRouteLayout,
  path: '/favorites',
  component: FavoriteDashboard,
  loader: ({ context: { client } }) => {
    useCollectionStore.setState({ filter: undefined });
    void client.ensureQueryData(filterOptionsQuery()).then((filter) => {
      useCollectionStore.setState({ filter });
    });
  },
  onLeave: () => {
    useFavoriteStore.setState({ activeFolderId: null });
  },
  staleTime: STANDARD_STALE_TIME,
});
