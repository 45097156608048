import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { STANDARD_STALE_TIME } from '$/services/fetcher';
import { getFandeckInfo } from '$/services/usecases/fandeck';

export const fandeckQuery = (collection?: string, page?: number) =>
  queryOptions({
    queryKey: ['fandeck', collection, page],
    queryFn: ({ signal }) =>
      collection == null || page == null || page === 0
        ? []
        : getFandeckInfo({ collection, page, signal }),
    placeholderData: keepPreviousData,
    staleTime: STANDARD_STALE_TIME,
  });
