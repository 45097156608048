import { Flex, Spacer, Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

import { BrandMenu } from '$/components/core/BrandMenu';
import { AccountButton } from '$/components/layouts/BaseDashboardLayout/components/Header/components/AccountButton';
import { Links } from '$/components/layouts/BaseDashboardLayout/components/Header/components/Links';

export const Header: FC<BoxProps> = (props) => {
  return (
    <Flex
      as='header'
      layerStyle='DashboardSection'
      flex='1'
      overflow='hidden'
      h='48px'
      {...props}
    >
      <Box h='100%' pl='4'>
        <BrandMenu showHomeLink={false} />
      </Box>

      <Spacer />
      <Links />
      <AccountButton />
    </Flex>
  );
};
