import { DependencyList, useEffect, useRef, useState } from 'react';

const identifierPropertyName = 'data-index';
const collectorIdentifierPropertyName = 'data-is-collector';

export const useCollapsibleList = (deps?: DependencyList) => {
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>(
    {},
  );
  const parentRef = useRef<HTMLDivElement>(null);

  const handleIntersection: IntersectionObserverCallback = (
    items: IntersectionObserverEntry[],
  ) => {
    const updatedMap: Record<string, boolean> = {};

    items.forEach((item) => {
      const identificator = Number.parseInt(
        item.target.getAttribute(identifierPropertyName) ?? '',
      );

      if (identificator == null || isNaN(identificator)) return;

      updatedMap[identificator] = item.isIntersecting;
    });

    setVisibilityMap((currentMap) => ({
      ...currentMap,
      ...updatedMap,
    }));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: parentRef.current,
      threshold: 1,
    });

    if (parentRef.current == null) return;
    setVisibilityMap({});

    Array.from(parentRef.current.children).forEach((item, index) => {
      if (item.getAttribute(collectorIdentifierPropertyName) == null) {
        item.setAttribute(identifierPropertyName, index.toString());
      }

      observer.observe(item);
    });

    return () => {
      observer.disconnect();
    };
  }, [parentRef.current?.children, parentRef.current?.children.length, deps]);

  const invisibleItemCount = Object.entries(visibilityMap).filter(
    (item) => !item[1],
  ).length;

  const visibleItemCount =
    Object.entries(visibilityMap).length - invisibleItemCount;

  // put this on every children of your list (except the collector element)
  const getChildrenProps = (index: number) => {
    const isVisible = visibilityMap[index];
    if (isVisible) return {};
    return {
      visibility: 'hidden',
      order: '100',
    } as Record<string, unknown>;
  };

  // put this on your element which will handle the hidden elements to have correct behavior when shrinking the width
  const getCollectorProps = () => {
    const collectorProps: Record<string, unknown> = {
      order: '99',
    };

    collectorProps[collectorIdentifierPropertyName] = true;

    if (visibleItemCount > 0 && invisibleItemCount > 0) {
      collectorProps[identifierPropertyName] = Math.max(
        Math.min(
          Object.entries(visibilityMap).length - 1,
          visibleItemCount - 1,
        ),
        0,
      );
    }

    if (invisibleItemCount === 0) {
      collectorProps['visibility'] = 'hidden';
    }

    return collectorProps;
  };

  return {
    visibilityMap,
    parentRef,
    visibleItemCount,
    invisibleItemCount,
    identifierPropertyName,
    getCollectorProps,
    getChildrenProps,
  };
};
