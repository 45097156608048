import { TouchEventHandler, useState } from 'react';

export const useSwipeHook = (eventHandler: {
  onSwipeHorizontal?: (direction: 'left' | 'right') => void;
  onSwipeVertical?: (direction: 'up' | 'down') => void;
}) => {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchEndX, setTouchEndX] = useState<number | null>(null);

  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [touchEndY, setTouchEndY] = useState<number | null>(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart: TouchEventHandler = (e) => {
    setTouchEndX(null);
    setTouchEndY(null);
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchStartY(e.targetTouches[0].clientY);
  };

  const onTouchMove: TouchEventHandler = (e) => {
    setTouchEndY(e.targetTouches[0].clientY);
    setTouchEndX(e.targetTouches[0].clientX);
  };

  const touchX = () => {
    if (!touchStartX || !touchEndX) return;
    const distance = touchStartX - touchEndX;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe || isRightSwipe) {
      eventHandler.onSwipeHorizontal?.(isLeftSwipe ? 'left' : 'right');
    }
  };

  const touchY = () => {
    if (!touchStartY || !touchEndY) return;
    const distance = touchStartY - touchEndY;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isDownSwipe || isUpSwipe) {
      eventHandler.onSwipeVertical?.(isDownSwipe ? 'down' : 'up');
    }
  };

  const onTouchEnd = () => {
    touchX();
    touchY();
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};
