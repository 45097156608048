import { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  defaultProps: {
    variant: 'primary',
  },
  variants: {
    primary: {
      background: 'primaryButton.background',
      color: 'primaryButton.color',
      borderRadius: '4px',
      _disabled: {
        pointerEvents: 'none',
        background: 'primaryButton.disabledBackground',
        opacity: '30%',
      },
      _hover: {
        background: 'primaryButton.hoverBackground',

        _disabled: {
          pointerEvents: 'none',
          background: 'primaryButton.disabledBackground',
          opacity: '30%',
        },
      },
      _active: {
        background: 'primaryButton.activeBackground',
      },
    },

    secondary: {
      backgroundColor: 'secondaryButton.background',
      color: 'secondaryButton.color',
      borderRadius: '4px',
      border: '1px solid',
      borderColor: 'secondaryButton.border',
      _disabled: {
        pointerEvents: 'none',
        background: 'secondaryButton.disabledBackground',
        color: 'secondaryButton.disabledColor',
        opacity: '30%',
      },
      _hover: {
        background: 'secondaryButton.hoverBackground',
      },
      _active: {
        background: 'secondaryButton.activeBackground',
      },
    },

    text: {
      color: 'textButton.color',
      _disabled: {
        pointerEvents: 'none',
        color: 'textButton.disabledColor',
        opacity: '30%',
      },
      _hover: {
        color: 'textButton.hoverColor',
      },
      _active: {
        color: 'textButton.activeColor',
      },
    },

    black: {
      backgroundColor: 'blackButton.background',
      color: 'blackButton.color',
      _disabled: {
        pointerEvents: 'none',
        color: 'blackButton.disabledColor',
        background: 'blackButton.disabledBackground',
        opacity: '30%',
      },
      _hover: {
        background: 'blackButton.hoverBackground',
      },
      _active: {
        background: 'blackButton.activeBackground',
      },
    },

    filter: {
      _hover: {
        bg: 'bodyBackground',
        _active: {
          bg: 'secondaryBackground',
        },
      },
    },
    danger: {
      bg: 'danger.900',
      color: 'white',
      _hover: {
        bg: 'danger.1000',
      },
    },
  },
};
