import { Text } from '@chakra-ui/react';
import { FC } from 'react';

import { DropdownItem } from '$/components/common/Dropdown/DropdownItem';
import { useIsMobile } from '$/hooks/useIsMobile';

interface Props {
  label: string;
}

export const SearchLabel: FC<Props> = ({ label }) => {
  const isMobile = useIsMobile();
  return (
    <DropdownItem px={isMobile ? '0' : '5'}>
      <Text color='neutralText' fontSize='sm'>
        {label}
      </Text>
    </DropdownItem>
  );
};
