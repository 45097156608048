import { VStack, Divider, Spacer, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { BrushShapeOptionButton } from '$/pages/EditorPage/components/AttributesSection/components/BrushShapeOptionButton';
import { SliderInput } from '$/pages/EditorPage/components/AttributesSection/components/BrushSizeInput';
import { TitleSection } from '$/pages/EditorPage/components/AttributesSection/components/TitleSection';
import {
  ModuleSendBrushShapeAction,
  ModuleSendBrushSizeAction,
} from '$/pages/EditorPage/hooks/moduleCommunicationActions';
import { useEditorActions } from '$/pages/EditorPage/hooks/useEditorActions';
import { useEditorStore } from '$/pages/EditorPage/stores/useEditorStore';

const maxValue = 100;
const minValue = 1;

export const RubberSidebar = () => {
  const { t } = useTranslation();
  const brushSize = useEditorStore.useRubberSize();
  const { setBrushSize } = useEditorActions();

  return (
    <VStack
      w='full'
      fontSize='sm'
      divider={<Divider pt='1' borderColor='border' />}
    >
      <TitleSection title={t('editor.rubber.title')} />
      <VStack align='flex-start' w='full' px='4'>
        <HStack>
          <Icon boxSize='4' icon='eraser' />
          <Text fontWeight='bold'>{t('editor.rubber.size')}</Text>
        </HStack>
        <SliderInput
          initialValue={brushSize}
          minValue={minValue}
          maxValue={maxValue}
          onChange={(val) =>
            setBrushSize(ModuleSendBrushSizeAction.RubberSize, val)
          }
        />
        <HStack w='full' pt='4'>
          <Text>{t('editor.rubber.shape')}</Text>
          <Spacer />
          <BrushShapeOptionButton
            type='round'
            mode={ModuleSendBrushShapeAction.RubberShape}
          />
          <BrushShapeOptionButton
            type='square'
            mode={ModuleSendBrushShapeAction.RubberShape}
          />
        </HStack>
      </VStack>
      <Spacer />
    </VStack>
  );
};
