import { Text, TextProps, Tooltip } from '@chakra-ui/react';
import { FC, useEffect, useRef, useState } from 'react';

export const OverflowText: FC<TextProps> = ({ children, ...props }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    const element = ref.current!;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <Tooltip
      w='full'
      isDisabled={!isOverflown}
      label={children}
      placement='top'
    >
      <Text ref={ref} isTruncated {...props}>
        {children}
      </Text>
    </Tooltip>
  );
};
