import { dataEndpointFactory } from '$/services/endpoints/dataEndpoints';
import { imageEndpointFactory } from '$/services/endpoints/imageEndpoints';

export type NullableUrlSearchParams = Record<string, string | undefined>;

export const cnf = () => 'spectrum-all';
const lng = 'de';

export const config = {
  INTEGRATE_BASE_URL: 'https://integrate.materialo.com/v2.1',
  DAW_INTEGRATE_BASE_URL: 'https://daw_integrate_own_db.aocluster.com/v2.1',
  EDITOR_BASE_URL: 'https://emodule.aocluster.com',
};

export const editorEndpoints = {
  EDITOR_SRC: `${config.EDITOR_BASE_URL}/emodule_v1_7`,
  EDITOR_IMAGE_UPLOAD: `${config.EDITOR_BASE_URL}/token1`,
};

export const dataEndpoints = {
  SCENES: `${config.DAW_INTEGRATE_BASE_URL}/cust/caparol/get_datalist_scenes.php`,
  SCENE_CATEGORIES: `${config.DAW_INTEGRATE_BASE_URL}/get_datalist_scenes_extinfo.php`,
  MATERIALS: `${config.DAW_INTEGRATE_BASE_URL}/get_datalist_materials.php`,
  FANDECK_INFO: `${config.DAW_INTEGRATE_BASE_URL}/cust/daw/get_fandeck_info.php`,
  MATERIAL_SEARCH_VALUES: `${config.DAW_INTEGRATE_BASE_URL}/cust/caparol/get_datalist_searchvals_auto.php`,
};

export const imageEndpoints = {
  SCENE_IMAGE_URL: `${config.DAW_INTEGRATE_BASE_URL}/get_img_scene.php`,
  MATERIAL_IMAGE_URL: `${config.DAW_INTEGRATE_BASE_URL}/get_img_material.php`,
  INSPIRATION_RENDER_SCENE: `${config.DAW_INTEGRATE_BASE_URL}/render_img_scene.php`,
  PROJECT_THUMBNAIL: `${config.DAW_INTEGRATE_BASE_URL}/get_img_project.php`,
};

export const cloudEndpoints = {
  REGISTER_URL: `${config.INTEGRATE_BASE_URL}/cust/daw/login/register_step1.php`,
  SIGN_IN_URL: `${config.INTEGRATE_BASE_URL}/cust/daw/login/daw_login.php`,
  ALL_PROJECTS_URL: `${config.INTEGRATE_BASE_URL}/cust/daw/login/get_project_list.php`,
  FORGOT_PASSWORD_URL: `${config.INTEGRATE_BASE_URL}/cust/daw/login/daw_reset_ps.php`,
  CHANGE_PASSWORD_URL: `${config.INTEGRATE_BASE_URL}/cust/daw/login/daw_change_ps.php`,
  UPLOAD_PROFILE_PICTURE: `${config.INTEGRATE_BASE_URL}/cust/daw/login/daw_upload_profile_picture.php`,
  UPDATE_USER_DATA: `${config.INTEGRATE_BASE_URL}/cust/daw/login/daw_update_user_data.php`,
  DELETE_ACCOUNT: `${config.INTEGRATE_BASE_URL}/cust/daw/login/daw_delete_account.php`,
};

export const legalEndpoints = {
  IMPRINT_URL: `https://imprint.daw.de/api/v1/577/${lng}/json`,
  PRIVACY_URL: `https://imprint.daw.de/api/v1/578/${lng}/json`,
  INTEGRITY_URL: `https://imprint.daw.de/api/v1/567/${lng}/json`,
};

export const endpointFactory = {
  ...dataEndpointFactory,
  ...imageEndpointFactory,
  ...cloudEndpoints,
};

export { buildRenderedInspirationImageUrl } from '$/services/endpoints/imageEndpoints';

export const buildUrl = (baseUrl: string, params: NullableUrlSearchParams) => {
  const url = new URL(baseUrl);
  url.search = createUrlSearchParams(params).toString();
  return url.href;
};

const createUrlSearchParams = (
  params: NullableUrlSearchParams,
): URLSearchParams => {
  const response = new URLSearchParams();
  Object.keys(params).forEach((key) => {
    const value = params[key];
    if (value != null) response.append(key, value);
  });

  return response;
};
