import {
  createSelectorHooks,
  ZustandHookSelectors,
} from 'auto-zustand-selectors-hook';
import { create } from 'zustand';

import { trackEvent } from '$/logger';
import { Material } from '$/services/usecases/materials';
import { mapMaterialTypeToCategory } from '$/utils/piwikUtils';

export type State = {
  id: string | null;
};

type Actions = {
  setActive: (material: Material) => void;
  clearActive: () => void;
};

export const initial = { id: null };

const store = create<State & Actions>()((set) => ({
  ...initial,
  setActive: (material) => {
    set({ id: material.id });
    const category = mapMaterialTypeToCategory(material.type[0]);

    trackEvent(category, 'Click', material.info);
  },
  clearActive: () => set({ id: null }),
}));

export const useMaterialDetailStore = createSelectorHooks(
  store,
) as typeof store & ZustandHookSelectors<State & Actions>;
