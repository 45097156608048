import { object, string, z } from 'zod';

import {
  GeneralAuthResponse,
  parseErrorResult,
} from '$/services/mapper/authentication';

export const SignInSchema = object({
  ...GeneralAuthResponse,
  token: string().optional(),
}).transform((data) => {
  return parseErrorResult(data, () => ({
    token: data.token!,
  }));
});

export type SignInResponse = z.infer<typeof SignInSchema>;
