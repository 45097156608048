import { Container, Grid, GridItem } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-router';
import { FunctionComponent, useEffect, useRef } from 'react';

import { AuthenticationModal } from '$/components/core/Authentication/AuthenticationModal';
import { Header } from '$/components/layouts/BaseDashboardLayout/components/Header';
import { MobileHeader } from '$/components/layouts/BaseDashboardLayout/components/MobileHeader';
import { Navbar } from '$/components/layouts/BaseDashboardLayout/components/Navbar';

export const BaseDashboardLayout: FunctionComponent = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const setContainerHeight = () => {
      if (containerRef.current) {
        containerRef.current.style.height = `${window.innerHeight}px`;
      }
    };

    setContainerHeight();

    const handleResize = () => setContainerHeight();
    const resizeObserver = new ResizeObserver(handleResize);

    resizeObserver.observe(document.documentElement);
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return (
    <Container
      ref={containerRef}
      maxW={{ base: 'container.2xl', lg: '100%' }}
      h='100vh'
      p={{ base: '16px', mobile: '32px', xl: '64px' }}
    >
      <Grid
        gap={{ base: '16px', xl: '32px' }}
        templateRows='auto minmax(0, 1fr)'
        templateColumns='auto 1fr'
        h='100%'
      >
        <GridItem display={{ base: 'none', mobile: 'flex' }} colSpan={2}>
          <Header />
        </GridItem>
        <GridItem display={{ base: 'flex', mobile: 'none' }} colSpan={2}>
          <MobileHeader />
        </GridItem>
        <GridItem display={{ base: 'none', mobile: 'flex' }}>
          <Navbar />
        </GridItem>
        <GridItem display='flex' minW='0' colSpan={{ base: 2, mobile: 'auto' }}>
          <Outlet />
        </GridItem>
      </Grid>
      <AuthenticationModal />
    </Container>
  );
};
