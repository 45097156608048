import { Stack } from '@chakra-ui/react';
import { useRef } from 'react';

import { useIsMobile } from '$/hooks/useIsMobile';
import { BackToTopButton } from '$/pages/DashboardPages/components/BackToTopButton';
import { CollectionSection } from '$/pages/DashboardPages/pages/Welcome/components/CollectionSection';
import { InspirationSection } from '$/pages/DashboardPages/pages/Welcome/components/InspirationSection';
import { ProjectSection } from '$/pages/DashboardPages/pages/Welcome/components/ProjectSection';
import { WelcomeHeader } from '$/pages/DashboardPages/pages/Welcome/components/WelcomeHeader';

export const WelcomeDashboard = () => {
  const ref = useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile();

  return (
    <Stack
      ref={ref}
      gap='6'
      overflowY='auto'
      w='full'
      {...(!isMobile && {
        layerStyle: 'DashboardSection',
        p: '7',
      })}
    >
      <WelcomeHeader />
      <ProjectSection />
      <InspirationSection />
      <CollectionSection />
      <BackToTopButton
        containerRef={ref}
        right='65px'
        bottom='65px'
      ></BackToTopButton>
    </Stack>
  );
};
