import { useQuery } from '@tanstack/react-query';

import { useAuthorization } from '$/components/core/Authentication/hooks/useAuthorization';
import { NewProjectCard } from '$/components/core/Projects/components/Cards/NewProjectCard';
import { ProjectCard } from '$/components/core/Projects/components/Cards/ProjectCard';
import { UpgradeAccountCard } from '$/components/core/Projects/components/Cards/UpgradeAccountCard';
import { LoadingProjectCard } from '$/components/core/Projects/components/LoadingProjectCard';
import { projectsQuery } from '$/services/usecases/projects/queries';
import { repeat } from '$/utils/arrayUtils';

export const ProjectGridItems = () => {
  const { data, isLoading } = useQuery(projectsQuery);

  const { canCreateMoreProjects } = useAuthorization();

  if (isLoading || !data) {
    return repeat(6).map((index) => <LoadingProjectCard key={index} />);
  }

  const projects = data.projects.map((project) => (
    <ProjectCard key={project.id} project={project} isLoaded={true} />
  ));

  if (data.projectCount === 0) {
    return <NewProjectCard />;
  }

  if (!canCreateMoreProjects()) {
    return [...projects, <UpgradeAccountCard key='upgrade-card' />];
  }

  return projects;
};
