import { Box, MenuItemProps, useDisclosure } from '@chakra-ui/react';
import { FC, ReactNode, RefObject, useEffect } from 'react';

import { GenericFilterSortDropdown } from '$/components/common/GenericFilterSortDropdown';
import { FilterSkeleton } from '$/components/core/Collection/CollectionFilter/FilterSkeleton';
import { useCollectionStore } from '$/stores/useCollectionStore';

interface Props extends Omit<MenuItemProps, 'type' | 'children'> {
  title: string;
  children: (onMenuClose: () => void) => ReactNode;
  loadingComponent?: JSX.Element;
  scrollRefs?: RefObject<HTMLDivElement>[];
}

export const FilterDropdown: FC<Props> = ({
  title,
  loadingComponent,
  children,
  scrollRefs = [],
  ...props
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const filter = useCollectionStore.useFilter();

  useEffect(() => {
    const handleScroll = () => isOpen && onClose();

    const cleanup = scrollRefs.map((ref) => {
      const current = ref.current;
      current?.addEventListener('scroll', handleScroll);
      return () => current?.removeEventListener('scroll', handleScroll);
    });

    return () => cleanup.forEach((fn) => fn());
  }, [scrollRefs, isOpen, onClose]);

  if (!filter) {
    return loadingComponent ?? <FilterSkeleton title={title} />;
  }

  return (
    <Box>
      <GenericFilterSortDropdown
        title={title}
        closeOnSelect={false}
        isOpen={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        {...props}
      >
        {children(onClose)}
      </GenericFilterSortDropdown>
    </Box>
  );
};
