import { Flex, Text } from '@chakra-ui/react';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';

export const BrandLink = () => {
  const { t } = useTranslation();

  return (
    <Link to='/'>
      <Flex align='center' gap='3' h='full'>
        <Icon icon='caparol' width='28px' height='26px' />
        <Text fontSize='sm' fontWeight='bold'>
          {t('brand.spectrum')}
        </Text>
      </Flex>
    </Link>
  );
};
