export const editorLayerStyles = {
  FloatingActionBar: {
    borderRadius: '12px',
    background: 'background',
    mt: '4',
    position: 'fixed',
    border: '1px solid',
    borderColor: 'border',
    left: '50%',
    transform: 'translateX(-50%)',
    px: '10px',
    h: '48px',
  },
  EditorFloatingSidebar: {
    as: 'section',
    borderRadius: '12px',
    background: 'background',
    top: '0',
    border: '1px solid',
    borderColor: 'border',
    alignItems: 'flex-start',
    paddingY: '2',
  },
  VisualizationBox: {
    p: '0',
    minWidth: '0',
    boxSize: '6',
    border: '1px solid',
    borderColor: 'border',
    borderRadius: '2',
    as: 'button',
  },
};
