import { object, optional, string, unknown, z } from 'zod';

export const ModuleCommandResponseSchema = object({
  action: string(),
  data: object({
    status: string(),
    vars: object({ action: optional(string()) }).catchall(unknown()),
  }),
});

export type ModuleCommandResponse = z.infer<typeof ModuleCommandResponseSchema>;
