import {
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { FC, ForwardedRef, RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCollapsibleList } from '$/hooks/useCollapsibleList';
import { FilterTag } from '$/pages/DashboardPages/pages/Collection/components/CollectionGrid/DataSortingRow/components/FilterTag';
import { GenericTagContent } from '$/pages/DashboardPages/pages/Collection/components/CollectionGrid/DataSortingRow/components/GenericTagContent';
import { ShowAdditionalFilterTagsButton } from '$/pages/DashboardPages/pages/Collection/components/CollectionGrid/DataSortingRow/components/ShowAdditionalFilterTagsButton';
import { useCollectionStore } from '$/stores/useCollectionStore';
import { groupBy } from '$/utils/arrayUtils';

interface Props {
  scrollRefs?: (RefObject<HTMLDivElement> | ForwardedRef<HTMLDivElement>)[];
}

export const FilterTags: FC<Props> = ({ scrollRefs = [] }) => {
  const { t } = useTranslation();

  const filter = useCollectionStore.useFilterGroups();
  const loadingGroups = useCollectionStore.useLoadingGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();
  const resetFilterGroup = useCollectionStore.useResetFilterGroup();

  const [loading, setLoading] = useState('');

  const {
    invisibleItemCount,
    parentRef,
    getCollectorProps,
    getChildrenProps,
    visibleItemCount,
  } = useCollapsibleList(filter);

  const filterGroups = groupBy(filter, (group) => group.type);

  const handleTagDelete = async (key: string) => {
    setLoading(key);
    await resetFilterGroup([key]).finally(() => setLoading(''));
  };

  const labels: string[] = [];
  const tags = Object.entries(filterGroups).map(([key, group]) => {
    const filters = group.map(({ option, displayName, payload }) => ({
      color: payload,
      label: displayName,
      option,
    }));

    const fullLabel = filters.map((item) => item.label).join(', ');
    labels.push(fullLabel);

    const fullOptions = filters.map((item) => item.option).join(', ');

    if (fullLabel === '') {
      return (
        <Skeleton
          key={key}
          p='1.5'
          fontSize='sm'
          lineHeight='normal'
          borderRadius='full'
        >
          {fullOptions}
        </Skeleton>
      );
    }

    return (
      <FilterTag
        key={key}
        isLoading={key === loading || loadingGroups.includes(key)}
        onDelete={() => handleTagDelete(key)}
      >
        {filters.map(({ label, color }) => {
          return (
            <GenericTagContent
              key={`${color}`}
              label={label}
              color={color}
              hideLabel={key === 'color' && group.length > 3}
            />
          );
        })}
      </FilterTag>
    );
  });

  if (brightnessFilter.from !== 0 || brightnessFilter.to !== 100) {
    const brightnessLabel = t('dashboard.collection.brightnessChip', {
      from: brightnessFilter.from,
      to: brightnessFilter.to,
    });
    labels.push(brightnessLabel);
    tags.push(
      <FilterTag onDelete={() => resetFilterGroup(['brightness'])}>
        <GenericTagContent label={brightnessLabel} />
      </FilterTag>,
    );
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleScroll = () => isOpen && onClose();

    const cleanup = scrollRefs.map((ref) => {
      const current = (ref as React.RefObject<HTMLDivElement>).current;
      current?.addEventListener('scroll', handleScroll);
      return () => current?.removeEventListener('scroll', handleScroll);
    });

    return () => cleanup.forEach((fn) => fn());
  }, [scrollRefs, isOpen, onClose]);

  return (
    <Popover
      closeOnBlur={true}
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement='bottom-start'
    >
      <HStack ref={parentRef} gap='2' overflowX='hidden'>
        {tags.map((tag, index) => (
          <Box key={tag.key} {...getChildrenProps(index)}>
            {tag}
          </Box>
        ))}
        <Box {...getCollectorProps()}>
          {invisibleItemCount > 0 && (
            <PopoverTrigger>
              <ShowAdditionalFilterTagsButton>
                <GenericTagContent
                  label={`+${invisibleItemCount} Filter`}
                  title={labels
                    .slice(tags.length - invisibleItemCount)
                    .join(', ')}
                />
              </ShowAdditionalFilterTagsButton>
            </PopoverTrigger>
          )}
        </Box>
      </HStack>
      <Portal>
        <PopoverContent
          // the use of sx is necessary because of the !important, which is necessary because chakra overwrites the visibility by themselves
          sx={{
            visibility:
              invisibleItemCount === 0 ? 'hidden !important' : 'visible',
          }}
          w='auto'
          p='4'
          bg='background'
          border='1px solid'
          borderColor='border'
          outline='none'
        >
          <Stack flexWrap='wrap'>
            {tags.slice(visibleItemCount).map((tag) => (
              <Box key={tag.key}>{tag}</Box>
            ))}
          </Stack>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
