import { Box, HStack, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  label: string;
  title?: string;
  color?: string | undefined;
  hideLabel?: boolean;
}

export const GenericTagContent: FC<Props> = ({
  label,
  color,
  title,
  hideLabel = false,
}) => {
  return (
    <HStack align='center' justify='center' gap='3px' minH='6'>
      {color && (
        <Box
          w='12px'
          h='12px'
          bg={color}
          border='1px'
          borderColor='lighterText'
          borderRadius='full'
        />
      )}
      {!hideLabel && (
        <Text
          fontSize='sm'
          lineHeight='normal'
          whiteSpace='nowrap'
          title={title}
        >
          {label}
        </Text>
      )}
    </HStack>
  );
};
