import { Box, Stack } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { ActiveMaterialCard } from '$/components/core/Collection/ActiveMaterialCard';
import { useIsMobile } from '$/hooks/useIsMobile';
import { BackToTopButton } from '$/pages/DashboardPages/components/BackToTopButton';
import { FilterSection } from '$/pages/DashboardPages/pages/Inspiration/components/FilterSection';
import { InspirationHeader } from '$/pages/DashboardPages/pages/Inspiration/components/InspirationHeader';
import { Scenes } from '$/pages/DashboardPages/pages/Inspiration/components/Scenes';
import { materialsQuery } from '$/services/usecases/materials/queries';
import { useMaterialStore } from '$/stores/useMaterialStore';

export const InspirationDashboard = () => {
  const id = useMaterialStore.useActiveMaterialId();
  const isMobile = useIsMobile();

  const { data } = useQuery(materialsQuery({ ids: id ? [id] : [] }));

  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Stack flex='1' gap='6' w='full'>
      {id && data && (
        <Stack layerStyle='DashboardSection' px='7' py='5'>
          <ActiveMaterialCard material={data.materials[0]} />
        </Stack>
      )}
      <Stack
        ref={containerRef}
        gap='5'
        overflowY='auto'
        h='full'
        {...(!isMobile && {
          layerStyle: 'DashboardSection',
          p: '7',
        })}
      >
        <InspirationHeader />
        <Box>
          <FilterSection />
        </Box>
        <Scenes />
        <BackToTopButton
          containerRef={containerRef}
          right='65px'
          bottom='65px'
        ></BackToTopButton>
      </Stack>
    </Stack>
  );
};
