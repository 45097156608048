import { Box, Grid, GridItem, Radio, RadioGroup, Text } from '@chakra-ui/react';
import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '$/components/common/Icon';
import { ExportFormData } from '$/pages/InspirationEditorPage/components/ExportModal';

export type ExportType = 'jpg' | 'png' | 'pdf' | 'tiff';

interface Props {
  exportType: ExportType;
  dispatch: Dispatch<Partial<ExportFormData>>;
}

export const FileTypeSelection: FC<Props> = ({ dispatch, exportType }) => {
  const { t } = useTranslation();

  return (
    <Box as='section'>
      <Text mb='8' color='editor.objectText'>
        {t('inspirationEditor.chooseExportFormatDescription')}
      </Text>
      <RadioGroup
        onChange={(value) => dispatch({ exportType: value as ExportType })}
        value={exportType}
      >
        <Grid gap='3' templateColumns='repeat(2, 1fr)'>
          <GridItem layerStyle='ExportRadioWrapper'>
            <Radio w='full' h='full' p='3' value='jpg'>
              JPG
            </Radio>
          </GridItem>
          <GridItem
            layerStyle='ExportRadioWrapper'
            p='3'
            cursor='pointer'
            onClick={() => dispatch({ exportType: 'pdf' })}
            rowSpan={3}
          >
            <Radio value='pdf'>{t('inspirationEditor.generatePdf')}</Radio>
            <Icon
              icon='pdf_preview'
              width='20'
              height='auto'
              marginLeft='4'
              marginTop='2'
            />
          </GridItem>
          <GridItem layerStyle='ExportRadioWrapper'>
            <Radio w='full' h='full' p='3' value='png'>
              PNG
            </Radio>
          </GridItem>
          <GridItem layerStyle='ExportRadioWrapper'>
            <Radio w='full' h='full' p='3' value='tiff'>
              TIFF
            </Radio>
          </GridItem>
        </Grid>
      </RadioGroup>
    </Box>
  );
};
