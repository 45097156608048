import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FC } from 'react';

import { GhostButton } from '$/components/common/GhostButton';
import { Icon } from '$/components/common/Icon';
import { ActionButton } from '$/components/core/Editor/ActionBar/components/ActionButton';
import { HorizontalGroup } from '$/components/core/Editor/ActionBar/components/HorizontalGroup';
import {
  EditorActionGroup,
  useActionBarStore,
} from '$/components/core/Editor/ActionBar/store/useActionBarStore';

interface Props {
  group: EditorActionGroup;
}

export const ActionGroup: FC<Props> = ({ group }) => {
  const activeAction = useActionBarStore.useActiveAction();

  if (group.actions.length <= 1) {
    return <ActionButton action={group.actions[0]} />;
  }

  if (!group.groupable) {
    return <HorizontalGroup group={group} />;
  }

  const activeActionInsideGroup = group.actions.some(
    (action) => action.key === activeAction?.key,
  );

  const groupIcon = activeActionInsideGroup
    ? activeAction?.icon
    : group.actions[0].icon;

  return (
    <Menu>
      <MenuButton
        as={GhostButton}
        h='30px'
        px='8px'
        borderRadius='2px'
        rightIcon={<Icon icon='chevron_down' w='12px' />}
        variant={activeActionInsideGroup ? 'action' : 'ghost'}
      >
        {groupIcon}
      </MenuButton>

      <MenuList minW='0'>
        {group.actions.map((action) => (
          <MenuItem key={action.key} as='span'>
            <ActionButton action={action} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
