import {
  Button,
  HStack,
  SkeletonText,
  StackProps,
  Text,
} from '@chakra-ui/react';
import { FC, RefObject, useState, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterTags } from '$/pages/DashboardPages/pages/Collection/components/CollectionGrid/DataSortingRow/components/FilterTags';
import { useCollectionStore } from '$/stores/useCollectionStore';
// import { SortingDropdown } from '$/pages/DashboardPages/Collection/components/CollectionGrid/DataSortingRow/components/SortingDropdown';
// import { useActiveSorting } from '$/pages/DashboardPages/Collection/hooks/useActiveSorting';

interface Props extends StackProps {
  amount?: number | string;
  hideCount?: boolean;
  hideSorting?: boolean;
  isLoading?: boolean;
  scrollRefs?: (RefObject<HTMLDivElement> | ForwardedRef<HTMLDivElement>)[];
}

export const DataSortingRow: FC<Props> = ({
  amount,
  // hideSorting = false,
  hideCount = false,
  isLoading = false,
  scrollRefs,
  ...props
}) => {
  const { t } = useTranslation();

  const resetFilter = useCollectionStore.useResetFilter();
  const filter = useCollectionStore.useFilterGroups();
  const brightnessFilter = useCollectionStore.useBrightnessFilter();

  const [resetLoading, setResetLoading] = useState(false);

  // const activeSorting = useActiveSorting();

  const anyFilterActive =
    filter.length > 0 ||
    (brightnessFilter.from !== 0 && brightnessFilter.to !== 100);

  const label = anyFilterActive
    ? t('dashboard.collection.resultsAmount.withFilter', { amount })
    : t('dashboard.collection.resultsAmount.withoutFilter', { amount });

  return (
    <HStack justify='space-between' gap='6' fontSize='sm' {...props}>
      <HStack
        align='flex-start'
        justifyContent='space-between'
        flexWrap='wrap'
        gap='6'
        w='full'
      >
        <HStack minW='0'>
          {!hideCount && !isLoading && amount && (
            <Text whiteSpace='nowrap'>{label}</Text>
          )}
          {!hideCount && isLoading && (
            <SkeletonText whiteSpace='nowrap' noOfLines={2}>
              {label}
            </SkeletonText>
          )}
          <FilterTags scrollRefs={scrollRefs} />
        </HStack>

        {(filter.length > 0 ||
          !(brightnessFilter.from == 0 && brightnessFilter.to == 100)) && (
          <Button
            flexShrink='0'
            px='0'
            color='lighterText'
            fontSize='sm'
            isLoading={resetLoading}
            onClick={async () => {
              setResetLoading(true);
              await resetFilter().finally(() => setResetLoading(false));
            }}
            variant='text'
          >
            {t('dashboard.collection.resetFilter')}
          </Button>
        )}
      </HStack>

      {/* {!hideSorting && (
        <Stack align={{ base: 'flex-start', lg: 'flex-end' }} gap='0'>
          <SortingDropdown
            title={t('dashboard.collection.sorting')}
            mx='-15px'
            w='fit-content'
          />
          <Text textAlign='right'>{activeSorting?.name}</Text>
        </Stack>
      )} */}
    </HStack>
  );
};
